<template>

    <div id="app" class="white pt-0 pa-0" v-if="(agenda_show)">

    
        <!-- obj_dados_empresa: {{obj_dados_empresa}} <br/> -->

        <!-- menu_de_tabelas : {{menu_de_tabelas}} <br/><br/> -->
        <!-- tabela_selecionada: {{tabela_selecionada}} <br/> <br/> -->
        <!-- selected: {{selected}} <br/><br/> -->
        <!-- item_menu: {{item_menu}} <br/><br/> -->
        <!-- menu_selecionado: {{menu_selecionado}} <br/><br/> -->


        <!-- item_edita : {{ item_edita }} <br/><br/> -->
        <!-- lista_horarios.length : {{ lista_horarios.length }} <br/><br/> -->
        <!-- lista_horarios : {{ lista_horarios }} <br/><br/> -->
        <!-- horarios_veiculo  : {{ horarios_veiculo  }} <br/><br/> -->
        <!-- items_eventos : {{ items_eventos }} <br/><br/> -->
        <!-- items_lista : {{ items_lista }} <br/><br/> -->
        <!-- events : {{ events }} <br/><br/> -->
        <!-- in_today : {{ in_today }} <br/><br/> -->
        <!-- selectedEvent : {{ selectedEvent }} <br/><br/> -->
        <!-- selectedElement : {{ selectedElement }} <br/><br/> -->
        <!-- selectedOpen : {{ selectedOpen }} <br/><br/> -->

        <!-- lista_aulas_aluno : {{ lista_aulas_aluno }} <br/><br/> -->
        <!-- lista_aulas_aluno : {{ lista_aulas_aluno }} <br/><br/> -->
        <!-- lista_instrutores : {{ lista_instrutores }} <br/><br/> -->
        <!-- instrutor_selecionado : {{ instrutor_selecionado }} <br/><br/> -->
    
        <!-- lista_cadastros : {{ lista_cadastros }} <br/><br/> -->
        <!-- cadastro_selecionado : {{ cadastro_selecionado }} <br/><br/> -->
        
        <!-- lista_veiculos : {{ lista_veiculos }} <br/><br/> -->
        <!-- veiculo_selecionado : {{ veiculo_selecionado }} <br/><br/> -->

        <!-- selectedDate : {{ selectedDate }} <br/><br/> -->
        <!-- data_inicial : {{ data_inicial }} <br/><br/> -->
        <!-- data_final : {{ data_final }} <br/><br/> -->



        <v-snackbar
          v-model="snackbar"
          :timeout="3000"
          top
          :color="snack_color"    
      >
      <center>
          {{ snack_text }}
        </center>
      </v-snackbar>


      <template>
        <div class="text-center">
            <v-dialog
            v-model="dialog_img"
            width="500"
            >

            <v-card>
                <v-card-title class="text-h6 grey lighten-3 justify-center">
                <!-- {{item_clicou.NOME}}  -->
                {{dialog_img_titulo}} 
                </v-card-title>

                <v-card-text>          
                <v-img
                    alt="user"
                    :src="dialog_img_url"
                    height="30%"
                    v-if="(dialog_img_url)"
                >
                </v-img>
                </v-card-text>

                <v-card-actions>
                <v-btn
                    color="primary"
                    text
                    x-large
                    @click="saiba_mais_click(dialog_img_mais)"
                    v-if="dialog_img_mais"
                >
                    saiba mais
                </v-btn>

                <v-spacer></v-spacer>


                <v-btn
                    color="primary"
                    text
                    x-large
                    @click="dialog_img = false"
                >
                    FECHAR
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </div>
      </template>





  <!-- MENU -->
  <v-dialog
      v-model="dialog_menu"
      max-width="250px"
      scrollable
    >


        <v-card class="mx-auto">

           <v-list dense >

                <v-subheader class="grey lighten-3 pl-2">
                  <span v-if="(selecionou_varios)">
                    <h3> ÍTEMS SELECIONADOS: {{ selected.length}} </h3>
                  </span>
                  <span v-else>
                    <h3 class="black--text"> {{ retorna_titulo(menu_selecionado, item_menu) }} </h3>
                    <!-- <v-chip class="black--text "> {{ retorna_titulo(tabela_selecionada, item_menu) }} </v-chip> -->
                  </span>
                </v-subheader>

                <v-divider class="mx-0"></v-divider>

                <v-list-group
                    v-for="(item, i) in ((selecionou_varios) ? menu_de_tabelas_selecionados : menu_de_tabelas)"
                    :key="i"
                    v-model="item.active"
                    
                    no-action
                    @click.stop="" 
                    v-if="(item.type=='group') && vif_here(item.click)"
                >

                    <template v-slot:activator>

                      <v-list-item-icon class="ml-1">
                        <v-icon :color="item.icon_color" small> {{ item.icon }}</v-icon>
                      </v-list-item-icon>

                      <v-list-item-content class="ml-n6">
                          <v-list-item-title v-text="item.title"></v-list-item-title>
                      </v-list-item-content>
                    </template>


                    <v-list-item
                      v-for="sub in item.items"
                      :key="sub.title"
                      link
                      @click="clicou_menu_de_tabelas(sub.click)"
                      v-if="vif_here(item.click)"
                    >

                      <v-list-item-content>
                          <v-list-item-title v-text="sub.title"></v-list-item-title>
                      </v-list-item-content>
                      
                      
                      <v-list-item-icon>
                        <v-icon :color="sub.icon_color"> {{ sub.icon }}</v-icon>
                      </v-list-item-icon>

                    </v-list-item>

                </v-list-group>

                <v-divider class="mx-0"></v-divider>


                  <span v-for="item in ((selecionou_varios) ? menu_de_tabelas_selecionados : menu_de_tabelas)" :key="item.title" v-if="vif_here(item.click)">
                    <v-list-item
                          link
                          @click="clicou_menu_de_tabelas(item.click)"
                          v-if="item.type=='menu'"
                        >

                        <v-list-item-icon class=" ml-2">
                            <v-icon  :color="item.icon_color"> {{ item.icon }}</v-icon>
                          </v-list-item-icon>

                          <v-list-item-content class=" ml-n5">
                              <v-list-item-title v-text="item.title"></v-list-item-title>
                          </v-list-item-content>

                    </v-list-item>
                    
                    <v-divider
                        class="mx-2"
                    ></v-divider>
                </span>



            </v-list>
        </v-card>


  </v-dialog> 




        <v-dialog
          v-model="dialog_tabela"
          persistent
          max-width="990"
          scrollable
      >
  
          <v-card
          class=""
          >
          <!-- height="80%" -->
          <v-card-title>
              <span class="text-h5" 
                v-text="(item_edita.CODIGO>0) ? 'Alterar '+ card_titulo +' '+item_edita.CODIGO : 'Novo '+ card_titulo "
              >
              Alterar
             </span>
          </v-card-title>
  
  

              <!-- passo 4 monto os campos editaveis -->
              <!-- :readonly = "readonly" -->
                  <v-card-text class="pa-1">
                    <v-form
                      ref="form"
                      v-model="valido"
                      >
                      <v-container>
                          <v-row >
                              <v-col
                                  class=""
                                  v-for="(item, i) in items_editaveis"
                                  :key="i"
                                  :cols="(isMobile) ? 12 : item.cols"
                                  v-if="parseInt(item.cols)>0"   
                              >




                                  <v-chip
                                  v-if="item.tag == 'v-chip'" :color="item.color" @click="clicou_em(item.click)"
                                  >
                                    {{ item.text }}
                                  </v-chip>

                                  <v-icon  v-if="item.tag == 'v-icon'" :color="item.color" @click="clicou_em(item.click)"
                                  >
                                    {{ item.icon }}
                                  </v-icon>

                                  <v-btn v-if="(item.tag == 'v-btn')" :color="item.color"
                                  @click="modal_clicou(item.click)"
                                  >
                                    {{item.text}}
                                  </v-btn>


                                  <!-- <span v-if="item.tag == 'span'">
                                    <b> {{ item.text }}</b>
                                  </span> -->

                                  <div v-if="item.grupo" class="mt-n7 pb-2">
                                    <b> {{ item.grupo }}</b>
                                  </div>

                                 <!-- COM V-MASK -->
                                  <v-text-field 
                                      dense
                                      v-model="item_edita[item.value]"
                                      :label="item.rule == 'S' ? item.text+' *': item.text"
                                      :type="item.type"
                                      v-if="(item.tag == 'v-text') && (item.vmask)"
                                      @keyup="meu_keyup($event, item.value, item.upper)"
                                      :rules="item.rule == 'S' ? notnullRules: noRules"
                                      :autofocus="i==1"
                                      :hint="item.hint"
                                      v-mask = "getVmask(item.vmask)"
                                  ></v-text-field>
                                  
                                  <!-- SEM V-MASK  acaba dando erro, por isso separei-->
                                  <v-text-field
                                      dense
                                      v-model="item_edita[item.value]"
                                      :label="item.rule == 'S' ? item.text+' *': item.text"
                                      :type="item.type"
                                      v-if="(item.tag == 'v-text') && (!item.vmask)"
                                      @keyup="meu_keyup($event, item.value, item.upper)"
                                      :rules="item.rule == 'S' ? notnullRules: noRules"
                                      :autofocus="i==1"
                                      :hint="item.hint"
                                  ></v-text-field>



                                  <v-combobox
                                      dense
                                      v-model="item_edita[item.value]"
                                      :label="item.text"
                                      v-if="item.tag == 'v-autocomplete'"
                                      :items="item.tag_items"
                                      :rules="item.rule == 'S' ? notnullRules: noRules"
                                      clearable

                                      @keyup="meu_keyup($event, item.value, item.upper)"

                                      :return-object = 'item.return_object'
                                      :item-value='item.item_value'
                                      :item-text='item.item_text'
                                  ></v-combobox>
                                  
                                  
                                  <v-select
                                      :class="[item.class]"
                                      dense
                                      v-model="item_edita[item.value]"
                                      :label="item.text"
                                      v-if="item.tag == 'v-select'"
                                      :items="item.tag_items"
                                      :rules="item.rule == 'S' ? notnullRules: noRules"

                                      item-value='CODIGO'
                                      :item-text='item.tag_item_text'
                                      :clearable = "item.tag_clear == 'S'"
                                      :persistent-hint="item.cols>2"
                                      :hint="item.hint"
                                  >
                                  
                                  <!-- <template slot="selection" v-slot-scope="item"> -->
                                    
                                  <!-- <template slot="selection" v-slot-scope="item"> -->
                                    <template v-slot:label>
                                      <v-tooltip bottom v-if="item.hint">
                                          <template v-slot:activator="{ on, attrs }">
                                            <span
                                                v-bind="attrs"
                                                v-on="on"
                                            > 
                                              <span :class="item.class">
                                                {{ item.text }} 
                                                <!-- {{ item_edita[item.value] }} -->
                                              </span>
                                            </span>

                                          </template>
                                          <span> {{ item.hint }} </span>
                                        </v-tooltip>

                                        <span :class="item.class" v-else>
                                          {{ item.text }} 
                                        </span>
                                    </template>
       
                                </v-select>


                                  <!-- CAMPO ANOTAÇÕES OU OBSERVAÇÃO -->
                                  <v-textarea
                                    v-model="item_edita[item.value]"
                                    :label="item.text"
                                    v-if="item.tag == 'v-textarea'"
                                    :hint="item.hint"
                                    :persistent-hint="item.cols>2"
                                    dense
                                  >
                                  </v-textarea>




                                  <!-- INICIO DIV IMAGEM -->
                                  <div class=""
                                   v-if="item.tag == 'img'"
                                  >
                                  
                                  <v-row dense class="grey lighten-5">
                                    <v-col  class="">
                                      <span class="pb-2">Logo</span>
                                        <v-img
                                        v-if="urlLogo"
                                        max-height="90"
                                        max-width="170"
                                        :src="urlLogo"
                                        contain
                                      >
                                      </v-img>
                                    </v-col>
                                    
                                    <v-col  class="mt-5">
                                      <p class="primary--text"><label for="file_upload" style="cursor: pointer;">Alterar Logo</label></p>
                                      <!-- <p class="primary--text"><label for="file_upload" style="cursor: pointer;">Alterar Logo</label></p> -->
                                      <!-- <p>Remover</p> -->
                                      <v-btn text x-small color="red" for="file_upload"
                                        @click="item_edita[item.value] = '', urlLogo=''"
                                      >
                                        Remover
                                      </v-btn>
                                    </v-col>


                                    </v-row>
                                    <!-- oculto -->
                                    <v-row dense class="grey lighten-3">
                                      <v-col>
                                        <!-- necessario para fazer upload logo -->
                                        <v-file-input
                                          v-model="files_upload"
                                          accept="image/*"
                                          label="Logomarca"
                                          @change="upload_logo(files_upload, session_bd ,'logo',item.value)"
                                          style="display: none"
                                          id="file_upload"
                                        >
                                        <!-- session_bd = nome da pasta onde salvar as imagens..peguei pelo nome do banco de dados -->
                                        </v-file-input>
                                        <!-- necessario para fazer upload logo -->
                                      </v-col>
                                    </v-row>
                                  </div>
                                  <!-- FINAL DIV IMAGEM -->


                                  
                                  
                              </v-col>
                          </v-row>
                      </v-container>
                    </v-form>
                  </v-card-text>
  
          <v-card-actions>
              <v-btn
                  x-small
                  color="red"
                  text
                  @click="remover_tabela(tabela_selecionada, item_edita, card_titulo), dialog_tabela = false"
              >
                Excluir
              </v-btn>
  
              <v-spacer></v-spacer>
  
              <v-btn
                  color="red"
                  text
                  @click="dialog_tabela = false"
              >
                Cancelar
              </v-btn>
              
              &nbsp
  
              <v-btn
                  :disabled="!valido"
                  color="primary"
                  @click="salvar_tabela(tabela_selecionada, item_edita, 'dialog_tabela')"
              >
                Salvar
              </v-btn>
          </v-card-actions>
          </v-card>
      </v-dialog>




        <section class="grid-1">
            
            <div class="item-2 grey lighten-5">
                <!-- <div class="item-5">5</div> -->

                <!-- coluna esquerda calendario mes -->
                <div class="item-6 lighten-4 ">


                    
                    <v-row dense class="">
                        <v-col class="grey lighten-5" cols="12">
                            <template>
                            <v-card
                                elevation="0"
                                class="grey lighten-3 ml-0"
                            >
                                <v-expansion-panels 
                                    :multiple="false"
                                    :focusable='false'
                                    v-model="value_expansion_1"
                                    >


                                        <!-- MOSTRA CALENDARIO -->
                                        <v-expansion-panel>
                                            <v-expansion-panel-header 
                                            class="pa-1 px-1"
                                            style="min-height: 25px"
                                            >
                                              <span class="fs-14 bold" v-if="(!(value_expansion_1>=0))">
                                                  <v-icon color="blue" size="18">mdi-calendar-month-outline</v-icon>
                                                  Calendário 
                                              </span>
                                              
                                            </v-expansion-panel-header>

                                            <v-expansion-panel-content>
                                                <!-- class="py-0 ma-0" -->

                                                <v-container>

                                                    <v-row>
                                                       <v-col class="px-0 py-0 mb-1">
                                                        <v-date-picker
                                                            v-model="in_today"
                                                            width="270"
                                                            :full-width = "$vuetify.breakpoint.smAndDown"
                                                            elevation="4"
                                                            locale="pt-br"
                                                            :first-day-of-week="0"
                                                            :landscape = false
                                                            class="ml-1"
                                                            >
                                                            </v-date-picker>
                                                       </v-col>
                                                    </v-row>
                                                </v-container>

                                            </v-expansion-panel-content>
                                        </v-expansion-panel>


                                </v-expansion-panels>
                            </v-card>
                            </template>
                        </v-col>
                    </v-row>



                    <v-card elevation="1" class="mt-0">
                        <v-row dense class="my-0 pt-1">
                            <v-col class="mx-2 mt-0 pt-0">

                                <v-select
                                    :items="lista_veiculos "
                                    label="Veículo"
                                    item-value="CODIGO"
                                    item-text="NOME"
                                    return-object
                                    v-model="veiculo_selecionado"
                                    hide-details="true"
                                    @change="tabela('agenda')"
                                    @input="salva_session('veiculo_selecionado')"
                                    >
                                
                                    <template slot='item' slot-scope='{ item }'>
                                            <div class="fs-14">
                                                <span v-if="(item.CODIGO>0)">
                                                    {{ item.PLACA }} | {{ item.NOME }} | {{ item.CATEGORIA }}
                                                </span>
                                                <span v-else> {{ item.NOME }} </span>
                                            </div>
                                    </template>


                                    <template slot='selection' slot-scope='{ item }'>
                                            <div class="fs-16 bold">
                                                <span v-if="(item.CODIGO>0)">
                                                    {{ item.PLACA }} | {{ item.NOME }} | {{ item.CATEGORIA }}
                                                </span>
                                                <span v-else> {{ item.NOME }} </span>
                                            </div>
                                    </template>
                                



                                </v-select>
                            </v-col>


                            <v-col cols="1 ma-0 pa-0 mr-3 mt-5 ">
                                <div class="d-flex justify-end">
                                    <v-tooltip bottom eager>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-checkbox
                                            v-model="filtrar_veiculo"
                                            label="Filtrar"
                                            dense
                                            class="ma-0 pa-0"
                                            hide-details=""
                                            @change="tabela('agenda')"
                                            
                                            >
                                            <template v-slot:label>
                                                <span :class="['fs-12 ml-n7']" v-bind="attrs" v-on="on">⠀⠀</span>
                                            </template>
                                            </v-checkbox>
                                        </template>
                                            <span>Filtrar Veículo</span>
                                    </v-tooltip>
                                </div>
                            </v-col>

                        </v-row>

                        <v-row dense class="mb-1 pl-2">

                            <div >
                                <v-avatar  class="pb-0 ma-0 pa-0" size="50" >
                                    <v-img
                                        alt="..."
                                        contain
                                        :src="instrutor_selecionado.FOTO_URL"
                                        v-if="(instrutor_selecionado.FOTO_URL)"
                                        @click="abre_img(instrutor_selecionado .NOME, instrutor_selecionado .FOTO_URL, '')"
                                        class="hand">
                                    </v-img>
                                        
                                        <!-- @click="dialog_img=true"  -->

                                        <v-icon size="55" v-else class="pa-0 ma-0">
                                        mdi-account-circle
                                        </v-icon>
                                    </v-avatar>
                            </div>


                            <v-col class="mx-2 pt-0">

                                <v-select
                                    :items="lista_instrutores "
                                    label="Instrutor"
                                    item-value="CODIGO"
                                    item-text="NOME"
                                    return-object
                                    v-model="instrutor_selecionado"
                                    hide-details="true"
                                    @change="tabela('agenda')"
                                    @input="salva_session('instrutor_selecionado')"
                                    >
                                
                                    <template slot='item' slot-scope='{ item }'>
                                        
                                        <v-avatar  class="pb-0 ma-0 mr-1 pa-0" size="22" >
                                                <v-img
                                                    alt="..."
                                                    contain
                                                    :src="item.FOTO_URL"
                                                    v-if="(item.FOTO_URL)"
                                                    @click="abre_img(item.NOME, item.FOTO_URL, '')"
                                                    class="hand">
                                                </v-img>
                                                <v-icon size="22" v-else class="pa-0 ma-0">
                                                    mdi-account-circle
                                                </v-icon>

                                            </v-avatar>
                                            
                                            <div class="caption ml-4">
                                                {{ item.NOME }}
                                            </div>

                                    </template>

                                    <!-- <template slot='selection' slot-scope='{ item }'>
                                        <v-avatar  class="pb-0 ma-0 mr-1" size="22" >
                                            <v-img
                                                alt="..."
                                                contain
                                                :src="item.FOTO_URL"
                                                v-if="2>1"
                                                @click="abre_img(item.NOME, item.FOTO_URL, '')"
                                                class="hand">
                                            </v-img>

                                                <v-icon size="22" v-else class="pa-0 ma-0">
                                                mdi-account-circle
                                                </v-icon>
                                            </v-avatar>

                                            <div class="caption ml-4">
                                            {{ item.NOME }}
                                            </div>
                                    </template> -->
                                



                                </v-select>
                            </v-col>

                            <v-col cols="1 ma-0 pa-0 mr-3 mt-5 ">
                                <div class="d-flex justify-end">
                                    <v-tooltip bottom eager>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-checkbox
                                            v-model="filtrar_instrutor"
                                            label="Filtrar"
                                            dense
                                            class="ma-0 pa-0"
                                            hide-details=""
                                            @change="tabela('agenda')"
                                            
                                            >
                                            <template v-slot:label>
                                                <span :class="['fs-12 ml-n7']" v-bind="attrs" v-on="on">⠀⠀</span>
                                            </template>
                                            </v-checkbox>
                                        </template>
                                            <span>Filtrar Instrutor</span>
                                    </v-tooltip>
                                </div>
                            </v-col>
    
                        </v-row>

                        <v-row dense class="my-0 pl-2">

                            <div class="pt-1">
                                <v-avatar  class="pb-0 ma-0 pa-0" size="50" >
                                    <v-img
                                        alt="..."
                                        contain
                                        :src="cadastro_selecionado.FOTO_URL"
                                        v-if="(cadastro_selecionado.FOTO_URL)"
                                        @click="abre_img(cadastro_selecionado .NOME, cadastro_selecionado .FOTO_URL, '')"
                                        class="hand">
                                    </v-img>
                                        
                                        <!-- @click="dialog_img=true"  -->

                                        <v-icon size="55" v-else class="pa-0 ma-0">
                                        mdi-account-circle
                                        </v-icon>
                                </v-avatar>
                            </div>


                            <v-col class="mx-2 mr-0 pr-2 " cols="8">

                                <v-select
                                    :items="lista_cadastros"
                                    label="Cadastro"
                                    item-value="CODIGO"
                                    item-text="NOME"
                                    return-object
                                    v-model="cadastro_selecionado"
                                    hide-details="true"
                                    @change="tabela('agenda'), aulas_alunos(cadastro_selecionado.CODIGO_ALUNO || cadastro_selecionado.CODIGO)"
                                    @input="salva_session('cadastro_selecionado')"
                                    class="py-2"
                                    dense
                                    >
                                
                                    <template slot='item' slot-scope='{ item }'>
                                        
                                        
                                        <v-avatar  class="pb-0 ma-0 mr-1" size="22" >
                                                <v-img
                                                    alt="..."
                                                    contain
                                                    :src="item.FOTO_URL"
                                                    v-if="(item.FOTO_URL)"
                                                    @click="abre_img(item.NOME, item.FOTO_URL, '')"
                                                    class="hand">
                                                </v-img>
                                                <v-icon size="22" v-else class="pa-0 ma-0">
                                                    mdi-account-circle
                                                </v-icon>

                                            </v-avatar>
                                            
                                            <div class="caption ml-4" >
                                                {{ item.NOME }}  {{(item.CODIGO_ALUNO)?' | '+ item.CODIGO_ALUNO : '' }}
                                            </div>

                                    </template>


                                    <template slot='selection' slot-scope='{ item }'>
                                        <div class="caption text-ellipsis">
                                            {{ item.NOME }}
                                        </div>
                                    </template>

                                </v-select>

                                <div v-if="cadastro_selecionado.CODIGO_ALUNO>0" class="mt-0 fs-11">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-chip color="primary" x-small class="px-2" @click="abrir_cadastro(cadastro_selecionado.CODIGO_ALUNO, true)" v-bind="attrs" v-on="on">
                                                {{ cadastro_selecionado.CODIGO_ALUNO }}
                                                <v-icon  x-small>
                                                    mdi-account
                                                </v-icon>
                                            </v-chip>

                                        </template>
                                        <span> Cadastro </span>
                                    </v-tooltip>

                                    <span class="px-1"> {{cadastro_selecionado.PROCESSO}} </span>
                                    <!-- <span class="px-1"> {{cadastro_selecionado.CELULAR}} </span> -->
                                </div>

                            </v-col>

                            <v-col cols="1 ma-0 pa-0 pl-2 ml-1 mt-4 ">
                                <div class="d-flex justify-end">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn x-small fab color="primary"  @click="abre_janela_modal(true, false, 'ALUNOS', 'CADASTRO')" v-bind="attrs" v-on="on">
                                            <v-icon>mdi-account-search </v-icon>
                                            </v-btn>
                                        </template>
                                        <span> Selecionar Cadastro </span>
                                    </v-tooltip>
                                </div>
                            </v-col>
    
                        </v-row>
                    </v-card>    

                    <v-divider class="mx-1"/>


                    <v-row dense v-if="(lista_aulas_aluno.length > 0)" class="blue mr-0">
                        <v-col class="red lighten-5 px-0 pl-1" cols="12">
                            <template>
                            <v-card 
                                flat
                                elevation="4"
                                class="grey lighten-3"
                                >
                                <v-expansion-panels 
                                    :multiple="false"
                                    :focusable='false'
                                    v-model="value_expansion_2"
                                    >


                                        <!-- LISTA DE AULAS -->
                                        <v-expansion-panel>
                                            <v-expansion-panel-header 
                                            class="pa-2 px-1"
                                            style="min-height: 28px"
                                            >

                                                <div v-if="2>1">
                                                <v-row>
                                                    <v-col cols="12">
                                                        <!-- <v-icon size="30" class="pr-1" color="primary"> 
                                                        mdi-chart-line-variant 
                                                        </v-icon>  -->
                                                        

                                                        <span>
                                                            <div class="fs-12 pb-1 ml-0" >
                                                                {{ cadastro_selecionado.DESCRICAO }} 
                                                            </div>

                                                            <v-icon
                                                            size="33"
                                                            color="blue darken-2"
                                                            @click.stop=""
                                                            class="pl-1"
                                                            @click="monta_menu_de_tabelas('menu_aulas')"
                                                            >
                                                              mdi-menu
                                                            </v-icon>

                                                            <span class="bold mx-1" @click.stop=""> {{ lista_aulas_aluno.length }} </span>

                                                            <v-chip  small v-if="lista_aulas_aluno[0].TOTAL_A>0" @click.stop="" 
                                                                :color="cor_limite('A')">
                                                                {{ lista_aulas_aluno[0].TOTAL_A }} A
                                                            </v-chip>
                                                            
                                                            <v-chip color="primary" small v-if="lista_aulas_aluno[0].TOTAL_B>0" class="ml-1" @click.stop=""
                                                                :color="cor_limite('B')">
                                                                {{ lista_aulas_aluno[0].TOTAL_B }} B
                                                            </v-chip>
                                                            
                                                            <v-chip color="primary" small v-if="lista_aulas_aluno[0].TOTAL_C>0" class="ml-1" @click.stop=""
                                                                :color="cor_limite('C')">
                                                                {{ lista_aulas_aluno[0].TOTAL_C }} C
                                                            </v-chip>
                                                            
                                                            <v-chip color="primary" small v-if="lista_aulas_aluno[0].TOTAL_D>0" class="ml-1" @click.stop=""
                                                                :color="cor_limite('D')">
                                                                {{ lista_aulas_aluno[0].TOTAL_D }} D
                                                            </v-chip>
                                                        </span>

                                                        

                                                    </v-col>
                                                </v-row>
                                                </div>
                                            </v-expansion-panel-header>

                                            <v-expansion-panel-content>
                                                <!-- class="py-0 ma-0" -->

                                                <v-container>


                                            <v-row class="mt-1 px-0" v-if="0>1">
                                                <v-col cols="12">
                                                        <!-- <v-icon size="30" class="pr-1" color="primary"> 
                                                        mdi-chart-line-variant 
                                                        </v-icon>  -->
                             

                                                    </v-col>
                                                </v-row>


                                                    <v-row>
                                                    <v-col class="px-0 py-0  ">

                                                    <v-list
                                                        dense
                                                        :height="altura_desse(190)"
                                                        class="overflow-y-auto px-0 py-0"
                                                        >
                                                        <v-list-item-group active-class="border">

                                                            <v-list-item
                                                            v-for="(item, i) in lista_aulas_aluno"
                                                            :key="i"
                                                            class="px-0 py-0 grey lighten-5"
                                                            style="min-height: 30px !important;"
                                                            @contextmenu.prevent="monta_menu_de_tabelas('agenda'), rightClick(item, $event)"
                                                            @click="monta_menu_de_tabelas('agenda'), seleciona_linha(item)"
                                                            >

                                                            <v-list-item-content class="py-0 mx-1">
                                                                <!-- <div> -->
                                                                <div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                                                                    <v-row>

                                                                    <v-col cols="1" class=""> 
                                                                        <span style=" text-align: right; font-size: 9px;" class="grey--text pl-1  "> {{ i + 1 }}º &nbsp </span>
                                                                    </v-col>

                                                                    <v-col cols="auto" class="">

                                                                        <span class="fs-11 bold">
                                                                            {{ item.xx_DATA }}
                                                                        </span>

                                                                        <span class="fs-11 ml-1">
                                                                            {{ item.HORA }}
                                                                        </span>
                                                                        
                                                                        <span class="fs-11 ml-1 bold">
                                                                            {{ item.CATEGORIA }}
                                                                        </span>

                                                                        <span class="fs-11 ml-1 bold">
                                                                            {{ item.PLACA }}
                                                                        </span>

                                                                        <span class="fs-11 ml-1 ">
                                                                            {{ item.INSTRUTOR }}
                                                                        </span>


                                                                    </v-col>

                                            
                                                                    </v-row>
                                                                </div>

                                                                <v-divider class="mx-0"/>
                                                            </v-list-item-content>
                                                            </v-list-item>




                                                        </v-list-item-group>
                                                        </v-list>

                                                    </v-col>
                                                    </v-row>
                                                </v-container>

                                            </v-expansion-panel-content>
                                        </v-expansion-panel>


                                </v-expansion-panels>
                            </v-card>
                            </template>
                        </v-col>
                    </v-row>



                </div>

                <!-- calendario semana -->
                <div class="item-7 red lighten-5" >

                    <div>
                        <v-row dense class="" v-if="2>1">

                           
                          <v-col cols="5" md="2" class=" ma-0 " v-if="2>1">
                            <div class="d-flex ">
                            <!-- height="54" -->
                            <v-btn
                                icon
                                class="ma-2"
                                @click="$refs.calendar.prev()"
                            >
                                <v-icon>mdi-chevron-left</v-icon>
                            </v-btn>

                            <v-select
                                v-model="type"
                                :items="viewOptions"
                                dense
                                :outlined="false"
                                hide-details
                                class="mt-4"
                                label="Tipo"
                                v-if="2>1"
                            >
                        
                            <template v-slot:selection="{ item }">
                                <div class="fs-12">{{ item.text }}</div> <!-- Usando a classe para diminuir a fonte -->
                            </template>

                            </v-select>


                            <v-spacer></v-spacer>
                            <v-btn
                                icon
                                class="ma-2"
                                @click="$refs.calendar.next()"
                            >
                                <v-icon>mdi-chevron-right</v-icon>
                            </v-btn>

                            </div>
                          </v-col>  

                          <v-col cols="2" md="1" class="pt-4  pl-3" v-if="2>1">
                            <v-chip color="primary" small @click="clicou_hoje()">Hoje</v-chip>

                          </v-col>

                          <v-col class="ml-auto" cols="auto" v-if="(!isMobile)">
                              <v-chip
                                class="ma-2"
                                color="error"  
                                href="https://youtu.be/PdhW4sLRkwc"
                                target="_blank"
                              >
                                <v-icon left color="white">
                                  mdi-youtube
                                </v-icon>
                                Tutorial
                              </v-chip>
                          </v-col>

                        </v-row>

                    
                    

                        <v-sheet :height=retorna_altura(-20) class="red " v-if="2>1">
                        <v-calendar

                            ref="calendar"
                            v-model="selectedDate"
                            locale="pt-br"
                            :weekdays="weekday"
                            
                            :type="type"
                            :events="items_eventos"
                            :event-overlap-mode="mode"
                            :event-overlap-threshold="30"
                            :event-color="getEventColor"
                            :show-interval-label="showIntervalLabel"
                            first-day-of-week="4"

                            :interval-format="intervalFormat"

                            :interval-count="lista_horarios.length"
                            :interval-height="interval_height"
                            :interval-minutes="interval_minutes"
                            :first-time="first_time"
                            

                            @change="getEvents"
                            @click:event="abre_evento"
                            @click:more="viewDay"
                            @click:date="viewDay"
                            
                            @click:time="clicou_hora"
                            
                        >
                        <!-- @click:event="showEvent" -->
                        <!-- @click:time="clicou_hora" -->
            


                        <template v-slot:day-body="{ date, week }">
                            <div v-for="(time, i) in horarios_veiculo.HORARIOS" :key="i" class="v-current-time"    
                                
                                    :class="{ first: date === week[0].date }"
                                    :style="{ top: calculateTop(time) }"> 
                                    <!-- {{ time }} -->
                             </div>
                        </template>

                        
                        <!-- <template v-slot:day-body="{ date, week}">
                            <div
                            class="v-current-time"
                            :class="{ first: date === week[0].date }"
                            :style="{ top: nowY }"
                            > 
                            {{ week[0].date }}
                            {{ nowY }}
                            </div>
                        </template> -->

                        <!-- <template v-slot:day-body="{ date, week}">
                            <div
                            class="v-current-time"
                            :class="{ first: date === week[0].date }"
                            :style="{ top: nowY }"
                            > 
                            {{ day }}
                            </div>
                        </template> -->

                        <!-- <template v-slot:event="{ event }">
                            <div class="custom-event" :style="{ backgroundColor: event.color }">
                                {{ event.name }}
                            </div>
                        </template> -->



                        <template v-slot:event="{ event }">
                            <div class="pa-1 ">
                                <!-- <v-icon>mdi-calendar</v-icon> Exemplo: ícone -->
                                <v-avatar  class="white pa-0" size="32" 
                                v-if="event.x_FOTO_URL_INSTRUTOR"
                                >
                                    <v-img
                                        alt="..."
                                        contain
                                        :src="event.x_FOTO_URL_INSTRUTOR"
                                        class="hand pa-2">
                                    </v-img>
                    

                                </v-avatar>

                                <!-- <v-icon size="32" v-else class="">
                                    mdi-account-circle
                                </v-icon> -->

                                <span v-else class="caption ml-1">
                                    {{ event.x_NOME_INSTRUTOR }}
                                </span>

                                <span class="caption ml-1">
                                    {{ event.x_PLACA }} ({{ event.CATEGORIA }})
                                </span>

                                <div 
                                  :class="['caption ml-1 ' + cor_cadastro(event.CODIGO_ALUNO) ]"
                                >


                                {{ (event.CODIGO_ALUNO > 0 ) ? event.CODIGO_ALUNO : '' }} 

                                <!-- nao vou mostrar foto do aluno aqui por enquanto -->
                                <!-- <span class="pt-2" v-if="(cadastro_selecionado.FOTO_URL) && (event.CODIGO_ALUNO == cadastro_selecionado.CODIGO_ALUNO) ">
                                    <v-avatar  class="white pa-0" size="32" >
                                        <v-img
                                            alt="..."
                                            contain
                                            :src="cadastro_selecionado.FOTO_URL"
                                            class="hand pa-2">
                                        </v-img>
                        

                                    </v-avatar>
                                </span> -->


                                   {{ event.ALUNO }}

                                    <div class="caption ml-1">
                                        {{ event.HORA }}
                                    </div>
                                </div>

                            </div>
                        </template>



    

                        </v-calendar>
                        <!-- event-height="20" -->
                        <!-- short-intervals="false" -->
                        </v-sheet>
                    </div>


                </div>
            </div>

        </section>



    <!-- MOSTRA JANELA MODAL -->
    <div class="mt-n10">
      <v-row>
        <v-col>
        <Editar_modal 
          :aberto = 'janela_modal_abre'
          :pro_tabela = 'janela_modal_tabela'
          :pro_titulo = 'janela_modal_titulo'
          :pro_obj_menus = null
          :show_menu = 'false'
          :pro_modo_edicao = 'janela_modo_edicao'
          @muda_aberto_lembretes="janela_modal_change"
        />
        </v-col>
      </v-row>
    </div>


    </div>
    </template>
    
    <script>
    
    import DSibasico from '../assets/js/DSi_basico'//chamo funcao do arquivo
    import {get_data_hora} from '../assets/js/DSi_basico'

    import DSisql from '../assets/js/DSi_sql'//chamo funcao do arquivo

    
    
    export default {

      components:{
        Editar_modal: () => import("./Editar.vue")
      },

      data: () => ({    
    
        agenda_show : false,

        obj_dados_empresa: null,


        dialog_menu: false,
        item_menu:{},
        menu_de_tabelas: [],
        menu_de_tabelas_selecionados: [],
        selecionou_varios: false,
        menu_selecionado:'',

        header_aulas_aluno : [
            { text: 'N', value: 'INDEX'},
            { text: 'DATA', value: 'xx_DATA'},
            { text: 'HORA', value: 'HORA'},
            { text: 'CATEGORIA', value: 'CATEGORIA'},
            { text: 'INSTRUTOR', value: 'INSTRUTOR', width:'80px'},
        ],



        aluno_selecionado:[],

        value_expansion_1 : undefined, //0 ou null ou [ 1, 0 ]
        value_expansion_2 : null, //0 ou null ou [ 1, 0 ]
        expansion_selecionao:'',


        janela_modal_abre  : false,
        janela_modal_tabela: '',
        janela_modal_titulo: '',
        janela_modo_edicao : false,

        timeSlots: ['08:00', '09:50', '11:40'], // Array de horários desejados

        selectedDate: '2024-10-10', // Data padrão
        blockHeight: 50,
        daysOfWeek: [],
        scheduleHours: [
            "07:00", "07:30", "08:00", "08:10", "09:00", "09:10", "10:00", "10:30", 
            "10:50", "11:00", "11:50", "12:40", "13:40", "14:30", "15:30", 
            "16:20", "17:15", "18:05", "19:00", "19:55"
        ],

        eventsByDate: {},

        customTimes: [
         '07:30', '08:45', '10:30', '11:20' // Horários personalizados
        ],

        espaco : `⠀`,
        lista_horarios: [],
        lista_instrutores: [],
        lista_veiculos: [],
        lista_cadastros: [],
        lista_aulas_aluno: [],

        horarios_veiculo :{},

        instrutor_selecionado:{'CODIGO':'0'},
        veiculo_selecionado:{'CODIGO':'0'},
        cadastro_selecionado:{'CODIGO':'0'},

        filtrar_instrutor:false,
        filtrar_veiculo:false,

        dialog_img_mais:'',
        dialog_img_titulo:'',
        dialog_img_url:'',
        dialog_img: false,
 

        first_time :"07:00:00",
        interval_count : "164",//24 144passo um qt grande para gerar ate umas 21hs por ai
        interval_height : "10",//45 10 deixo o tamanho pequeno pois estou gerando de 5 minutos
        interval_minutes :"5",//45 5 minutos para gera varios horarios e depois filtrar os que estao no array


        ready: false,

        carregou : false,


        selectedDate: '',

        today : '',
        in_today : '',
        data_inicial:'',
        data_final:'',

        type: 'week',
        // types: ['month', 'week', 'day'],
        
        viewOptions: [
        { text: 'Mês', value: 'month' },
        { text: 'Semana', value: 'week' },
        { text: 'Dia', value: 'day' },
       ],

        mode: 'column',
        modes: ['stack', 'column'],
        weekday: [1, 2, 3, 4, 5, 6],
        weekdays: [{ text: 'Seg - Sab', value: [1, 2, 3, 4, 5, 6] },],
        

        value: '',
        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,
        events: [],
        items_eventos: [],

        colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
        names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
        

        w_data: '99px',
        w_fone: '125px',
        w_cpf: '120px',
        w_cnpj: '150px',
        w_nome: '220px',
        w_situacao: '60px',
        w_valor: '90px',

        // ------------ TABELAS INICIO ---------------
        icone_tabela_sel:'',
        selected: [],

        selectedItem: -1,
        card_titulo:'',
        dialog_tabela: false,
        tabela_selecionada:'',
        search: '',

        lista_tabelas:[],
        selected: [],
        items_lista:[],
        items_header: [],
        items_editaveis: [],

        item_edita: {},

        sub_codigo:0,
        sub_descricao:'',

        valido: false,
        
        size_mobile:22,

        notnullRules: [
        v => !!v || 'necessário'
        ],

        noRules:[v => true],


        // ------------ TABELAS FIM ---------------

        snackbar: false,
        snack_text: 'Nova Mensagem',
        snack_color: "success",

        }),
    
    
      created(){
        this.onCreated()
      },

      computed: {
        cal () {
            return this.ready ? this.$refs.calendar : null
        },
        nowY () {
            console.log('this.cal.times.now:', this.cal.times.now);
            
            return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px'
        },
      },

      mounted () {
        this.ready = true
        this.scrollToTime()
        this.updateTime()
      },


      watch:{
           
        janela_modal_abre(a){
            // quando fecho a janela pode chamar uma funcao
            if (!a){
              this.acao_after_modal(this.janela_modal_tabela)//passo o nome da tabela
            }
        },

        value_expansion_1(a){
            sessionStorage['agenda_value_expansion_1']     = JSON.stringify(a)
        },

        veiculo_selecionado(a){
            this.transformarHorarios();
        },

        in_today(a){
            this.selectedDate = a
        },

      },
    

    
      methods:{


        imprimir(){
        
            this.vai_imprimir = true

            setTimeout(function(){
            window.print()
            this.vai_imprimir = false  
            
            // this.dialog_protocolo = false
            }.bind(this), 111);
        },

        cor_limite(cat){

            try{  
                let limite = parseInt(this.veiculo_selecionado.LIMITE_TOTAL)     || 10000
                let jatem  = parseInt(this.lista_aulas_aluno[0][`TOTAL_${cat}`]) || 10000
                
                if (this.veiculo_selecionado.CATEGORIA == cat){
                    if (jatem >= limite){
                        // return 'warning'
                        return 'success'
                    }
                }
            }catch(e){}
                
            return ''
        },
        


        async restricoes_agendamento(item, hora){

            // console.log('item:', item);
            
            let ide       = this.cadastro_selecionado.CODIGO_ALUNO
            let carro     = Object.assign({}, this.veiculo_selecionado)  
            let horarios  = Object.assign({}, this.horarios_veiculo)  
            let retorno   = {}

            let sql = ``
            let LT_DIA = 4 //limite total de agendameetnos por dia
            


            //1º verifica aluno ou veiculo agendado neste horario
            sql = `
            SELECT IFNULL(
                (SELECT codigo 
                FROM agenda 
                WHERE data   = '${item.date}' 
                AND hora     = '${hora}' 
                AND ativo    = 'S' 
                AND (CODIGO_ALUNO = ${ide} or CODIGO_VEICULO = ${carro.CODIGO} )
                LIMIT 1), 
                0
            ) AS codigo;
            `
       
            // console.log('sql:', sql);
            retorno = await this.crud_abrir_tabela(sql, false)
            retorno = retorno[0]
            // console.log('retorno:', retorno);

            if (retorno.codigo>0){
                this.alerta(`Aluno ou Veículo já agendado nesta Data e Hora! (${retorno.codigo})`, 'orange')
                return false
            }
            





            

            //2º LIMITES POR DIA E SEMANAS POR CATEGORIA E TOTAL DE AULAS AGENDADAS
            sql = `
                SELECT

                '${carro.CATEGORIA}' AS CATEGORIA,

                IF(
                    COUNT(CASE WHEN (AGENDA.CODIGO_ALUNO = ${ide}) AND (AGENDA.DATA = '${item.date}') AND (AGENDA.CATEGORIA = '${carro.CATEGORIA}') AND (AGENDA.ATIVO = 'S') THEN AGENDA.CODIGO END) >= ${horarios.LIMITE_DIA},
                    COUNT(CASE WHEN (AGENDA.CODIGO_ALUNO = ${ide}) AND (AGENDA.DATA = '${item.date}') AND (AGENDA.CATEGORIA = '${carro.CATEGORIA}') AND (AGENDA.ATIVO = 'S') THEN AGENDA.CODIGO END),
                    0
                ) AS LIMITE_DIA,
                

                (
                        SELECT 
                            IF(COUNT(*) >= ${horarios.LIMITE_SEMANA}, COUNT(*), 0) AS LIMITE_SEMANA
                        FROM (
                            SELECT DISTINCT AGENDA.DATA  -- Apenas uma entrada por dia
                            FROM AGENDA
                            WHERE AGENDA.CODIGO_ALUNO    = ${ide}
                                AND AGENDA.DATA BETWEEN '${this.data_inicial.date}' AND '${this.data_final.date}'
                                AND DAYOFWEEK(AGENDA.DATA) IN (2, 3, 4, 5, 6)  -- Segunda a sexta
                                AND AGENDA.CATEGORIA     = '${carro.CATEGORIA}'
                                AND AGENDA.ATIVO         = 'S'
                        ) AS dias_unicos
                    ) AS LIMITE_SEMANA_ANTES,


                IF(
                    COUNT(CASE WHEN (AGENDA.CODIGO_ALUNO = ${ide}) AND (AGENDA.DATA = '${item.date}') AND (AGENDA.ATIVO = 'S') THEN AGENDA.CODIGO END) >= ${LT_DIA},
                    COUNT(CASE WHEN (AGENDA.CODIGO_ALUNO = ${ide}) AND (AGENDA.DATA = '${item.date}') AND (AGENDA.ATIVO = 'S') THEN AGENDA.CODIGO END),
                    0
                ) AS TOTAL_DIA,

                IF(
                    COUNT(CASE WHEN (AGENDA.CODIGO_ALUNO = ${ide}) AND (AGENDA.CATEGORIA = '${carro.CATEGORIA}') AND (AGENDA.DATA BETWEEN '${this.data_inicial.date}' AND '${this.data_final.date}') AND (AGENDA.ATIVO = 'S') THEN AGENDA.CODIGO END) >= ${horarios.LIMITE_SEMANA},
                    COUNT(CASE WHEN (AGENDA.CODIGO_ALUNO = ${ide}) AND (AGENDA.CATEGORIA = '${carro.CATEGORIA}') AND (AGENDA.DATA BETWEEN '${this.data_inicial.date}' AND '${this.data_final.date}') AND (AGENDA.ATIVO = 'S') THEN AGENDA.CODIGO END),
                    0
                ) AS LIMITE_SEMANA,


                IF(
                    COUNT(CASE WHEN (AGENDA.CODIGO_ALUNO = ${ide}) AND (AGENDA.CATEGORIA = '${carro.CATEGORIA}') AND (AGENDA.ATIVO = 'S') THEN AGENDA.CODIGO END) >= ${horarios.LIMITE_TOTAL},
                    COUNT(CASE WHEN (AGENDA.CODIGO_ALUNO = ${ide}) AND (AGENDA.CATEGORIA = '${carro.CATEGORIA}') AND (AGENDA.ATIVO = 'S') THEN AGENDA.CODIGO END),
                    0
                ) AS TOTAL_GERAL

                FROM AGENDA;
            `


            // console.log('sql:', sql);
            retorno = await this.crud_abrir_tabela(sql, false)
            retorno = retorno[0]
            // console.log('retorno:', retorno);


            if (retorno.TOTAL_GERAL > 0){
                alert(`Aluno já atingiu Limite Total de ${retorno.TOTAL_GERAL} Aulas para a Categoria ${retorno.CATEGORIA} !`)
                return false
            }
            else if (retorno.LIMITE_DIA > 0){
                alert(`Aluno já atingiu Limite de ${retorno.LIMITE_DIA} Aulas no dia para a Categoria ${retorno.CATEGORIA} !`)
                return false
            }
            else if (retorno.TOTAL_DIA > 0){
                alert(`Aluno já atingiu Limite de ${retorno.TOTAL_DIA} Aulas no Dia!`)
                return false
            }
            else if (retorno.LIMITE_SEMANA > 0){
                alert(`Aluno já atingiu Limite de ${retorno.LIMITE_SEMANA} Aulas por Semana na Categoria ${retorno.CATEGORIA} !`)
                return false
            }
            





            return true

        },

        async imprimir_conteudo(modelo_relatorio, headers, items, titulo, ocultar_header, auto_print, largura){

            let componente = ''

            if (items.constructor === Array){
                componente = 'v-data-table'
                headers = JSON.stringify(headers)
                items = JSON.stringify(items)
            }
            else{
                componente = 'v-textarea'
            }


            // monto o objeto que sera lido la em relatorios.vue
            let obj_dados_impressao = {}

            this.$set(obj_dados_impressao, 'relatorios_show'    , true) //permite ver relatorio
            this.$set(obj_dados_impressao, 'qual'               , 'AUTO')
            this.$set(obj_dados_impressao, 'modelo_relatorio'   , modelo_relatorio)
            this.$set(obj_dados_impressao, 'componente'         , componente)
            this.$set(obj_dados_impressao, 'headers'            , headers)
            this.$set(obj_dados_impressao, 'items'              , items)
            this.$set(obj_dados_impressao, 'titulo'             , titulo)
            this.$set(obj_dados_impressao, 'auto_print'         , auto_print )
            this.$set(obj_dados_impressao, 'ocultar_header'     , ocultar_header) 
            this.$set(obj_dados_impressao, 'largura'            , largura) 

            this.$set(obj_dados_impressao, 'cadastro'           , this.cadastro_selecionado) 

            // salvo na session para ser lido em relatorios.vue
            sessionStorage['obj_dados_impressao']  =  JSON.stringify(obj_dados_impressao)

            // abro a pagina relatorios.vue
            let route = this.$router.resolve({path: '/relatorios'});
            window.open(route.href, '_blank');


            // apos aberto, volto para false para nao ser lido fora do contexto
            this.$set(obj_dados_impressao, 'relatorios_show'    , false)
        },

        async muda_situacao(situacao){
            let sql = `update agenda set SITUACAO = '${situacao}' where CODIGO = ${this.item_menu.CODIGO}`
            // console.log('sql:', sql);

            await this.crud_sql(sql)
            await this.tabela('agenda')//atualiza v-calendar
            
        },

        async seleciona_aluno(item_cadastro){
           
           this.cadastro_selecionado   = Object.assign({}, item_cadastro) 
           this.salva_session('cadastro_selecionado')
           this.aulas_alunos(this.cadastro_selecionado.CODIGO_ALUNO || this.cadastro_selecionado.CODIGO)


           // evita duplicidade  adiciona na lista
           if (!this.lista_cadastros.some(cadastro => cadastro.CODIGO === item_cadastro.CODIGO)) {
               this.lista_cadastros.push(item_cadastro);
           }
        },

        async buscar_cadastro(ide){
            let sql = `
                SELECT 
                    ALUNOS.CODIGO, 
                    ALUNOS.CODIGO_ALUNO, 
                    ALUNOS.NOME, 
                    ALUNOS.ATIVO, 
                    ALUNOS.FOTO_URL,
                    ALUNOS.PROCESSO
                FROM 
                    ALUNOS
                WHERE
                    ALUNOS.ATIVO = 'S' AND
                    ALUNOS.CODIGO_ALUNO = ${ide}
                ORDER BY 
                    ALUNOS.NOME;
            `
            // console.log('sql:', sql);
            

            let item_cadastro = await this.crud_abrir_tabela(sql, false)
            // console.log('retorno:', item_cadastro);
            item_cadastro = item_cadastro[0]//mudo para objeto
            // console.log('item_cadastro:', item_cadastro);

            await this.seleciona_aluno(item_cadastro)
            
        },

        vif_here(acao){

            if ( (acao == 'abrir_cadastro') || (acao == 'selecionar_cadastro') || (acao == 'definir_situacao') ) {
                // (this.souDSi)
               return  (this.item_menu.CODIGO_ALUNO > 0)
            }
            else{
                return true
            }
        },

        abrir_cadastro(ide, nova_aba){
           sessionStorage['cadastro_abrir_ide'] = ide

           if (nova_aba){
            let route = this.$router.resolve({path: '/cadastro'});
             window.open(route.href, '_blank');
          }
          else{
             this.$router.push('/cadastro')//redireciono para a pagina do router
          }
           
        },

        clicou_hoje(){
            this.in_today     = this.today
            this.selectedDate = this.today
            this.tabela('agenda')  
        },

        cor_cadastro(CODIGO_ALUNO){

            // console.log('CODIGO_ALUNO:', CODIGO_ALUNO);
            
            if (CODIGO_ALUNO<=0){
                return 'orange'
            }
            else if (CODIGO_ALUNO == this.cadastro_selecionado.CODIGO_ALUNO){
                return 'blue'
            }
            else {
                return ''
            } 
        },

        async entao_remover_aula(item_menu, tipo){

            // console.log('entao_remover_aula:', item_menu );
            
            if (item_menu.CODIGO < 1){
               return false
            }


            if (!confirm( (tipo == 'um') ? "Excluir este Agendamento?" : 'Excluir Todos os Agendamentos deste Aluno?')){
                return false
            }

            let sql = ``

            if (tipo == 'um'){
               sql = `update agenda set ATIVO = 'X' where CODIGO = ${item_menu.CODIGO}`
            }
            else if (tipo == 'todos'){
                sql = `update agenda set ATIVO = 'X' where CODIGO_ALUNO = ${item_menu.CODIGO_ALUNO}`
            }

            // console.log('sql:', sql);
            
            await this.crud_sql(sql)
            await this.tabela('agenda')//atualiza v-calendar
            await this.aulas_alunos(this.cadastro_selecionado.CODIGO_ALUNO || this.cadastro_selecionado.CODIGO)//atualiza lista de aulas do aluno


        },

       clicou_menu_de_tabelas(acao){
          // alert(acao)
          this.dialog_menu = false

          if (acao == 'imprimir_aulas_alunos'){

            let item  =  Object.assign({}, this.cadastro_selecionado)

            console.log('item:', item.NOME);
            

            let titulo = `COMPROVANTE DE AULAS \n ${item.NOME} \n PROCESSO: ${item.PROCESSO}  ⠀  IDE:${item.CODIGO_ALUNO}`
            this.imprimir_conteudo(acao, this.header_aulas_aluno, this.lista_aulas_aluno, titulo, false, false, '50%')
          }

          else if (acao == 'remover_aula'){
             this.entao_remover_aula(this.item_menu, 'um')
          }
          
          else if (acao == 'remover_aula_todas'){
             this.entao_remover_aula(this.item_menu, 'todos')
          }
          
          else if (acao == 'abrir_cadastro'){
             this.abrir_cadastro(this.item_menu.CODIGO_ALUNO, true)
          }
          
          else if (acao == 'selecionar_cadastro'){
             this.buscar_cadastro(this.item_menu.CODIGO_ALUNO)
          }
          
          else if (acao.includes('defx_')){
            // defx_confirmado
            acao = acao.split('_')[1] //copia tudo depois do _ na string acao
            acao = acao.trim().toUpperCase()
            this.muda_situacao(acao)
          }
          
       },

       retorna_titulo(menu_selecionado, item_menu){
         
        // alert(tabela)
        if (menu_selecionado == 'agenda'){
            return `${item_menu.x_DATA} - ${item_menu.HORA} - (${item_menu.CODIGO})`
            // return `${item_menu.x_DATA} às ${item_menu.HORA}`
         }

         else if (menu_selecionado == 'menu_evento'){
            return `${item_menu.x_DATA} - ${item_menu.HORA} - (${item_menu.CODIGO})`
         }
         
         else if (menu_selecionado == 'menu_aulas'){
            return `${this.cadastro_selecionado.NOME}`
         }
       },

       rightClick(item, event) {
         // console.log('rightClick event:', event)
         // console.log('rightClick item:', item)

         this.abre_menu(item)
         try{ 
            event.preventDefault()
         }catch(e){}
       },

       abre_menu(item){
        
        // console.log('abre_menu:', item);
        
        this.dialog_menu = true
        // this.item_menu = item
        if (item){
           this.item_menu  = Object.assign({}, item)  
        }


        this.seleciona_linha(item)
      },

        monta_menu_de_tabelas(qual){


          this.menu_de_tabelas              = []//limpa
          this.menu_de_tabelas_selecionados = []//limpa

          this.menu_selecionado = qual

        //   if (this.pode_alterar(this.tabela_selecionada)){

        //         this.menu_de_tabelas.push(   
        //         {type:'menu', title: 'Alterar',   icon:'mdi-pencil',    icon_color: 'primary',    click:'alterar' },
        //         )
        //   }

          if (qual == 'agenda'){

            this.menu_de_tabelas.push(    
                {type:'menu', title: 'Excluir',        icon:'mdi-close',   icon_color: 'red',        click:'remover_aula' },
                {type:'menu', title: 'Excluir Todos',   icon:'mdi-delete-circle-outline',   icon_color: 'red',        click:'remover_aula_todas' },
            )

            // this.menu_de_tabelas_selecionados.push(    
            //     {type:'menu', title: 'Gerar Despesas ...',       icon:'mdi-currency-usd',    icon_color: 'primary',    click:'gerar_despesas' },
            // )
          }

          else if (qual == 'menu_aulas'){

            this.menu_de_tabelas.push(    
                {type:'menu', title: 'Imprimir',        icon:'mdi-printer',   icon_color: 'primary',        click:'imprimir_aulas_alunos' },
            )

            this.abre_menu(false)

          }

          else if (qual == 'menu_evento'){

            this.menu_de_tabelas.push(    
                {type:'menu', title: 'Cadastro',            icon:'mdi-account',          icon_color: 'primary',    click:'abrir_cadastro' },
                {type:'menu', title: 'Selecionar Cadastro', icon:'mdi-account-search',   icon_color: 'primary',    click:'selecionar_cadastro' },

                
                {
                    type:'group',
                    icon:'mdi-circle',
                    icon_color: 'blue',
                    title: 'Situação',
                    click: 'definir_situacao', 
                    items: [
                        {type:'', title: 'Confirmado',        icon:'mdi-square', icon_color: '#800080',       click:'defx_confirmado'},
                        {type:'', title: 'Faltou',            icon:'mdi-square', icon_color: 'red lighten-2', click:'defx_faltou'},
                        {type:'', title: 'Concluído',         icon:'mdi-square', icon_color: 'green',         click:'defx_concluido'},
                        {type:'', title: 'Marcado',           icon:'mdi-square', icon_color: 'grey',          click:'defx_marcado'},
                        // {type:'', title: 'Aula Lançada',      icon:'mdi-square', icon_color: 'yellow',  click:'def_lancada'},
                    ],
                },
                
                {type:'menu', title: 'Excluir',             icon:'mdi-close',            icon_color: 'red',        click:'remover_aula' },


            )

          }
        },

        altura_desse(){
            let r   = 0
            let dif = 190
            
            if (this.value_expansion_1 >=0 ){
                dif = 460
            }

            r = this.retorna_altura(dif)
            
            return r
        },

        async aulas_alunos(ide){
            

            if (ide<1){
                this.lista_aulas_aluno = []
                return false
            }

            let sql = `
            SELECT 
                *,
                (SELECT COUNT(agenda.CATEGORIA) FROM agenda WHERE CODIGO_ALUNO = ${ide} AND CATEGORIA = 'A' AND ATIVO = 'S') AS TOTAL_A,
                (SELECT COUNT(agenda.CATEGORIA) FROM agenda WHERE CODIGO_ALUNO = ${ide} AND CATEGORIA = 'B' AND ATIVO = 'S') AS TOTAL_B,
                (SELECT COUNT(agenda.CATEGORIA) FROM agenda WHERE CODIGO_ALUNO = ${ide} AND CATEGORIA = 'C' AND ATIVO = 'S') AS TOTAL_C,
                (SELECT COUNT(agenda.CATEGORIA) FROM agenda WHERE CODIGO_ALUNO = ${ide} AND CATEGORIA = 'D' AND ATIVO = 'S') AS TOTAL_D
            FROM 
                agenda
            WHERE 
                ATIVO = 'S' AND
                CODIGO_ALUNO = ${ide}`

            // console.log('sql:', sql);
            
            await this.crud_abrir_tabela(sql, 'lista_aulas_aluno')

        },

        salva_session(tipo){           
            sessionStorage[tipo]  =  JSON.stringify(this[tipo])
        },

       abre_janela_modal(status, edicao, tabela, titulo){

         this.janela_modal_tabela   = tabela
         this.janela_modal_titulo   = titulo
         this.janela_modo_edicao    = edicao
         this.janela_modal_abre     = status
       },  

       janela_modal_change(valor){
        this.janela_modal_abre = valor

        if (!valor){
          // alert('atualiza algo nesta janela')
        }
      },

      async acao_after_modal(acao){
        if (acao == 'ALUNOS'){
          
           let ccadastro
           
           try{
             ccadastro = JSON.parse(sessionStorage['cadastro_selecionados'])
             ccadastro = ccadastro[0]
           }catch(e){
             return false
           }


           if (!ccadastro){
            return false
           }

          //console.log('ccadastro:', ccadastro);
           await this.seleciona_aluno(ccadastro)
           
        }
      },

      calculateTop(time) {
        // Exemplo: converter o horário para pixels
        // Aqui, considere que um horário representa 60px, ajuste conforme necessário
        const [hours, minutes] = time.split(':').map(Number);
        const totalMinutes = hours * 60 + minutes;

        return this.cal ? this.cal.timeToY(totalMinutes) + 'px' : '-10px'
        // return `${totalMinutes}px`; // Ajuste a fórmula conforme necessário
      },

      getCurrentTime () {
        return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0
      },

      scrollToTime () {
        const time = this.getCurrentTime()
        const first = Math.max(0, time - (time % 30) - 30)

        this.cal.scrollToTime(first)
      },

      updateTime () {
        setInterval(() => this.cal.updateTimes(), 60 * 1000)
      },


        transformarHorarios() {
           const horariosArray = this.veiculo_selecionado.HORARIOS ? this.veiculo_selecionado.HORARIOS.split('\n') : [];
           

           // Adiciona o veículo selecionado ao objeto horarios_veiculo
           this.horarios_veiculo = {
                NOME          : this.veiculo_selecionado.NOME,
                DURACAO       : this.veiculo_selecionado.DURACAO,
                LIMITE_DIA    : this.veiculo_selecionado.LIMITE_DIA     > 0 ? this.veiculo_selecionado.LIMITE_DIA       : 100000,
                LIMITE_SEMANA : this.veiculo_selecionado.LIMITE_SEMANA  > 0 ? this.veiculo_selecionado.LIMITE_SEMANA    : 100000,
                LIMITE_TOTAL  : this.veiculo_selecionado.LIMITE_TOTAL   > 0 ? this.veiculo_selecionado.LIMITE_TOTAL     : 100000,
                HORARIOS      : horariosArray
            };



           // Expressão regular para validar o formato HH:MM
           const regexHora = /^([01]\d|2[0-3]):([0-5]\d)$/;

           // Filtrar apenas os horários válidos
           this.horarios_veiculo.HORARIOS = this.horarios_veiculo.HORARIOS.filter(horario =>
             regexHora.test(horario)
           );


           if (this.horarios_veiculo.HORARIOS.length === 0) {
              // caso nao tenha definido entao preencho manualmente
              this.horarios_veiculo.HORARIOS = [
                  '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', 
                  '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', 
                  '19:00', '20:00'
              ];

              this.horarios_veiculo.DURACAO = '60'
           }


           try{
             this.first_time = this.horarios_veiculo.HORARIOS[0] || '07:00'
           }catch(e){}
        },

        getColor(item){

            // console.log('item:', item);

            let ss = item.SITUACAO
            let r = ''
            
            if (ss == 'MARCADO'){
                r =  'grey lighten-0'
            }

            else if (ss == 'CONFIRMADO'){
                r =  '#800080'
                // r =  'green lighten-2'
            }

            else if (ss == 'CONCLUIDO'){
                r =  'green'
            }

            else if (ss == 'FALTOU'){
                // r =  '#ff8080'
                r =  'red lighten-2'
            }

            else{
                r = 'blue'
            }

            return r
        },

        abre_img(titulo, url_img, mais){
          this.dialog_img        = true
          this.dialog_img_titulo = titulo
          this.dialog_img_url    = url_img
          this.dialog_img_mais   = mais
        },

        async abre_listas(){

            // INSTRUTORES
            let sql = `select *  from instrutores where ativo = 'S'`
            // console.log('sql:', sql);
            await this.crud_abrir_tabela(sql, 'lista_instrutores')
            this.lista_instrutores.unshift({CODIGO:'0', NOME:'TODOS'}) //no começo




            // VEICULOS
            sql = `select *  from veiculos where ativo = 'S'`
            // console.log('sql:', sql);
            await this.crud_abrir_tabela(sql, 'lista_veiculos')
            this.lista_veiculos.unshift({CODIGO:'0', NOME:'TODOS'}) //no começo

            // pega os dados atualizados
            if (this.veiculo_selecionado.CODIGO>0){
                this.veiculo_selecionado  = Object.assign({}, this.lista_veiculos.find(veiculo => veiculo.CODIGO === this.veiculo_selecionado.CODIGO) )  
            }


            
            this.lista_cadastros.unshift({CODIGO:'-6', NOME:'FERIAS'}) //no começo
            this.lista_cadastros.unshift({CODIGO:'-5', NOME:'AULA EXTRA'}) //no começo
            this.lista_cadastros.unshift({CODIGO:'-4', NOME:'EX.PRATICO'}) //no começo
            this.lista_cadastros.unshift({CODIGO:'-3', NOME:'ALMOÇO'}) //no começo
            this.lista_cadastros.unshift({CODIGO:'-2', NOME:'INTERVALO'}) //no começo
            this.lista_cadastros.unshift({CODIGO:'-1', NOME:'LIVRE'}) //no começo

            this.lista_cadastros.unshift({CODIGO:'0' , NOME:'NENHUM'}) //no começo

        },

        async define_dia(){
            this.today    = await get_data_hora('today')
            this.in_today = await get_data_hora('today')
            this.carregou = true

            this.tabela('agenda')
        },


        async abre_evento(item){

            // console.log('abre_evento:', item);
            // console.log('abre_evento:', item.event);
            
            // mostro menu com opcoes
            this.monta_menu_de_tabelas('menu_evento')
            this.rightClick(item.event, false)


            // aqui abriria o dialog de edição 
        //    await this.editar_tabela(item.event, 'item_edita', 'dialog_tabela')
        //    await this.renomeiaPropredades(this.item_edita)

            item.nativeEvent.stopPropagation()
        },


        async renomeiaPropredades(item) {

            //obj para string 
           let ss =  JSON.stringify(item)

           // campos para renomear
           ss = ss.replaceAll('"start"'     , '"x_start"') 
           ss = ss.replaceAll('"end"'       , '"x_end"') 
           ss = ss.replaceAll('"name"'      , '"x_name"') 
           ss = ss.replaceAll('"color"'     , '"x_color"') 
           ss = ss.replaceAll('"timed"'     , '"x_timed"') 
           

          //volta string para objeto    
           item = JSON.parse(ss)

           this.item_edita  = Object.assign({}, item)  


        },



        async clicou_hora(item){
            // console.log('clicou_hora:', item);

            if (this.veiculo_selecionado.CODIGO<1){
                this.alerta('Selecione o Veículo', 'orange')
                return false
            }
            
            if (this.instrutor_selecionado.CODIGO<1){
                this.alerta('Selecione o Instrutor', 'orange')
                return false
            }
            
            if (this.cadastro_selecionado.CODIGO == 0){
                this.alerta('Selecione um Cadastro', 'orange')
                return false
            }
            

            

            // alert(item.time)
            // let hora = await this.trata_hora(item.time, this.lista_horarios)
            let hora = await this.trata_hora(item.time, this.horarios_veiculo.HORARIOS)
            // alert(hora)


            let restricoes = await this.restricoes_agendamento(item, hora)
            if (!restricoes ){
                // this.alerta('Selecione um Cadastro', 'orange')
                return false
            }


            // abre modal
            this.nova_tabela('item_edita','dialog_tabela')
            this.dialog_tabela = false//fecha modal


            let intervalo = this.horarios_veiculo.DURACAO || 10
            let hora_fim = DSibasico.incMinutes(hora, intervalo )
            hora_fim     =  hora_fim.substring(0, 5);
            

            // define valores default
            this.$set(this.item_edita, 'ATIVO'              , 'S')
            this.$set(this.item_edita, 'CODIGO_ALUNO'       , this.cadastro_selecionado.CODIGO_ALUNO || this.cadastro_selecionado.CODIGO)
            this.$set(this.item_edita, 'CODIGO_VEICULO'     , this.veiculo_selecionado.CODIGO)
            this.$set(this.item_edita, 'CODIGO_INSTRUTOR'   , this.instrutor_selecionado.CODIGO)

            this.$set(this.item_edita, 'DATA'               , item.date)
            this.$set(this.item_edita, 'HORA'               , hora)
            this.$set(this.item_edita, 'HORA_FIM'           , hora_fim)
            this.$set(this.item_edita, 'DURACAO'            , intervalo)
            this.$set(this.item_edita, 'SITUACAO'           , 'MARCADO')
            this.$set(this.item_edita, 'DESCRICAO'          , this.cadastro_selecionado.DESCRICAO)

            this.$set(this.item_edita, 'ALUNO'              , this.cadastro_selecionado.NOME)
            this.$set(this.item_edita, 'PROCESSO'           , this.cadastro_selecionado.PROCESSO)
            this.$set(this.item_edita, 'PLACA'              , this.veiculo_selecionado.PLACA)
            this.$set(this.item_edita, 'CATEGORIA'          , this.veiculo_selecionado.CATEGORIA)
            this.$set(this.item_edita, 'INSTRUTOR'          , this.instrutor_selecionado.NOME)
            
            this.$set(this.item_edita, 'QUEM_ALTEROU'       , DSibasico.get_quem_alterou())


            await this.salvar_tabela(this.tabela_selecionada, this.item_edita, 'dialog_tabela')
            await this.aulas_alunos(this.cadastro_selecionado.CODIGO_ALUNO || this.cadastro_selecionado.CODIGO)
        },

        async gera_lista_horas() {
            const { first_time, interval_count, interval_minutes } = this; // Desestruturação das propriedades

            // alert(interval_minutes)
            // this.interval_minutes = 5
            const timeSlots = [];
            const start = new Date(`1970-01-01T${first_time}`); // Cria um objeto Date a partir da hora inicial

            // Gera os horários começando pelo primeiro horário
            for (let i = 0; i < interval_count; i++) {
                const newTime = new Date(start.getTime() + (i * interval_minutes * 60000)); // Adiciona o intervalo em milissegundos
                const formattedTime = newTime.toTimeString().slice(0, 5); // Formata para 'HH:mm'

                // Adiciona apenas horários que são iguais ou posteriores ao first_time
                if (formattedTime >= first_time.slice(0, 5)) {
                    timeSlots.push(formattedTime); // Adiciona ao array
                }
            }

            this.lista_horarios = timeSlots;
            // return timeSlots; // Retorna o array de horários
        },


        showIntervalLabel (interval) {
        //    return interval.minute === 0
        // console.log('showIntervalLabel:', interval);
        
           return interval
        },


        getStartOfWeek(date) {
            const day = new Date(date);
            const dayOfWeek = day.getDay(); // Domingo é 0, Sábado é 6
            const diff = day.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // Ajusta para segunda-feira
            return new Date(day.setDate(diff));
        },

        async onCreated(){
            this.isMobile = window.innerWidth < 800

            this.value_expansion_2 = (this.isMobile) ? null : 0

            await this.ler_sessions()
            await this.abre_listas()
            await this.gera_lista_horas()
            await this.define_dia()
        },

        viewDay ({ date }) {
            this.focus = date
            this.type = 'day'
        },


        async trata_hora(time, horarios) {
                    
            // faz com que pegue os horarios com base no array da lista de horas
            // console.log('time:', time);
            
            const [hourStr, minuteStr] = time.split(':');
            const hour = parseInt(hourStr, 10);
            const minutes = parseInt(minuteStr, 10);
            
            const selectedTotalMinutes = hour * 60 + minutes;

            // Obtém os slots de horário gerados
            // console.log('this.lista_horarios:', this.lista_horarios);
            const timeSlots = horarios 

            
            let finalTime = '';

            // Percorre a lista de horários para encontrar o mais apropriado
            for (let i = 0; i < timeSlots.length; i++) {
                const slot = timeSlots[i];
                const [slotHour, slotMinute] = slot.split(':');
                const slotTotalMinutes = parseInt(slotHour, 10) * 60 + parseInt(slotMinute, 10);
                
                // Verifica se o horário selecionado não passou do próximo slot
                if (selectedTotalMinutes < slotTotalMinutes) {
                    // Se for o primeiro slot, usa ele
                    if (i === 0) {
                        finalTime = slot; // Usa o primeiro slot
                    } else {
                        // Caso contrário, usa o slot anterior
                        finalTime = timeSlots[i - 1];
                    }
                    break; // Sai do loop
                } else if (i === timeSlots.length - 1) {
                    // Se for o último slot e o horário clicado for maior ou igual, usa o último slot
                    finalTime = slot; 
                }
            }

            // Exibe ou usa o horário final
            // alert(`Horário selecionado: ${finalTime}`);
            return finalTime
        },

        showEvent ({ nativeEvent, event }) {


            const open = () => {
            this.selectedEvent = event
            this.selectedElement = nativeEvent.target
            requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
            }

            if (this.selectedOpen) {
            this.selectedOpen = false
            requestAnimationFrame(() => requestAnimationFrame(() => open()))
            } else {
            open()
            }

            nativeEvent.stopPropagation()
        },

        intervalFormat(interval) {

            // console.log('intervalFormat;', interval );

               // Verifica se o intervalo está na lista_horarios
            try{   
                if (this.horarios_veiculo.HORARIOS.length === 0) {
                    // caso nao tenha definido horario, mostra somente horas em ponto
                    if (interval.minute == '00'){
                        return interval.time
                    }
                }
                else if (this.horarios_veiculo.HORARIOS.includes(interval.time)){
                    // console.log('intervalFormat;', interval );
                    return interval.time; // Retorna o horário se estiver na lista
                }
            }catch(e){
                    // caso nao tenha definido horario, mostra somente horas em ponto
                    if (interval.minute == '00'){
                        return interval.time
                    }
            }

            // return `${interval.start} - ${interval.end}`
        },

        retorna_altura(menos){
            // let r = '100vh'
            // let r = '720'
            let r = (window.innerHeight - 80 - 60)
            let height = window.innerHeight;

            if (menos){
            r = (r - menos)
            }

            return r
        },

        getEvents ({ start, end }) {

            // alert('change')
            // console.log('start:', start);
            // console.log('end:', end);

            this.data_inicial = start
            this.data_final   = end

            
            // isto estava executando antes de carregar as coisas la em onCreated, porisso fiz esse carregou
            if (!this.carregou){
                return false
            }

            this.tabela('agenda')
            

            // this.getStartOfWeek()

            return false
            const events = []

            const min = new Date(`${start.date}T00:00:00`)
            const max = new Date(`${end.date}T23:59:59`)
            const days = (max.getTime() - min.getTime()) / 86400000
            const eventCount = this.rnd(days, days + 20)

            for (let i = 0; i < eventCount; i++) {
            const allDay = this.rnd(0, 3) === 0
            const firstTimestamp = this.rnd(min.getTime(), max.getTime())
            const first = new Date(firstTimestamp - (firstTimestamp % 900000))
            const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
            const second = new Date(first.getTime() + secondTimestamp)

            events.push({
                name: this.names[this.rnd(0, this.names.length - 1)],
                start: first,
                end: second,
                color: this.colors[this.rnd(0, this.colors.length - 1)],
                timed: !allDay,
            })
            }

            this.events = events
        },

      getEventColor (event) {
        return event.color
      },

      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },


        async ler_sessions(){


            // ativado em App.vue
            this.agenda_show = sessionStorage['agenda_show'] == 'S'


            if (!this.agenda_show){
                window.location.replace("https://www.google.com") 
                return false
            }

            console.log('ler_sessions uma tag..se nula fecha janela..para nao usar o logado aqui');


            await DSisql.crud_empresa()//atualiza dados da empresa

            try { this.obj_dados_empresa = JSON.parse(sessionStorage['obj_dados_empresa']) }
            catch (error) { console.log('erro:', error); }

            try{
              this.veiculo_selecionado = JSON.parse(sessionStorage['veiculo_selecionado'])
            }catch(e){}

            try{
              this.instrutor_selecionado = JSON.parse(sessionStorage['instrutor_selecionado'])
            }catch(e){}

            try{
              this.cadastro_selecionado = JSON.parse(sessionStorage['cadastro_selecionado'])
              this.lista_cadastros.push(this.cadastro_selecionado);//add na lista pra mostrar
              await this.aulas_alunos(this.cadastro_selecionado.CODIGO_ALUNO || this.cadastro_selecionado.CODIGO)
            }catch(e){}

            try{
              this.value_expansion_1 = JSON.parse(sessionStorage['agenda_value_expansion_1'])
            }catch(e){}


        },
    

        async tabela(tabela, item){
        //passo 3 executo a acao quando clicar nos items e monto os campos editaveis e os campos do data-table

            // console.log(item);
            // this.icone_tabela_sel = item.icon
            
            if (!item){
             sessionStorage['tabela_clicou']   = tabela
             sessionStorage['tabela_titulo']   = this.card_titulo
            }

            this.rodape = false

            this.search = ''//limpa filtro

            this.tabela_selecionada = tabela


            //limpa array
            this.items_lista       = [] //recebe os dados do sql
            this.items_header      = [] //campos que tem que mostrar no header
            this.items_editaveis   = [] //campos editaveis mas nao tem que mostrar no header

            this.monta_menu_de_tabelas(tabela)

        

            if (tabela=='agenda'){
                
                
                this.items_header = [

                
                  { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width: this.w_actions},
                  // { text: 'CODIGO', value: 'CODIGO', type:'number', cols:'0' },              
                  { text: 'ALUNO', value: 'CODIGO_ALUNO', tag:'v-text', type:'text', cols:'2', rule:'N' },      
                  { text: 'VEICULO', value: 'CODIGO_VEICULO', tag:'v-text', type:'text', cols:'2', rule:'N' },      
                  { text: 'INSTRUTOR', value: 'CODIGO_INSTRUTOR', tag:'v-text', type:'text', cols:'2', rule:'N' },      
                  
                  { text: 'DATA', value: 'DATA', tag:'v-text', type:'date', cols:'2', width: this.w_data,rule:'N' },      
                  { text: 'HORA', value: 'HORA', tag:'v-text', type:'text', cols:'1', rule:'N' },      
                  { text: 'HORA_FIM', value: 'HORA_FIM', tag:'v-text', type:'text', cols:'1', rule:'N' },      
                  { text: 'DURACAO', value: 'DURACAO', tag:'v-text', type:'text', cols:'1', rule:'N' },      

                  { text: 'SITUACAO', value: 'SITUACAO',
                        type:'text',
                        tag:'v-select',
                        tag_items:[
                            'MARCADO',         
                            'CONFIRMADO',         
                            'CONCLUIDO',         
                            'FALTOU',
                        ],
                        cols:'2',rule:'N' 
                  },

                  { text: 'DESCRICAO', value: 'DESCRICAO', tag:'v-text', type:'text', cols:'3', rule:'N',upper:'N' },      

                  ]

                  // clono os items
                  this.items_editaveis = this.items_header.map((x) => x); //clonando array copiar array

                  let cod_instrutor  = ((this.filtrar_instrutor) && (this.instrutor_selecionado.CODIGO)) || 0
                  let cod_veiculo    = ((this.filtrar_veiculo)   && (this.veiculo_selecionado.CODIGO))   || 0


                 let sql = `
                 SELECT 
                    a.CODIGO, 
                    a.CODIGO_ALUNO, 
                    a.CODIGO_VEICULO, 
                    a.CODIGO_INSTRUTOR, 
                    a.DATA, 
                    a.HORA, 
                    a.HORA_FIM, 
                    a.DESCRICAO, 
                    a.SITUACAO, 
                    a.DURACAO,
                    a.ALUNO,
                    a.PROCESSO,
                    a.CATEGORIA,
                    a.QUEM_ALTEROU,
                    COALESCE(v.NOME      , '.'          ) AS x_NOME_VEICULO, 
                    COALESCE(v.PLACA     , a.PLACA      ) AS x_PLACA,           /* caso seja removido o veiculo ou instrutor */
                    COALESCE(i.NOME      , a.INSTRUTOR  ) AS x_NOME_INSTRUTOR,   /* caso seja removido o veiculo ou instrutor */
                    COALESCE(i.FOTO_URL  , ''           ) AS x_FOTO_URL_INSTRUTOR   /* caso seja removido o veiculo ou instrutor */
                FROM 
                    agenda a
                LEFT JOIN 
                    veiculos v ON a.CODIGO_VEICULO = v.CODIGO 
                LEFT JOIN 
                    instrutores i ON a.CODIGO_INSTRUTOR = i.CODIGO 

                WHERE      a.ATIVO = 'S' AND
                           DATA BETWEEN '${this.data_inicial.date}' and '${this.data_final.date}'
                            ${(cod_instrutor>0) ? `and CODIGO_INSTRUTOR = ${cod_instrutor}` : ''} 
                            ${(cod_veiculo>0)   ? `and CODIGO_VEICULO   = ${cod_veiculo}`   : ''} 
                            order by codigo desc
                 `

                //   console.log(tabela+': '+sql);
                  await this.crud_abrir_tabela(sql, 'items_lista')
            

                

                // Mapeia a lista adicionando start e end
                this.items_eventos = this.items_lista.map(item => {
                    const start = `${item.DATA}T${(item.HORA)}`;
                    const end = `${item.DATA}T${(item.HORA_FIM)}`;

                    return {
                        ...item,
                        start   : start,
                        end     : end,
                        name    : `${item.x_PLACA} - ${item.x_NOME_INSTRUTOR} - ${item.DESCRICAO}`,
                        // name    : item.DESCRICAO,
                        // color   : item.COLOR || "blue", // Use uma cor padrão se não especificada
                        color   : this.getColor(item), // Use uma cor padrão se não especificada
                        timed   : true   // Indica que é um evento com horário
                    };
                });


            }

        },

        async before_update(tabela){
        // passo 8 antes de salvar verifico alguns campos necessarios em determinada tabela


            // VERIFICA CAMPOS NUMERICOS QUE NAO PODEM IR VAZIOS
            let VERIFICAR = [
                // 'LANCAMENTOS|DATA|null',
            ]
            
            let CAMPOS      = []
            let CAMPO_VER   = ''
            let CAMPO_VALOR = ''
            for (let i = 0; i < VERIFICAR.length; i++) {
                
                CAMPOS      = VERIFICAR[i].split("|");//divide
                CAMPO_VER   = CAMPOS[1]
                CAMPO_VALOR = this.item_edita[CAMPO_VER]

                if (tabela==CAMPOS[0]){//verifica se é a tabela selecionada
                    if (!CAMPO_VALOR){//verifica se vazio
                    this.$set(this.item_edita, CAMPOS[1], CAMPOS[2] ) 
                    }
                }
            }
        },

        async ultimo_click(){


          let tabela = sessionStorage['tabela_clicou']

          if (this.pro_tabela){
            this.card_titulo = this.pro_titulo
            await this.tabela(this.pro_tabela,'')

            sessionStorage['tabela_clicou'] = this.pro_tabela
          }
          else if (tabela){
              this.card_titulo = sessionStorage['tabela_titulo'] 
              await this.tabela(tabela,'')
          }


          
          //   ja abre em modo edicao
          if (this.pro_modo_edicao){

              if (this.pro_obj_1){
                // alert('editar')
                this.editar_tabela(this.pro_obj_1, 'item_edita', 'dialog_tabela')
              }
              else{
                // alert('novo')
                this.nova_tabela('item_edita','dialog_tabela')
              }
         }

        },

        meu_keyup(event,campo,upper){
  
          //identifica se o campo é double e muda o display
          if ((campo.includes('double_') || campo.includes('VALOR')) ){
              this.item_edita[campo] =  this.moeda(event)
              // @keyup="editedItem.double_credito = moeda($event)"
          }
          else if (campo.includes('CEP')) {
  
              this.consulta_cep(this.item_edita[campo])    
          }
          else{
              if ((campo.includes('EMAIL')==false) &&
              (campo.includes('SENHA')==false) &&
              (campo.includes('SENHA_WEB')==false)){
  
                  if (upper !=='N'){
                  this.allUpper(event, campo)
                  }
                  
              }
          }
        },

        allUpper(event, field){
              let a = event.target.value;//captura o valor do event
              if (a!==''){  
                  a = a.toUpperCase();
                  this.item_edita[field] = a;
              } 
        },

        firstUpper(event, field){
              // console.log(event);
              let a = event.target.value;//captura o valor do event

              if (a!=='' && a.length==1){  
                a = a.toUpperCase();
                // this.item_edita[field] = a;

                event.target.value = a
              } 
          },


        getVmask(qual){
            
            if (qual == 'cel'){
                return "(##) #####-####"
            }
            else if (qual == 'fone'){
                return "(##) ####-####"
            }
            else if (qual == 'cnpj'){
                return "##.###.###/####-##"
            }
            else if (qual == 'cpf'){
                return "###.###.###-##"
            }
            else if (qual == 'cep'){
                return "#####-###"
            }
            else{
                return ""
            }
          },
 

        define_defaults(tabela){

            // passo 7 defino valores default para alguns campos de determinada tabela
            if (tabela=='agenda'){
            //  this.$set(this.item_edita, 'CODIGO_ALUNO', 1)
            //  this.$set(this.item_edita, 'DATA'           , this.in_today)
            }

        },

        async seleciona_linha(row) {
            // console.log('row:');
            // console.log(row);
            this.selected  = [ Object.assign({}, row)] 
                //  this.selected =  [{"CODIGO" : row.CODIGO}] //via v-modal no data-table
        },    
    
    
    
    
        alerta(text,color){
            DSibasico.alerta(this, text, color)
        },

        dif_datas(a,b){
            return DSibasico.dif_datas(a,b)
        },
    
        incDay(data,dias,pt){
            return DSibasico.incDay(data,dias,pt)
        },
    
        incMonth(data,dias,pt){
            return DSibasico.incMonth(data,dias,pt)
        },
    
        alerta2(text,color){
            // alert(text);
             this.snackbar = true;
             this.snack_text= text;
    
             this.snack_color = color;
        },
    
        define_data_hora(){
            DSibasico.define_data_hora(this)//passo o this para que la ele defina as variaveis daqui
        },
    


           //---------------importando funcoes sql ----------------------- INICIO
          //EXECUTAR UM SQL TIPO UPDATE
          async crud_sql (sql) {
              await DSisql.crud_sql(this, sql)
          },
  
          async tabela_create(tabela, obj_edita){
              await DSisql.tabela_create(this, tabela, obj_edita)
          },
  
          async tabela_update(tabela, obj_edita){
              await DSisql.tabela_update(this, tabela, obj_edita)
          },
  
          //ABRE OS DADOS DA TABELA
          async crud_abrir_tabela(sql, items_name){
              return await DSisql.crud_abrir_tabela(this, sql, items_name)
          }, 
  
          //FICA NO BOTAO SALVAR.. VERIFICA SE UPDATE OU CREATE
          async salvar_tabela(tabela, obj_edita, dialog_name){

              await this.before_update(this.tabela_selecionada)//trato alguns campos necessarios

              await DSisql.salvar_tabela(this,tabela, obj_edita, dialog_name)

            //   if (this.tabela_selecionada == 'agenda'){
            //     alert('here')
            //   }
          },
  
          async remover_tabela(tabela, obj_edita, titulo){
              await DSisql.remover_tabela(this, tabela, obj_edita, titulo)
          },
  
          async editar_tabela(item, obj_edita_name, dialog_name) {
              await this.seleciona_linha(item)
              await DSisql.editar_tabela(this, item, obj_edita_name, dialog_name)
          },
  
          nova_tabela(obj_edita_name,dialog_name){

             DSisql.nova_tabela(this, obj_edita_name,dialog_name)

             //define alguns valores default
             this.define_defaults(this.tabela_selecionada)
          },


      },
    
    
    }
    </script>
    
    <style scoped>



    .container{
        max-width: 100%;
    }

    ::v-deep .v-expansion-panel-content__wrap {
    padding: 0 2px 2px !important;
    }

    .v-list-item {
    padding: 0 9px;
    }


      @media (max-width: 480px) {
        .chat-container .content{
          max-width: 60%;
        }
      }
    

    .fs-7{
    font-size: 7px !important;
    }
    .fs-8{
        font-size: 8px !important;
    }
    .fs-9{
        font-size: 9px !important;
    }
    .fs-10{
        font-size: 10px !important;
    }
    .fs-11{
        font-size: 11px !important;
    }
    .fs-12{
        font-size: 12px !important;
    }
    .fs-13{
        font-size: 13px !important;
    }
    .fs-14{
        font-size: 14px !important;
    }
    .fs-15{
        font-size: 15px !important;
    }
    .fs-16{
        font-size: 16px !important;
    }
    .fs-17{
        font-size: 17px !important;
    }
    .bold{
        font-weight: bold !important;
    }

    .fixed-width-col {
        width: 900px !important; /* Defina a largura fixa desejada */
    }



    /* CALENDÁRIO */

    /* Título do calendário onde ficam os anos */
    ::v-deep .v-picker__title {
        padding-top: 6px !important;
        padding-bottom: 1px !important;
    }

    /* Título com a data no calendário */
    ::v-deep .v-date-picker-title__date {
        font-size: 16px !important;
        height: 16px !important;
    }

    /* O ano no calendário */
    ::v-deep .v-date-picker-title__year {
        display: inline !important;
    }

    /* Dias do calendário (botões) */
    ::v-deep .v-date-picker-table .v-btn {
        height: 18px !important;
    }

    /* Meses no calendário */
    ::v-deep .v-date-picker-table--month td {
        height: 36px !important;
    }

    /* Altura da tabela do calendário */
    ::v-deep .v-date-picker-table {
        height: 190px !important;
    }

    /* Dia da semana do calendário */
    ::v-deep .v-application--is-ltr .v-date-picker-title .v-picker__title__btn {
        height: 0;
    }

    /* Header do calendário */
    ::v-deep .v-date-picker-header {
        padding: 0 !important;
        font-size: 16px;
    }
    

    /* Alinhamento do título do date picker */
    ::v-deep .v-date-picker-title {
        flex-direction: row !important;
    }

    /* Estilo das células da tabela do calendário */
    ::v-deep .v-date-picker-table--date th, 
    ::v-deep .v-date-picker-table--date td {
        padding: 0 !important;
    }
   

    /* Estilo do botão do calendário */
    ::v-deep .v-date-picker .v-btn__content {
        background-color: red;
    }



    /* FIM DO CALENDÁRIO */

    


    /* @media only screen and (min-width: 500px) {

.grid-1 div {
  font-size: 20px;
  padding-top: 0px;
  padding-left: 2px;
}

} */

    /* specific item styles */


    .item-2 {
    /* grid-area: main; */

    display: grid;

    grid-template-columns: 100%;
    word-wrap: break-word;
    grid-template-rows: auto;
    grid-gap: 5px;

    grid-template-areas: "header"
                    "article"
                    "sidebar";

    }

    /* rwd */

    @media only screen and (min-width: 600px) {

    .item-2 {
    
    grid-template-columns: 283px auto;
    grid-template-rows: auto auto;
    
    grid-template-areas: "header header"
                        "article sidebar";
    
    }

    }
    .item-3 {
    grid-area: sidebar;
    }
    .item-4 {
    grid-area: footer;
    }
    .item-5 {

    grid-area: header;
    }
    .item-6 {
      grid-area: article;
    }
    .item-7 {
      grid-area: sidebar;
    }


    /* V-CALENDAR */

    ::v-deep .v-calendar-daily__interval {
      /* Ajuste para dar mais espaço ao horário */
      padding-top: 4px; 

       /* padding-right: 1px;  */
    }

    ::v-deep .v-calendar-daily__interval-text {

      /* Aumentar o tamanho da fonte */
      font-size: 12px; 

        /* Deixar a fonte em negrito */
        font-weight: bold;
    }

    ::v-deep .v-calendar-daily__interval-label {
      margin-left: 5px; /* Move o rótulo do intervalo de tempo */
      z-index: 2; /* Garante que o texto do horário fique acima da linha de grade */
    }

    ::v-deep .v-calendar-daily__interval-line {
      opacity: 0.5; /* Torna a linha de grade mais suave para não sobrepor o horário */
      border-color: #ccc;
    }

    /* V-CALENDAR */





    /* MEU CALENDARIO */

    .schedule-wrapper {
  display: flex;
}

.fixed-column {
  width: 120px; /* Ajuste para a largura da coluna fixa */
}

.scrollable-columns {
  flex: 1; /* O restante do espaço será ocupado pelas colunas de dias */
  overflow-x: auto; /* Permite rolagem horizontal se necessário */
  display: flex;
}

.day-column {
  flex: 1; /* Cada coluna do dia ocupa um espaço igual */
  margin: 0 5px; /* Margem entre as colunas */
}

.block-item {
  border: 1px solid #e0e0e0; /* Borda para os itens */
}

.block-content {
  padding: 5px; /* Espaçamento interno */
  white-space: nowrap; /* Evita quebra de linha */
}


/* ocultar linhas dos horarios intervalos time */
::v-deep .theme--light.v-calendar-daily .v-calendar-daily__interval::after {
  /* display: none; */
  border: none; 
}


/* Se você quiser também ocultar os horários do lado esquerdo */
::v-deep .v-calendar-daily__head .v-calendar-daily__interval {
  display: none;
  
}

/* Aplicando o estilo de forma profunda usando ::v-deep */
/* ::v-deep .theme--light.v-calendar-daily .v-calendar-daily__day-interval:first-child { */
/* ::v-deep .theme--light.v-calendar-daily .v-calendar-daily__day-interval:nth-child(2n) { */

::v-deep .theme--light.v-calendar-daily .v-calendar-daily__day-interval:not(:first-child){ 
  /* Suas regras de estilo aqui */
  /* background-color: #f5f5f5;  Exemplo de cor de fundo */
  background-color: white;  /* Exemplo de cor de fundo */
  border: none;               /* Removendo a borda */
  /* Adicione outras regras conforme necessário */
} 


::v-deep .v-current-time {
  height: 1px;
  /* background-color: #ea4335; */
  background-color: silver;
  position: absolute;
  left: -10px;
  right: 0;
  pointer-events: none;
  z-index: 0;

  /* &.first::before {
    content: '';
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  } */
}


  /* Utilizando ::v-deep para adaptar o seletor com escopo */
  ::v-deep .theme--light.v-calendar-daily .v-calendar-daily_head-day.v-past .v-calendar-daily_head-day-label {
    color: #b0bec5;
    font-weight: bold;
    opacity: 0.6; 
  }

  

    /* MEU CALENDARIO */


    .text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%; /* Ajusta para o tamanho máximo do v-select */
}
    
    </style>