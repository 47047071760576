<template>
    <v-calendar ref="calendar">
        <template v-slot:day-body="{ date, week }">
            <div v-for="time in timeSlots" :key="time" class="v-current-time"
                :class="{ first: date === week[0].date }"
                :style="{ top: calculateTop(time) }"> 
                {{ time }}
            </div>
        </template>
    </v-calendar>
</template>

<script>
export default {
    data() {
        return {
            timeSlots: ['08:00', '09:50', '11:40'], // Array de horários desejados
            currentDate: '2024-10-10' // Data atual
        };
    },
    computed: {
        cal () {
            return this.ready ? this.$refs.calendar : null;
        },
        nowY () {
            console.log('this.cal.times.now:', this.cal ? this.cal.times.now : null);
            return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px';
        }
    },
    methods: {
        calculateTop(time) {
            const [hours, minutes] = time.split(':').map(Number);
            const totalMinutes = hours * 60 + minutes;
            
            // Ajusta a posição com base na hora atual
            const now = this.cal ? this.cal.times.now : null;
            const nowInMinutes = now ? (now.hours * 60 + now.minutes) : 0;

            // Converte para pixels considerando que cada minuto representa, por exemplo, 2px
            const position = (totalMinutes - nowInMinutes) * 2; // Ajuste conforme necessário
            return `${position}px`; // Retorna a posição em pixels
        }
    }
};
</script>

<style scoped>
.v-current-time {
    position: absolute; /* Para posicionar a linha corretamente */
    width: 100%; /* Ajuste conforme necessário */
    height: 2px; /* Espessura da linha */
    background-color: #ea4335; /* Cor da linha */
}
</style>
