<template>
  <div>


    <br/>
      <template>
        <br>
        <v-card
          color="#26c6da"
          dark
          max-width="385"

          ref="CFCgrafico"
          class="mx-auto pa-2 white"
        >

        <v-img
          :src="CFC"
          @click="saveAsImage('CFCgrafico')"
          class="hand"

          aspect-ratio="2.5"
          gradient="to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.4)"
        >

          <v-card-title class="mb-5 d-flex align-end justify-end" >
            <span class="text-h6 font-weight-bold">Módulo Agendamento Online </span>
            <!-- <v-icon  
              left
              size="33"
              class="ml-1"
              color=""
            >
              mdi-calendar
            </v-icon> -->
            
          </v-card-title>

          <v-card-text class="fs-13 mt-n8 ">

            <center>
                <span class="">
                  Receba via <span class="fs-16 negrito yellow--text"> Pix ou Boleto</span> de seus Alunos 
                  <br/>
                  não precisa gerar arquivo remessa ou retorno!
                  <br/>
                  baixa automática, tudo direto do Sistema
                </span> 
              </center>

          </v-card-text>

          <!-- <v-card-text class="fs-15 mt-n8 d-flex justify-center align-center">
              <center>
                <span class="">
                  Receba via <span class="fs-16 negrito yellow--text"> Pix ou Boleto</span> de seus Alunos 
                  <br/>
                  não precisa gerar arquivo remessa ou retorno!
                  <br/>
                  baixa automática, tudo direto do Sistema
                </span> 
              </center>
            </v-card-text> -->


          <!-- <br/> -->
          <v-card-text class="negrito fs-16 text-start white--text mt-n5 mb-0">
              <!-- Mais agilidade no seu dia a dia -->
              <!-- Atenda mais -->
               <!-- e mais rápido! -->
               <span>
                Envie Lembretes e Cobranças Automáticas
               </span> <br/>

               <!-- <span class="fs-15 yellow--text">
                Teste Grátis
               </span> -->
               <br/>
               
          </v-card-text>
          </v-img>

        </v-card>
      </template>

    <br/>
      <template>
        <br>
        <v-card
          color="#26c6da"
          dark
          max-width="385"

          ref="CFCgrafico"
          class="mx-auto pa-2 white"
        >

        <v-img
          :src="CFCgrafico"
          @click="saveAsImage('CFCgrafico')"
          class="hand"

          aspect-ratio="2.5"
          gradient="to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.4)"
        >

          <v-card-title class="mb-5 d-flex align-end justify-end" >
            <span class="text-h6 font-weight-bold">Módulo WhatsApp </span>
            <v-icon  
              left
              size="33"
              class="ml-1"
              color=""
            >
              mdi-whatsapp
            </v-icon>
            
          </v-card-title>

          <v-card-text class="fs-13 mt-n8 ">
            
            <br/>
            <!-- <span class="yellow--text fs-16  ml-15 pl-15"> Reduza a Inadimplência!  </span> <br/> -->
            <!-- <span class="yellow--text fs-16"> as mesmas perguntas </span> -->
             <!-- <span class="yellow--text fs-16"> Não perca tempo respondendo  </span> <br/> -->
            <!-- <span class="yellow--text fs-16"> as mesmas perguntas </span>  -->

            
             
            <!-- <span class="">
               Automatize seu WhatsApp para responder 
            </span> <br/>
            <span>               
               as perguntas mais frequentes
            </span>  -->

            <br/>
            <br/> <br/>

            <!-- <span class="yellow--white fs-14 blue px-1">
               Crie Menus e Respostas Automáticas
            </span> -->

            <!-- <span>               
               ENVIO <span class="yellow--text">AUTOMÁTICO <br> </span>  DE MENSAGEM NO <span class="yellow--text"> WHATSAPP</span>
            </span>  -->
          </v-card-text>


          <!-- <br/> -->
          <v-card-text class="negrito fs-16 text-start white--text mt-n5 mb-0">
              <!-- Mais agilidade no seu dia a dia -->
              <!-- Atenda mais -->
               <!-- e mais rápido! -->
               <span>
                Envie Lembretes e Cobranças Automáticas
               </span> <br/>

               <!-- <span class="fs-15 yellow--text">
                Teste Grátis
               </span> -->
               <br/>
               
          </v-card-text>
          </v-img>

        </v-card>
      </template>

    <br/>
      <template>
        <br>
        <v-card
          color="#26c6da"
          dark
          max-width="385"

          ref="alfaGraficoNumeros"
          class="mx-auto pa-2 white"
        >

        
        <!-- :src="CFCgrafico" -->
        <v-img
          :src="CFCgraficoNumeros"
          @click="saveAsImage('alfaGraficoNumeros')"
          class="hand"

          aspect-ratio="2.5"
          gradient="to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.4)"
        >

          <v-card-title class="mb-5 d-flex align-end justify-end" >
            <span class="text-h6 font-weight-bold">Módulo WhatsApp </span>
            <v-icon  
              left
              size="33"
              class="ml-1"
              color=""
            >
              mdi-whatsapp
            </v-icon>
            
          </v-card-title>

          <v-card-text class="fs-13 mt-n8 ">
            
            <span class="yellow--text fs-16  ml-15 pl-15"> Reduza a Inadimplência!  </span> <br/>
            <!-- <span class="yellow--text fs-16"> as mesmas perguntas </span> -->
             <!-- <span class="yellow--text fs-16"> Não perca tempo respondendo  </span> <br/> -->
            <!-- <span class="yellow--text fs-16"> as mesmas perguntas </span>  -->

            
             
            <!-- <span class="">
               Automatize seu WhatsApp para responder 
            </span> <br/>
            <span>               
               as perguntas mais frequentes
            </span>  -->

            <br/>
            <br/> <br/>

            <!-- <span class="yellow--white fs-14 blue px-1">
               Crie Menus e Respostas Automáticas
            </span> -->

            <!-- <span>               
               ENVIO <span class="yellow--text">AUTOMÁTICO <br> </span>  DE MENSAGEM NO <span class="yellow--text"> WHATSAPP</span>
            </span>  -->
          </v-card-text>


          <!-- <br/> -->
          <v-card-text class="negrito fs-16 text-start white--text mt-n5 mb-0">
              <!-- Mais agilidade no seu dia a dia -->
              <!-- Atenda mais -->
               <!-- e mais rápido! -->
               <span>
                Envie Lembretes e Cobranças Automáticas
               </span> <br/>

               <span class="fs-15 yellow--text">
                <!-- Teste Grátis -->
                Teste Grátis
               </span>
               
          </v-card-text>
          </v-img>

        </v-card>
      </template>


    <br/>
      <template>
        <br>
        <v-card
          class="mx-auto"
          color="#26c6da"
          dark
          max-width="380"
        >

        <v-img
          :src="A1"
          aspect-ratio="2.5"
          gradient="to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3)"
        >

          <v-card-title class="ml-10 pl-15 mb-5">
            <span class="text-h5 font-weight-bold ">Módulo Boleto Pix</span>
            <v-icon  
              left
              size="44"
              class="ml-2"
            >
            mdi-barcode
            </v-icon>
            
          </v-card-title>

          <br/>
          <v-card-text class="fs-15 mt-n8 d-flex justify-center align-center">
                        
                  
                        <center>
                          <span class="">
                            Receba via <span class="fs-16 negrito yellow--text"> Pix ou Boleto</span> de seus Alunos 
                            <br/>
                            não precisa gerar arquivo remessa ou retorno!
                            <br/>
                            baixa automática, tudo direto do Sistema
                          </span> 
                        </center>
                      </v-card-text>

                      <br/>
                      
                      

          <v-card-text class="text-h6 font-weight-bold text-left white--text mt-n4" v-if="0>1">

            <div class="mt-2">
             <!-- R$ 59,90  <span style="font-size:12px !important"> / mês</span> -->

             <span style="font-size:24px !important" class=""> Teste Grátis</span>
            </div>
            <br/>
            

            </v-card-text>

            <v-card-actions v-if="0>1">


              
            <v-list-item class="grow">
              <v-list-item-avatar color="grey darken-3" v-if="0>1">
                <v-img
                  class="elevation-6"
                  alt=""
                  src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
                ></v-img>
                
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="text-h6 font-weight-bold text-left"> Sistemas DSi </v-list-item-title>
              </v-list-item-content>

              <v-row
                align="center"
                justify="end"
              >
                <v-icon class="mr-1">
                  mdi-heart
                </v-icon>
                <span class="subheading mr-2">256</span>
                <span class="mr-1">·</span>
                <v-icon class="mr-1">
                  mdi-share-variant
                </v-icon>
                <span class="subheading">145</span>
              </v-row>
            </v-list-item>
            </v-card-actions>
          </v-img>
        </v-card>
      </template>


      <br/>
      <template>
        <br>
        
        <!-- <center><h6 ref="measurementsText"></h6></center>  -->
        <v-card
          color="#26c6da"
          dark
          width="380"
          ref="imageSrc"
          class="mx-auto pa-2 white"
        >

          <center v-if="(!salvando)"><h6 ref="measurementsText" class="red--text"></h6></center> 
          <v-img
            :src="imageSrc"  
            @click="saveAsImage('imageSrc')"
            @load="medidas('imageSrc')"
            class="hand"      
                  
            aspect-ratio="2.5"
            gradient="to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.4)"
          >

            <br/>
            <br/>
            <br/>
            <br/>            
            <div class="pa-1">
                <v-card-title class="mb-5 d-flex justify-center align-center" >
                  <span class="text-h6 font-weight-bold "> DSi Web - Gerenciador de CFC </span>
                  
                </v-card-title>

                <v-card-text class="fs-15 mt-n8 d-flex justify-center align-center">
                        
                  
                  <center>
                    <span class="">
                      Receba via <span class="fs-16 negrito yellow--text"> Pix ou Boleto</span> de seus Alunos 
                      <br/>
                      não precisa gerar arquivo remessa ou retorno!
                      <br/>
                      baixa automática, tudo direto do Sistema
                    </span> 
                  </center>
                </v-card-text>


                <!-- <br/> -->
                <v-card-text class="negrito fs-16 text-start white--text mt-n5 mb-0">
                    <span class="yellow--text">
                      <!-- Teste Grátis -->
                      <!-- Saiba Mais -->
                    </span>
                    
                </v-card-text>
            </div>
            <br/>
            <br/>
                      
          </v-img>

        </v-card>

        

      
      </template>

      <br/>
      <template>
        <br>
        

        <v-card
          color="#26c6da"
          dark
          width="380"
          height="380"
          ref="imageSrc2"
          class="mx-auto pa-2 white"
          v-if="0>1"
        >
          
          <center v-if="(!salvando)"><h6 ref="measurementsText" class="red--text"></h6></center>
          <v-img
            :src="imageSrc"  
            @click="saveAsImage('imageSrc2')"
            @load="medidas('imageSrc2')"
            class="hand"

            aspect-ratio="2.5"
            gradient="to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.4)"
            
          >

            <br/>
            <br/>
            <br/>
            <br/>
            
            
            <v-card-title class="mb-5 d-flex justify-center align-center" >
              <span class="text-h6 fs-22 font-weight-bold "> DSi Web - Gerenciador de CFC </span>
              
            </v-card-title>

            <v-card-text class="fs-16 mt-n8 d-flex justify-center align-center">
                    
              
              <center>
                <span class="">
                  Receba via <span class="fs-16 negrito yellow--text"> Pix ou Boleto</span> de seus Alunos 
                  <br/>
                  não precisa gerar arquivo remessa ou retorno!
                  <br/>
                  baixa automática, tudo direto do Sistema
                </span> 
              </center>
              
            </v-card-text>


            <!-- <br/> -->
            <v-card-text class="negrito fs-16 text-start white--text mt-n5 mb-0">
                <span class="yellow--text">
                  <!-- Teste Grátis -->
                  <!-- Saiba Mais -->
                </span>
                
            </v-card-text>
            <br/>
          
            
          </v-img>

          <v-card class="my-2 blue" v-if="2>1">
            <v-card-subtitle>
              <center>
                <span>Mais agilidade no seu dia a dia <br> mais tempo para você</span>
              </center>
            </v-card-subtitle>
          </v-card>
        </v-card>
        

      
      </template>




      <br/>
      <template>
        <br>
        <v-card
          color="#26c6da"
          dark
          max-width="380"
          ref="A1"
          class="mx-auto pa-2 white"
        >

        <center v-if="(!salvando)"><h6 ref="measurementsText" class="red--text"></h6></center>
        <v-img
          :src="A1"
          @click="saveAsImage('A1')"
          @load="medidas('A1')"
          class="hand"

          aspect-ratio="2.5"
          gradient="to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.4)"
        >

          <br/>
          <br/>
          <br/>
          <br/>
         
          
          
          <v-card-title class="mb-5 d-flex justify-center align-center" >
            <span class="text-h6 font-weight-bold "> DSi Web - Gerenciador de CFC </span>
            
          </v-card-title>

          <v-card-text class="fs-13 mt-n8 d-flex justify-center align-center">
                   
             
            <span class="">
               Automatize sua rotina, tenha tudo ao seu alcance
            </span> <br/>
          </v-card-text>


          <!-- <br/> -->
          <v-card-text class="negrito fs-16 text-start white--text mt-n5 mb-0">
               <span class="fs-15 yellow--text">
                <!-- Teste Grátis -->
                Teste Grátis
               </span>
               
          </v-card-text>
          <br/>
          </v-img>

        </v-card>
      </template>

      <br/>
      <template>
        <br>
        <v-card
          color="#26c6da"
          dark
          max-width="385"

          ref="CHATBOT2"
          class="mx-auto pa-2 white"
        >

        <v-img
          :src="CHATBOT2"
          @click="saveAsImage('CHATBOT2')"
          class="hand"

          aspect-ratio="2.5"
          gradient="to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.4)"
        >

          <v-card-title class="mb-5" >
            <span class="text-h6 font-weight-bold ">Secretária Virtual </span>
            <v-icon  
              left
              size="33"
              class="ml-1"
              color=""
            >
              mdi-whatsapp
            </v-icon>
            
          </v-card-title>

          <v-card-text class="fs-13 mt-n8 ">
            
            <span class="yellow--text fs-15 "> Não deixe seu Cliente esperando!  </span> <br/>
            <!-- <span class="yellow--text fs-16"> as mesmas perguntas </span> -->
             <!-- <span class="yellow--text fs-16"> Não perca tempo respondendo  </span> <br/> -->
            <!-- <span class="yellow--text fs-16"> as mesmas perguntas </span>  -->

            
             
            <span class="">
               Automatize seu WhatsApp para responder 
            </span> <br/>
            <span>               
               as perguntas mais frequentes
            </span> 

            <br/> <br/>

            <!-- <span class="yellow--white fs-14 blue px-1">
               Crie Menus e Respostas Automáticas
            </span> -->

            <!-- <span>               
               ENVIO <span class="yellow--text">AUTOMÁTICO <br> </span>  DE MENSAGEM NO <span class="yellow--text"> WHATSAPP</span>
            </span>  -->
          </v-card-text>


          <!-- <br/> -->
          <v-card-text class="negrito fs-16 text-start white--text mt-n5 mb-0">
              <!-- Mais agilidade no seu dia a dia -->
              <!-- Atenda mais -->
               <!-- e mais rápido! -->
               <span>
                Crie Menus e Respostas Automáticas
               </span> <br/>

               <span class="fs-15 yellow--text">
                <!-- Teste Grátis -->
                Teste Grátis
               </span>
               
          </v-card-text>
          </v-img>

        </v-card>
      </template>

      <br/>
      <template>
        <br>
        <v-card
          class="mx-auto"
          color="#26c6da"
          dark
          max-width="380"
        >

        <v-img
          :src="CHATBOT"
          aspect-ratio="2.5"
          gradient="to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.4)"
        >

          <v-card-title class="mb-5" >
            <span class="text-h6 font-weight-bold ">Secretária Virtual </span>
            <v-icon  
              left
              size="33"
              class="ml-1"
              color=""
            >
              mdi-whatsapp
            </v-icon>
            
          </v-card-title>

          <v-card-text class="fs-13 mt-n8 ">
            
            <span class="yellow--text fs-15 "> Não deixe seu Cliente esperando!  </span> <br/>
            <!-- <span class="yellow--text fs-16"> as mesmas perguntas </span> -->
             <!-- <span class="yellow--text fs-16"> Não perca tempo respondendo  </span> <br/> -->
            <!-- <span class="yellow--text fs-16"> as mesmas perguntas </span>  -->

            
             
            <span class="">
               Automatize seu WhatsApp para responder 
            </span> <br/>
            <span>               
               as perguntas mais frequentes
            </span> 

            <br/> <br/>

            <!-- <span class="yellow--white fs-14 blue px-1">
               Crie Menus e Respostas Automáticas
            </span> -->

            <!-- <span>               
               ENVIO <span class="yellow--text">AUTOMÁTICO <br> </span>  DE MENSAGEM NO <span class="yellow--text"> WHATSAPP</span>
            </span>  -->
          </v-card-text>


          <!-- <br/> -->
          <v-card-text class="negrito fs-16 text-start white--text mt-n5 mb-0">
              <!-- Mais agilidade no seu dia a dia -->
              <!-- Atenda mais -->
               <!-- e mais rápido! -->
               <span>
                Crie Menus e Respostas Automáticas
               </span> <br/>

               <span class="fs-15 yellow--text">
                <!-- Teste Grátis -->
                Teste agora mesmo
               </span>
               
          </v-card-text>
          </v-img>

        </v-card>
      </template>

      
      <br/>
      <template>
        <br>
        <v-card
          class="mx-auto"
          color="#26c6da"
          dark
          max-width="380"
        >

        <v-img
          :src="CFC"
          aspect-ratio="2.5"
          gradient="to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.4)"
        >

          <v-card-title class="ml-10 pl-15 mb-5">
            <span class="text-h5 font-weight-bold ">Módulo WhatsApp</span>
            <v-icon  
              left
              size="55"
            >
              mdi-whatsapp
            </v-icon>
            
          </v-card-title>

          <v-card-text class="text-h5 font-weight-bold text-center mt-n4 ">
            <span>               
               MENSAGEM AUTOMÁTICA
            </span> <br/>
            <span class="yellow--text"> DE COBRANÇA E VENCIMENTO </span>
            <!-- <span>               
               ENVIO <span class="yellow--text">AUTOMÁTICO <br> </span>  DE MENSAGEM NO <span class="yellow--text"> WHATSAPP</span>
            </span>  -->
          </v-card-text>


          <v-card-text class="text-h6 font-weight-bold text-center white--text mt-n5 mb-4">
              <!-- Mais agilidade no seu dia a dia -->
              Teste agora mesmo
          </v-card-text>

          <v-card-text class="text-h6 font-weight-bold text-left white--text mt-n4" v-if="0>1">

            <div class="mt-2">
             <!-- R$ 59,90  <span style="font-size:12px !important"> / mês</span> -->

             <span style="font-size:24px !important" class=""> Teste Grátis</span>
            </div>
            <br/>
            

            </v-card-text>

            <v-card-actions v-if="0>1">


              
            <v-list-item class="grow">
              <v-list-item-avatar color="grey darken-3" v-if="0>1">
                <v-img
                  class="elevation-6"
                  alt=""
                  src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
                ></v-img>
                
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="text-h6 font-weight-bold text-left"> Sistemas DSi </v-list-item-title>
              </v-list-item-content>

              <v-row
                align="center"
                justify="end"
              >
                <v-icon class="mr-1">
                  mdi-heart
                </v-icon>
                <span class="subheading mr-2">256</span>
                <span class="mr-1">·</span>
                <v-icon class="mr-1">
                  mdi-share-variant
                </v-icon>
                <span class="subheading">145</span>
              </v-row>
            </v-list-item>
            </v-card-actions>
          </v-img>
        </v-card>
      </template>

      <br/>
      <template>
        <br>
        <v-card
          class="mx-auto"
          color="#26c6da"
          dark
          max-width="400"
        >

        <v-img
          :src="CLINICA"
          aspect-ratio="2.5"
          gradient="to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5)"
        >

          <v-card-title>
            <v-icon
              
              left
              size="100"
            >
              mdi-whatsapp
            </v-icon>
            <span class="text-h5 font-weight-bold ">Módulo WhatsApp</span>
          </v-card-title>

          <v-card-text class="text-h6 font-weight-bold text-left mt-n4">
            <span>
               
               Ganhe <span class="yellow--text">eficiência </span>  na sua Clínica!
            </span> 
            
            <br/>
            <span>Envie mensagens <span class="yellow--text">automáticamente </span>  com apenas 1 clique! </span> 
            <!-- <span>de mensagens para seus  <span class="yellow--text">Pacientes </span>  </span> -->
          </v-card-text>

          <!-- <v-card-text class="text-h6 font-weight-bold text-left mt-n7 py-0">
           <span class="mt-n15">para  <span class="white--text"> Clientes.</span> </span> 
          </v-card-text> -->
<!-- 
          <v-card-text class="text-h6 font-weight-bold text-left mt-n7 py-0">
           <span class="mt-n15">para seus <span class="white--text"> Alunos.</span> </span> 
          </v-card-text> -->

          <!-- <v-card-text class="text-h6 font-weight-bold text-left mt-0 py-0">
           <span class="mt-n15">Dispare <span class="yellow--text"> Avisos e Propagandas</span> </span> 
          </v-card-text> -->


          <!-- <span style="font-size:24px !important" class="ml-12"> Teste Grátis</span> -->

          <!-- SALVAR BMP E DIMINUIR PARA 50% -->

          <!-- <v-card-text class="text-h6 font-weight-bold text-left mt-n6">
          <span> <span class="white--text">Automatize </span> o envio de mensagens
            para seus <span class="white--text"> Alunos</span></span> 
          </v-card-text> -->

          <v-card-text class="text-h6 font-weight-bold text-left grey--text">
              Mais agilidade no seu dia a dia <br/>  Mais tempo para você 
          </v-card-text>

          <v-card-text class="text-h6 font-weight-bold text-left white--text mt-n4" v-if="0>1">

            <div class="mt-2">
             <!-- R$ 59,90  <span style="font-size:12px !important"> / mês</span> -->

             <span style="font-size:24px !important" class=""> Teste Grátis</span>
            </div>
            <br/>
            

            </v-card-text>

            <v-card-actions v-if="0>1">


              
            <v-list-item class="grow">
              <v-list-item-avatar color="grey darken-3" v-if="0>1">
                <v-img
                  class="elevation-6"
                  alt=""
                  src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
                ></v-img>
                
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="text-h6 font-weight-bold text-left"> Sistemas DSi </v-list-item-title>
              </v-list-item-content>

              <v-row
                align="center"
                justify="end"
              >
                <v-icon class="mr-1">
                  mdi-heart
                </v-icon>
                <span class="subheading mr-2">256</span>
                <span class="mr-1">·</span>
                <v-icon class="mr-1">
                  mdi-share-variant
                </v-icon>
                <span class="subheading">145</span>
              </v-row>
            </v-list-item>
            </v-card-actions>
          </v-img>
        </v-card>
      </template>




      <br/>
      <template>
        <br>
        <v-card
          class="mx-auto "
          color="#26c6da"
          dark
          max-width="500"
        >
        

        <v-img
          :src="CFC2"
          aspect-ratio="2.5"
          gradient="to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.8)"
        >
        

          <v-card-title class="text-center">
            <v-icon
              left
              size="99"
            >
              mdi-whatsapp
            </v-icon>
            <span class="text-h4 font-weight-bold ">Módulo WhatsApp</span>
          </v-card-title>

          <br/>

          <v-card-text class="text-h5 font-weight-bold text-center mt-n4">
            <span>Envio </span> <span class="yellow--text">Automático </span> 
            <span>de mensagens </span>
          </v-card-text>

          <v-card-text class="text-h6 font-weight-bold text-center mt-n5 py-0">
           <span class="mt-n15">para seus <span class="white--text"> Clientes</span> </span> 
          </v-card-text>
<!-- 
          <v-card-text class="text-h6 font-weight-bold text-left mt-n7 py-0">
           <span class="mt-n15">para seus <span class="white--text"> Alunos.</span> </span> 
          </v-card-text> -->

          <v-card-text class="text-h5 font-weight-bold text-center mt-0 py-0">
           <span class="mt-n15">Dispare <span class="yellow--text"> Cobranças, Lembretes, Aniversários</span>  e muito mais </span> 
          </v-card-text>


          <v-card-text class="text-h6 font-weight-bold text-left white--text mt-n2 ml-5">

            <!-- <div class="text-decoration-line-through yellow--text" style="font-size:16px !important">
             R$ 149,90
            </div> -->

            <div class="mt-2">
             <!-- R$ 59,90  <span style="font-size:12px !important"> / mês</span> -->
             Teste agora mesmo

             <!-- <span style="font-size:24px !important" class="ml-15 blue--text"> Teste Grátis</span> -->
            </div>
            <br/>
            

            </v-card-text>

          </v-img>
        </v-card>
      </template>

      <br/>
      <template>
        <br>
        <v-card
          class="mx-auto"
          color="#26c6da"
          dark
          max-width="350"
        >
        <!-- color="#26c6da" -->
          <v-card-title>
            <v-icon
              
              left
              size="100"
            >
              mdi-whatsapp
            </v-icon>
            <span class="text-h5 font-weight-bold ">Módulo WhatsApp</span>
          </v-card-title>

          <v-card-text class="text-h6 font-weight-bold text-left">
          <span> <span class="white--text">Automatize </span> o envio de mensagens
            para seus <span class="white--text"> Alunos</span></span> 
          <!-- <span class="text-h5 white--text"> Economize Tempo</span> -->
          </v-card-text>

          <!-- <v-card-text class="text-h6 font-weight-bold text-left">
              Mais agilidade no seu dia a dia <br/>  Mais tempo para você 
          </v-card-text> -->

          <v-card-text class="text-h6 font-weight-bold text-left white--text mt-n8">

            <div class="text-decoration-line-through yellow--text" style="font-size:16px !important">
             R$ 149,90
            </div>

            <div class="mt-n2">
             R$ 89,90  <span style="font-size:10px !important"> / mês</span>

             <v-btn class="ml-15" color="blue" @click="oi()">Teste Grátis</v-btn>
             <!-- <span class="ml-10 primary--text" style="font-size:16px !important"> Teste Grátis</span> -->
            </div>
            

            </v-card-text>

          <v-card-actions>

          </v-card-actions>
        </v-card>
      </template>


      <br/>
      <template>
        <br>
        <v-card
          class="mx-auto"
          color="#26c6da"
          dark
          max-width="360"
        >

        <v-img
          :src="CFC"
          aspect-ratio="2.5"
          gradient="to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.7)"
        >
        
          <v-card-title class="d-flex align-end justify-end">

            <span class="fs-18 font-weight-bold yellow--text">Módulo</span> 
            <span class="fs-18 font-weight-bold ml-3 mt-1 yellow--text">Agendamento Online</span>
            <v-icon
              color="yellow"
              left
              size="33"
              class="ml-1"
            >
              mdi-calendar-clock
            </v-icon>

          </v-card-title>

          <!-- <v-card-text class="text-h6 font-weight- text-left white--text"> -->
          <v-card-text class="fs-18 font-weight text-end white--text">
          <span>
            Aumente sua produtividade!  <br/>
          </span>

          <span class="fs-14 font-weight text-end white--text">
            Sua Agenda Disponível 24 horas, 7 dias por semana
          </span>

          <br/>
          <span>
            <!-- Sua agenda disponível para os pacientes 24h por dia, 7 dias por semana -->
          </span>
          
          </v-card-text>         

          <v-card-actions>


              
            <v-list-item class="grow">
              <v-list-item-avatar color="grey darken-3" v-if="0>1">
                <v-img
                  class="elevation-6"
                  alt=""
                  src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
                ></v-img>
                
              </v-list-item-avatar>

              <v-list-item-content>

                <!-- <span> <span style="font-size:12px !important"> Apenas </span> <br/> R$ 59,90  <span style="font-size:12px !important"> / mês</span></span> -->

                <v-list-item-title class="fs-16 font-weight-bold text-left"> Teste Grátis </v-list-item-title>


              </v-list-item-content>

              <v-row
                align="center"
                justify="end"
              >

              
                <v-icon class="mr-1">
                  mdi-heart
                </v-icon>
                <span class="subheading mr-2">256</span>
                <span class="mr-1">·</span>
                <v-icon class="mr-1">
                  mdi-share-variant
                </v-icon>
                <span class="subheading">145</span>
              </v-row>
            </v-list-item>
          </v-card-actions>
        </v-img>
        </v-card>
      </template>

      <br/>
      <template>
        <br>
        <v-card
          class="mx-auto"
          color="#26c6da"
          dark
          max-width="540"
        >
          <v-card-title>
            <v-icon
              
              left
              size="150"
            >
              mdi-whatsapp
            </v-icon>
            <span class="text-h4 font-weight-bold ">Módulo WhatsApp</span>
          </v-card-title>

          <v-card-text class="text-h6 font-weight-bold text-left">
          <span> <span class="white--text">Automatize </span> o envio de mensagens
            para seus <span class="white--text"> Clientes</span></span> 
          <!-- <span class="text-h5 white--text"> Economize Tempo</span> -->
          </v-card-text>

          <v-card-text class="text-h6 font-weight-bold text-left">
              Mais agilidade no seu dia a dia <br/>  Mais tempo para você 
          </v-card-text>

          <v-card-actions>


              
            <v-list-item class="grow">
              <v-list-item-avatar color="grey darken-3" v-if="0>1">
                <v-img
                  class="elevation-6"
                  alt=""
                  src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
                ></v-img>
                
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="text-h6 font-weight-bold text-left"> Sistemas DSi </v-list-item-title>
              </v-list-item-content>

              <v-row
                align="center"
                justify="end"
              >
                <v-icon class="mr-1">
                  mdi-heart
                </v-icon>
                <span class="subheading mr-2">256</span>
                <span class="mr-1">·</span>
                <v-icon class="mr-1">
                  mdi-share-variant
                </v-icon>
                <span class="subheading">145</span>
              </v-row>
            </v-list-item>
          </v-card-actions>
        </v-card>
      </template>

      <br/>
      <template>
        <br>
        <v-card
          class="mx-auto"
          color="#26c6da"
          dark
          max-width="470"
        >
          <v-card-title>
            <v-icon
              
              left
              size="150"
            >
              mdi-whatsapp
            </v-icon>
            <span class="text-h4 font-weight-bold ">Secretária Virtual</span>
          </v-card-title>

          <v-card-text class="text-h6 font-weight-bold text-left">
          <span> <span class="white--text">Automatize </span> a Confirmação de Consultas <br/>
            com o <span class="white--text"> Módulo  Whatsapp</span></span> 
          <!-- <span class="text-h5 white--text"> Economize Tempo</span> -->
          </v-card-text>

          <v-card-text class="text-h6 font-weight-bold text-left">
              Mais agilidade no seu dia a dia <br/>  Mais tempo para você 
          </v-card-text>

          <v-card-actions>


              
            <v-list-item class="grow">
              <v-list-item-avatar color="grey darken-3" v-if="0>1">
                <v-img
                  class="elevation-6"
                  alt=""
                  src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
                ></v-img>
                
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="text-h6 font-weight-bold text-left"> Sistemas DSi </v-list-item-title>
              </v-list-item-content>

              <v-row
                align="center"
                justify="end"
              >
                <v-icon class="mr-1">
                  mdi-heart
                </v-icon>
                <span class="subheading mr-2">256</span>
                <span class="mr-1">·</span>
                <v-icon class="mr-1">
                  mdi-share-variant
                </v-icon>
                <span class="subheading">145</span>
              </v-row>
            </v-list-item>
          </v-card-actions>
        </v-card>
      </template>
  </div>
</template>

<script>

import html2canvas from 'html2canvas';

export default {

  data: () => ({
    
    salvando: false,

    imageSrc:'',
    A1:'http://localhost/images/alfa3.png',
    CHATBOT:'http://localhost/images/chatbot.png',

    CLINICA:'http://localhost/images/um.png',
    CFC2:'http://localhost/images/fundoCFC2.png',
    CHATBOT2:'http://localhost/images/alfaGrafico.png',
    // CHATBOT2:'http://localhost/images/alfa1.png',
    CFC:'http://localhost/images/alfa2.png',
    FUNDO:'http://localhost/images/fundo.png',
    email: 'contato.dsi@gmail.com',
    cor_fundo: '#273746',

    emailRules: [
         // v => !!v || 'necessário',
         v => /.+@.+\..+/.test(v) || 'E-mail inválido!',
    ],

  }),


  created(){
     this.carrega_imagens()
  },

  methods:{





    medidas(refName) {
      this.$nextTick(() => {
        const captureDiv = this.$refs[refName].$el;
        // console.log('captureDiv:', captureDiv);

        if (captureDiv) {
          const width = captureDiv.offsetWidth;
          const height = captureDiv.offsetHeight;
          this.cardWidth = width;
          this.cardHeight = height;
          
          
          const h6Element = captureDiv.querySelector('h6'); // Captura o elemento h6 dentro do v-card
          //   console.log('h6Element:', h6Element);
          if (h6Element) {
            h6Element.textContent = `Largura: ${width}px, Altura: ${height}px`;
          }
        }
      });
    },


    carrega_imagens(){
       var url =  sessionStorage['url']
       this.imageSrc = url+"images/arte/A2.png" 
      //  this.imageSrc = url+"images/arte/alfa3.png" 

        this.A1         = url+"images/arte/alfa3.png",
        this.CHATBOT    = url+"images/arte/chatbot.png"

        this.CLINICA    = url+"images/arte/um.png"
        this.CFC2       = url+"images/arte/fundoCFC2.png"
        this.CHATBOT2   = url+"images/arte/alfa1.png"
        this.CFC        = url+"images/arte/alfa2.png"
        this.CFCgrafico  = url+"images/arte/alfaGrafico.png"
        this.CFCgraficoNumeros  = url+"images/arte/alfaGraficoNumeros.png"
        this.FUNDO      = url+"images/arte/fundo.png'"
    },



    saveAsImage(refName) {

      this.salvando = true//oculta as medidas

      setTimeout(function(){
        
        const captureDiv = this.$refs[refName].$el;

        html2canvas(captureDiv).then(canvas => {
          const link = document.createElement('a');
          link.href = canvas.toDataURL('image/png');
          link.download = `${refName}.png`;
          link.click();
        });

      }.bind(this), 111);
      
    },
    

    oi(){
      alert('oi')
    },
  }

}
</script>

<style scoped>

  .negrito {
      font-weight: bolder !important;
      /* color: red !important; */
      /* Outros estilos desejados */
  }
  .fs-8{
    font-size: 8px !important;
  }
  .fs-10{
    font-size: 10px !important;
  }
  .fs-11{
    font-size: 11px !important;
  }
  .fs-12{
    font-size: 12px !important;
  }
  .fs-13{
    font-size: 13px !important;
  }
  .fs-14{
    font-size: 14px !important;
  }
  .fs-15{
    font-size: 15px !important;
  }
  .fs-16{
    font-size: 16px !important;
  }
  .fs-18{
    font-size: 18px !important;
  }
  .fs-22{
    font-size: 22px !important;
  }

  .hand:hover {
   cursor: pointer; /* Muda o cursor para a mão ao passar o mouse sobre o container */
  }

</style>