import { render, staticRenderFns } from "./Agenda3.vue?vue&type=template&id=608f7603&scoped=true&"
import script from "./Agenda3.vue?vue&type=script&lang=js&"
export * from "./Agenda3.vue?vue&type=script&lang=js&"
import style0 from "./Agenda3.vue?vue&type=style&index=0&id=608f7603&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "608f7603",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCalendar } from 'vuetify/lib/components/VCalendar';
installComponents(component, {VCalendar})
