<template>
  <div>


    <!-- config_whats: {{config_whats}} <br/><br/> -->
    <!-- obj_cliente_selecionado:{{ obj_cliente_selecionado }}<br/><br/> -->

    <!-- selected: {{selected}} <br/><br/> -->
    <!-- selected_2: {{selected_2}} <br/><br/> -->
    <!-- meu_sub_menu: {{meu_sub_menu}} <br/><br/> -->

    <!-- session_bd: {{ session_bd }} <br/> -->
    <!-- {{ qual_importar }} -->
    <!-- dados_importados:{{ dados_importados }}<br/><br/> -->
    <!-- items_lista_importados:{{ items_lista_importados }}<br/><br/> -->
    <!-- {{ items_menus }} -->
    <!-- tabela_selecionada:{{tabela_selecionada}} <br/> -->
    <!-- tabela_selecionada_2:{{tabela_selecionada_2}} <br/> -->
    <!-- {{items_lista}} -->
    <!-- {{zapzap_mostrar}} -->
    <!-- {{zapzap_conectado}} -->

    <!-- item_edita:{{ item_edita }} <br/> -->
    <!-- item_edita_2:{{ item_edita_2 }} <br/> -->
    <!-- obj_dados_empresa:{{ obj_dados_empresa }} <br/><br/> -->
    <!-- {{ superA }} -->
    <!-- {{item_edita.URL_FILE}} -->
    <!-- {{ value_expansion }} -->
    <!-- {{ buscar_nomes_model }} -->
    <!-- {{ items_header_2 }} -->


    
    <!-- <br/> -->
    <!-- <br/> -->


    <!-- {{ item_sub_edita }} -->

    <!-- {{ items_editaveis_2 }} -->
    <!-- obj_clicado:{{ obj_clicado }} <br/><br/> -->
    <!-- obj_clicado_2:{{ obj_clicado_2 }} <br/><br/> -->
    <!-- menu_selecionou: {{ menu_selecionou }}<br/><br/> -->
    <!-- items_campanha:{{ items_campanha }} <br/> -->
    <!-- items_editaveis:{{ items_editaveis }} <br/><br/> -->
    <!-- items_editaveis_2:{{ items_editaveis_2 }} <br/><br/> -->
    <!-- {{ zapzap_conectado }} -->


    <input
      type="file"
      ref="fileInput"
      accept=".txt"
      @input="handleFileUpload"
      style="display: none;"
    />

    <v-overlay :value="overlay">
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>

    <v-container>
        <!-- <v-row>
          <v-col>
            <v-chip> {{ obj_cliente_selecionado.NOME }}</v-chip>
          </v-col>
        </v-row> -->

        <v-row dense class="orange lighten-5">
          <!-- campanhas -->
          <v-col cols="12" md="3" class="grey">
              <v-card class="mx-auto"> 

                <v-card-title class="pb-0">
                    <v-icon color=""  class="mr-1">mdi-flag-variant </v-icon>
                    Menus ({{ items_lista.length }})
                    <v-spacer/>

                    <span v-if="(0>1)">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn x-small fab color="grey" dark class="ma-2" 
                            v-bind="attrs" v-on="on"
                            @click="triggerFileInput"
                          >
                          
                            <!-- <v-icon>mdi-import</v-icon> -->
                            <!-- <v-icon>mdi-arrow-down-bold-box</v-icon> -->
                            <v-icon>mdi-arrow-down-box</v-icon>
                            
                          </v-btn>
                        </template>
                      <span> Importar Menus </span>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn x-small fab color="grey" dark class="ma-2" 
                            @click="exportArray()"
                            v-bind="attrs" v-on="on"
                          >
                            <v-icon>mdi-export-variant</v-icon>
                          </v-btn>
                        </template>
                      <span> Exportar Menus </span>
                      </v-tooltip>
                    </span>
                    
                    <v-btn x-small fab color="primary" class="ma-2" @click="nova_tabela('item_edita','dialog_tabela')">
                      <v-icon>mdi-plus</v-icon>
                      <!-- <v-icon>mdi-flag-variant-plus </v-icon> -->
                    </v-btn>

                </v-card-title>

                


                <v-container class="elevation-4 mb-1 " >
                      <v-row dense class="" >

                          <v-col class="" cols="1">

                            <!-- <v-container class="px-0" fluid> -->
    
                              <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
      
                                  <v-icon
                                  class="pb-1"
                                  color="primary"
                                  size="28"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  mdi-menu
                                </v-icon>

                                </template>
                                <v-list>
                                  <v-list-item
                                    v-for="(item, index) in menu_nomes"
                                    :key="index"
                                    dense
                                    @click="clicou_menu_nomes(item.acao)"
                                    v-if="(retorna_titulo(item.title, item.acao))"
                                  >
                                    <v-list-item-title>
                                      {{ retorna_titulo(item.title, item.acao) }}
                                      <!-- <hr> -->
                                    </v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>

                          </v-col>

                          <v-col cols="auto">
                            <v-chip small class="ml-3" color="primary" @click="open_campanhas()"> {{ obj_cliente_selecionado.NOME }}</v-chip>
                          </v-col>

                      </v-row>
                  </v-container>

                <v-data-table
                    v-model="selected"
                    item-key="CODIGO"
                    @click:row="seleciona_campanha"

                    :headers="items_header"
                    :items="items_lista"
                    class="elevation-1 pl-1"
                    :items-per-page="-1"
                    
                    :mobile-breakpoint="55"
                    hide-default-footer
                    dense
                    :height="(isMobile)? '300' : retorna_altura(120)"
                    >

                    <template v-slot:item.actions="{ item }">
                        <v-icon
                            class="mr-2"
                            @click="editar_tabela(item, 'item_edita', 'dialog_tabela'), table_codigo = item.CODIGO"
                            color="blue"
                            size="18"
                        >
                        mdi-pencil
                        </v-icon>
                    </template>
                    

                    

                    <template v-slot:item.x_DATA="{ item }">
                        <v-chip small>
                           <span class="fs-9 mr-1">{{ item.CODIGO }}</span> 
                           <span class="fs-12">{{ item.x_DATA }}</span>
                        </v-chip>
                    </template>


                    <template v-slot:item.URL_FILE="{ item }">
                      <a> <v-img
                          alt="user"
                          contain
                          :src="(item.URL_FILE)? item.URL_FILE : ''"
                          width="50px"
                        >
                      </v-img></a>
                    </template>

                    <template v-slot:item.CODIGO="{ item }">
                      <span class=""> {{ item.CODIGO }} </span>
                    </template>

                    <template v-slot:item.DESCRICAO="{ item }">
                      <v-chip v-if="(item.INICIAL == 'S')" small 
                         :color="(item.ATIVO=='S') ? 'success' : 'error'">
                        {{ item.DESCRICAO }} 
                      </v-chip>
                      
                      <v-chip v-else-if="(menu_destacar == item.DESCRICAO)" small color="primary">
                        {{ item.DESCRICAO }} 
                      </v-chip>
                      
                      <v-chip v-else small color="">
                        {{ item.DESCRICAO }} 
                      </v-chip>

                      <!-- <span v-else>
                        {{ item.DESCRICAO }} 
                      </span> -->
                      <!-- :class="[(menu_destacar == item.DESCRICAO) ? 'negrito' : '']">  -->
                    </template>

                    <template v-slot:item.TEXTO="{ item }">
                        <!-- <v-chip
                         color="info"
                         small
                         active-class="white--text"
                        >
                        <a class="white--text">{{ item.TEXTO }}</a>
                        </v-chip> -->
                        <span v-if="(item.INICIAL=='S')" class="blue--text">
                          {{ item.TEXTO }}
                        </span>
                        <span v-else>
                          {{ item.TEXTO }}
                        </span>
                    </template>



                </v-data-table>
              </v-card>
          </v-col>  
          

          <!-- nomes -->
          <v-col cols="12" md="4" class="grey" v-if="obj_clicado.CODIGO">

              <v-card class="mx-auto "> 

                  <v-card-title class="mb-0  pb-0">
                      <v-icon color=""  class="mr-1">mdi-account </v-icon>

                      Respostas ({{ items_lista_2.length }}) 
                      
                      <v-spacer/>

                      <v-btn class="ma-2" x-small fab color="primary" @click="nova_tabela_2('item_edita_2','dialog_tabela_2')">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>

                  </v-card-title>


                  <v-container class="elevation-4 mb-1" >
                      <v-row dense class="" >

                          <v-col class="pt-1" md="8">

                            <!-- <v-container class="px-0" fluid> -->
    
                              <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
      
                                  <v-icon
                                  class="pb-1"
                                  color="primary"
                                  size="28"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  mdi-menu
                                </v-icon>

                                </template>
                                <v-list>
                                  <v-list-item
                                    v-for="(item, index) in menu_respostas"
                                    :key="index"
                                    dense
                                    @click="clicou_menu_nomes(item.acao)"
                                    v-if="(retorna_titulo(item.title, item.acao))"
                                  >
                                    <v-list-item-title>
                                      {{ retorna_titulo(item.title, item.acao) }}
                                      <!-- <hr> -->
                                    </v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>

                          </v-col>

                      </v-row>
                  </v-container>


                  <v-data-table
                      v-model="selected_2"
                      item-key="CODIGO"
                      @click:row="seleciona_item_2"

                      :headers="items_header_2"
                      :items="items_lista_2"
                      class="elevation-1 pl-1"
                      :items-per-page="-1"
                      
                      :mobile-breakpoint="55"
                      hide-default-footer
                      dense
                      
                      :height="(isMobile)? '300' : retorna_altura(120)"

                      
                      >
                      <!-- @contextmenu:row="rightClick" -->



                  <!-- Template para renderizar dinamicamente os dados das colunas -->
                      <!-- <template v-slot:item="{ item }">
                        <tr>
                          <td v-for="header in items_header_2" :key="header.value">
                            {{ item[header.value] }}
                          </td>
                        </tr>
                      </template> -->


                      <template v-slot:item.URL_FILE="{ item }">
                      <a> <v-img
                          alt="user"
                          contain
                          :src="(item.URL_FILE)? item.URL_FILE : ''"
                          width="50px"
                        >
                      </v-img></a>
                    </template>

                      <template v-slot:item.NOME="{ item }">
                        <v-chip small 
                        :color="(item.ID_WHATS) ? 'success' : ''">
                        <span>
                          {{ item.NOME }}
                        </span>
                        </v-chip>

                      </template>



                      <template v-slot:item.TIPO="{ item }">

                          <v-chip small color="primary" v-if="item.TIPO=='MENU'" @click="seleciona_item_2(item),seleciona_campanha(meu_sub_menu)">
                          <span>
                            {{ item.TIPO }}
                          </span>
                          </v-chip>

                          <span v-else> {{ item.TIPO }} </span>

                      </template>



                      <template v-slot:item.NUMERO="{ item }">

                        <v-icon
                          v-if="(item.ID_WHATS)"
                          class="mx-1"
                          color="green"
                          size="20"
                        >
                          mdi-whatsapp
                        </v-icon>

                        <v-chip small 
                        :color="(item.ID_WHATS) ? 'success' : ''">
                        <span>
                          {{ item.NUMERO }}
                        </span>
                        </v-chip>
                      </template>

                      <template v-slot:item.actions_delete="{ item }">
                          <v-icon
                              class="mr-2"
                              @click="remover_esse(item)"
                              color="red"
                              size="18"
                          >
                          mdi-delete
                          </v-icon>
                      </template>


                      <template v-slot:item.actions="{ item }">
                          <v-icon
                              class="mr-2"
                              @click="editar_tabela(item, 'item_edita', 'dialog_tabela'), table_codigo = item.CODIGO"
                              color="blue"
                              size="18"
                          >
                          mdi-pencil
                          </v-icon>
                      </template>

                      <template v-slot:item.actions_2="{ item }">
                          <v-icon
                              class="mr-2"
                              @click="editar_tabela(item, 'item_edita_2', 'dialog_tabela_2'), table_codigo = item.CODIGO"
                              color="blue"
                              size="18"
                          >
                          mdi-pencil
                          </v-icon>
                      </template>

                      

                      <template v-slot:item.x_DATA="{ item }">
                          <v-chip small>
                          <span class="fs-9 mr-1">{{ item.CODIGO }}</span> 
                          <span class="fs-12">{{ item.x_DATA }}</span>
                          </v-chip>
                      </template>

                      <template v-slot:item.TITULO="{ item }">
                          <v-chip
                          color="success"
                          small
                          active-class="white--text"
                          >
                          <a class="white--text">{{ item.TITULO }}</a>
                          </v-chip>
                      </template>



                  </v-data-table>
              </v-card>
            
          </v-col> 

          <!-- texto -->
          <v-col cols="12" md="4" class="grey pr-2" v-if="obj_clicado.CODIGO">
            
            <!-- v-if="items_editaveis_2.length>0" -->


          <!-- PAINEL ESQUERDA -->
            <v-card 
            flat
            elevation="5"
            class="mt-0 white lighten-3 mb-4 overflow-y-auto"
            scrollable
            :height="retorna_altura(150)"
            >



            <v-card-title class="mb-6">
              Modelo
          </v-card-title>


          <v-card-text class="green lighten-4 pa-5 ma-2">
            <p v-html="obj_clicado.TEXTO" class="black--text"></p>

            <p v-html="modelo_mensgem" class="black--text"></p>
          </v-card-text>

          <v-card-text class="green lighten-4 pa-5 ma-2">

            <p v-html="modelo_sub_mensgem" class="black--text"></p>
            <!-- <p v-html="obj_clicado_2.TEXTO" class="black--text"></p> -->
          </v-card-text>

              <v-expansion-panels 
                :multiple="true"
                :focusable='false'
                v-model="value_expansion"
                v-if="0>1"
                >


                

                <!-- MENSAGEM -->
                <v-expansion-panel>
                  <v-expansion-panel-header 
                  class="pa-2 px-4"
                  style="min-height: 28px"
                  
                  >
                    <div>
                      <v-row
                      >
                        <v-col
                          cols="12"
                          md="12"
                        >
                            <v-icon
                             size="20"
                            >
                            mdi-text-box-multiple-outline  
                            </v-icon> 
                            <span class="blue--text text-h6"> Mensagem</span>
                        </v-col>
                      </v-row>
                    </div>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content class="py-0 ma-0">

                  <v-row class="">
                     <v-col cols="12" class="pa-0 mt-1">
                        <v-card class="ma-2 pa-4" elevation="4" v-if="(obj_clicado_2.CODIGO)">
                          <v-textarea
                              v-model="obj_clicado_2.TEXTO"
                              label="Mensagem"
                              dense
                              filled
                              background-color="#FAFAFA"
                              hide-details
                              :height="(isMobile) ? '500' : retorna_altura(430)"
                              readonly
                          >
                          </v-textarea>



                          <v-text-field
                              v-model="obj_clicado_2.URL_FILE"
                              v-if="(!superA)"
                              label="PATH IMAGEM"
                              dense
                          ></v-text-field>
                          
                          <v-img
                            v-if="obj_clicado_2.URL_FILE"
                            :src="obj_clicado_2.URL_FILE"
                            max-width="500"
                            max-height="300"
                            class="mt-2"
                          ></v-img>

                        </v-card>
                     </v-col>
                  </v-row>


                  <v-row class="">
                     <v-col cols="6" class="pa-0 mt-1">
                      
                          <v-file-input
                            v-model="selectedFile"
                            show-size
                            show-overflow
                            label="Imagem ou PDF"
                            @change="upload_logo(selectedFile, session_bd+'/whats_lab', 'anexo_'+menu_selecionou.CODIGO, 'URL_FILE')"
                          >
                        </v-file-input>
                     </v-col>
                     
                     <v-col cols="6" class="pa-0 mt-6 pr-2 text-right" v-if="item_edita.URL_FILE">
                        
                        <v-btn x-small color="error"
                          @click="item_edita.URL_FILE='', selectedFile=null"
                        >
                          Remover Anexo
                        </v-btn>
                     </v-col>
                  </v-row>

                  </v-expansion-panel-content>
                </v-expansion-panel>


              </v-expansion-panels>
            </v-card>




              <v-card class="mx-auto ma-1"> 
                <v-row dense class="grey lighten-4">

                

                  <v-col cols="12" class="">
                    <v-card class="ma-2 pa-4" elevation="4">
                       <v-card-actions>
                        
                        <v-btn color="primary" @click="salvar_msg" :disabled="(!item_edita.TEXTO)" small>
                            Salvar mensagem
                            <!-- <v-icon small class="ml-2"> mdi-send  </v-icon> -->
                        </v-btn>

                        <v-spacer></v-spacer>


                        <v-btn
                         :color="(zapzap_conectado) ? 'success' : 'orange'" 
                         @click="disparar_whats()" :disabled="(!item_edita.TEXTO)||(!items_editaveis_2.length>0)" v-if="(zapzap_mostrar)">
                            Disparar
                            <v-icon small class="ml-2"> mdi-whatsapp  </v-icon>
                        </v-btn>

                        <v-btn color="grey" dark @click="alerta_zap"  v-if="(!zapzap_mostrar)">
                            Disparar
                            <v-icon small class="ml-2"> mdi-whatsapp  </v-icon>
                        </v-btn>
                        


                          

                            <!-- <v-container class="px-0" fluid> -->
    
                              <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
      
                                  <v-icon
                                  class="pb-1 ml-5"
                                  color="primary"
                                  size="32"
                                  v-bind="attrs"
                                  v-on="on"
                                  :disabled="(!item_edita.TEXTO)||(!items_editaveis_2.length>0)"
                                >
                                  mdi-menu
                                </v-icon>

                                </template>
                                <v-list>
                                  <v-list-item
                                    v-for="(item, index) in menu_45"
                                    :key="index"
                                    dense
                                    @click="clicou_menu_nomes(item.acao)"
                                    v-if="(retorna_titulo(item.title, item.acao))"
                                  >
                                    <v-list-item-title>
                                      {{ retorna_titulo(item.title, item.acao) }}
                                      <!-- <hr> -->
                                    </v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>

                          

                       </v-card-actions> 
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
          </v-col>  

        </v-row>   


        <v-dialog
          v-model="dialog_tabela"
          persistent
          max-width="990"
          scrollable
      >
  
          <v-card class="" >
          <!-- height="80%" -->
          <v-card-title>
              <span class="text-h5" 
                v-text="(item_edita.CODIGO>0) ? 'Alterar '+ card_titulo +' '+item_edita.CODIGO : 'Novo '+ card_titulo "
              >
              Alterar
             </span>
          </v-card-title>
  
  

              <!-- passo 4 monto os campos editaveis -->
              <!-- :readonly = "readonly" -->

                  <v-card-text class="pa-1">
                    <v-form
                      ref="form"
                      v-model="valido"
                      >
                      <v-container>
                          <v-row>
                              <v-col
                                  class=""
                                  v-for="(item, i) in items_editaveis"
                                  :key="i"
                                  :cols="(isMobile) ? 12 : item.cols"
                                  v-if="parseInt(item.cols)>0"   
                              >
                              <!-- :cols="item.cols" -->




                                <v-card  class="" 
                                  v-if="(item.tag == 'v-card')&&(item.value == 'PARAM_WHATS')"
                                >
                                  <v-card-text>

                                    <h3 class="blue--text"> {{ item.text }} </h3>
                                    
                                    <v-chip-group
                                      column
                                    >
                                      <v-chip
                                          v-for="(item, i) in tags_empresa"
                                          :key="i"
                                          small
                                          @click="insere_tag(item)"
                                      >
                                        {{ item }}

                                        <span v-if="(item=='@;)')"> 😉 </span>
                                        <span v-if="(item=='@:)')"> 🙂 </span>
                                        <span v-if="(item=='@[]')"> 👩🏼‍💻 </span>
                                        
                                      </v-chip>
                                    </v-chip-group>
                                  </v-card-text>
                                  </v-card>


                                  <!-- v-model="item_edita[item.value]" -->
                                  
                                      

                                    <div v-if="item.tag == 'v-file-input'">
                                        <v-file-input
                                          v-model="selectedFile"
                                          label="Imagem / PDF / OGG"
                                          accept="image/*,.pdf,.ogg"
                                          show-size
                                          show-overflow
                                          @change="upload_logo(selectedFile, session_bd+'/whats_bot', 'menu_'+obj_clicado.ID, 'URL_FILE', 'item_edita')"
                                        ></v-file-input>



                                        <v-img 
                                          v-if="item_edita[item.value]" 
                                          :src="item_edita[item.value]" 
                                          alt="Imagem carregada" 
                                          max-width="500"
                                          max-height="300"
                                        ></v-img>
                                    </div>
                                      

                                  <v-text-field
                                      dense
                                      v-model="item_edita[item.value]"
                                      :label="item.text"
                                      :type="item.type"
                                      v-if="item.tag == 'v-text'"
                                      @keyup="meu_keyup($event, item.value, item.upper)"
                                      
                                      :rules="item.rule == 'S' ? notnullRules: noRules"
                                      :autofocus="i==2"
                                      v-mask = "getVmask(item.vmask)"
                                      class=""
                                      :clearable = "item.tag_clear == 'S'"
                                  ></v-text-field>
                                  <!-- @keydown="firstUpper($event, item.value)" -->
                                  <!-- @input="(item.upper == 'S') ? item_edita[item.value] = item_edita[item.value].toUpperCase() : ''" -->
                                  
                                  <v-combobox
                                      dense
                                      v-model="item_edita[item.value]"
                                      :label="item.text"
                                      v-if="item.tag == 'v-autocomplete'"
                                      :items="item.tag_items"
                                      :rules="item.rule == 'S' ? notnullRules: noRules"
                                  ></v-combobox>
                                  
                                  <v-select
                                      :class="[item.class]"
                                      dense
                                      v-model="item_edita[item.value]"
                                      :label="item.text"
                                      v-if="item.tag == 'v-select'"
                                      :items="item.tag_items"
                                      :rules="item.rule == 'S' ? notnullRules: noRules"

                                      item-value='CODIGO'
                                      item-text='NOME'
                                      :clearable = "item.tag_clear == 'S'"
                                      :persistent-hint="item.cols>2"
                                      :hint="item.hint"
                                  >
                                  
                                  <!-- <template slot="selection" v-slot-scope="item"> -->
                                    
                                  <!-- <template slot="selection" v-slot-scope="item"> -->
                                    <template v-slot:label>
                                      <v-tooltip bottom v-if="item.hint">
                                          <template v-slot:activator="{ on, attrs }">
                                            <span
                                                v-bind="attrs"
                                                v-on="on"
                                            > 
                                              <span :class="item.class">
                                                {{ item.text }} 
                                                <!-- {{ item_edita[item.value] }} -->
                                              </span>
                                            </span>

                                          </template>
                                          <span> {{ item.hint }} </span>
                                        </v-tooltip>
                                    </template>
       
                                </v-select>


                              <!-- CAMPO ANOTAÇÕES OU OBSERVAÇÃO -->
                              <!-- <component :is="(vai_imprimir) ? 'v-text' : 'v-textarea' "   -->
                              <v-textarea
                                v-model="item_edita[item.value]"
                                :label="item.text"
                                v-if="item.tag == 'v-textarea'"
                                :hint="item.hint"
                                :persistent-hint="item.cols>2"
                                dense
                                :rows="item.rows"
                                outlined

                                :autofocus="i==2"
                                :rules="item.rule == 'S' ? notnullRules: noRules"
                                @click="clicou_em=item.value"
                                id="textara_to_print"
                                ref="textara_to_print"
                              >
                              <!-- vai_imprimir -->
                              </v-textarea>                                  
                                  
                              </v-col>
                          </v-row>
                      </v-container>
                    </v-form>
                  </v-card-text>
  
          <v-card-actions>
              <v-btn
                  x-small
                  color="red"
                  text
                  @click="remover_tabela(tabela_selecionada, item_edita, card_titulo), dialog_tabela = false"
              >
                Excluir
              </v-btn>
  
              <v-spacer></v-spacer>


              <v-btn
                  color="blue"
                  text
                  @click="imprimir_pagina()"
                  class="mr-5"
                  x-small
                  v-if="0>1"
              >
                Imprimir
              </v-btn>
  
              <v-btn
                  color="red"
                  text
                  @click="dialog_tabela = false"
              >
                Cancelar
              </v-btn>
              
              &nbsp
  
              <v-btn
                  :disabled="!valido"
                  color="primary"
                  @click="salvar_tabela(tabela_selecionada, item_edita, 'dialog_tabela')"
              >
                Salvar
              </v-btn>
          </v-card-actions>
          </v-card>
      </v-dialog>



        <!-- PASSO 4 DA 2 ETAPA -->
        <v-dialog
          v-model="dialog_tabela_2"
          persistent
          max-width="990"
          scrollable
      >
  
          <v-card class="" >
          <!-- height="80%" -->
          <v-card-title>
              <span class="text-h5" 
                v-text="(item_edita_2.CODIGO>0) ? 'Alterar '+ card_titulo +' '+item_edita_2.CODIGO : 'Novo '+ card_titulo "
              >
              Alterar
             </span>
          </v-card-title>
  
  

              <!-- passo 4.2 monto os campos editaveis -->
              <!-- :readonly = "readonly" -->

                  <v-card-text class="pa-1">
                    <v-form
                      ref="form"
                      v-model="valido"
                      >
                      <v-container>
                          <v-row>
                              <v-col
                                  class=""
                                  v-for="(item, i) in items_editaveis_2"
                                  :key="i"
                                  :cols="(isMobile) ? 12 : item.cols"
                                  v-if="parseInt(item.cols)>0"   
                              >
                              <!-- :cols="item.cols" -->




                                <v-card  class="" 
                                  v-if="(item.tag == 'v-card')&&(item.value == 'PARAM_WHATS')"
                                >
                                  <v-card-text>

                                    <h3 class="blue--text"> {{ item.text }} </h3>
                                    
                                    <v-chip-group
                                      column
                                    >
                                      <v-chip
                                          v-for="(item, i) in tags_empresa"
                                          :key="i"
                                          small
                                          @click="insere_tag(item)"
                                      >
                                        {{ item }}

                                        <span v-if="(item=='@;)')"> 😉 </span>
                                        <span v-if="(item=='@:)')"> 🙂 </span>
                                        <span v-if="(item=='@[]')"> 👩🏼‍💻 </span>
                                        
                                      </v-chip>
                                    </v-chip-group>
                                  </v-card-text>
                                  </v-card>



                                  <div v-if="item.tag == 'v-file-input'">
                                        <v-file-input
                                          v-model="selectedFile"
                                          label="Imagem / PDF / OGG"
                                          accept="image/*,.pdf,.ogg"
                                          show-size
                                          show-overflow
                                          @change="upload_logo(selectedFile, session_bd+'/whats_bot', 'resposta_'+obj_clicado_2.CODIGO, 'URL_FILE', 'item_edita_2')"
                                        ></v-file-input>



                                        <v-img 
                                          v-if="item_edita_2[item.value]" 
                                          :src="item_edita_2[item.value]" 
                                          alt="Imagem carregada" 
                                          max-width="500"
                                          max-height="300"
                                        ></v-img>
                                  </div>


                                  <v-text-field
                                      dense
                                      v-model="item_edita_2[item.value]"
                                      :label="item.text"
                                      :type="item.type"
                                      v-if="item.tag == 'v-text'"
                                      @keyup="meu_keyup($event, item.value, item.upper)"
                                      
                                      :rules="item.rule == 'S' ? notnullRules: noRules"
                                      :autofocus="item.focus"
                                      v-mask = "getVmask(item.vmask)"
                                      class=""
                                      :clearable = "item.tag_clear == 'S'"
                                  ></v-text-field>
                                  <!-- @keydown="firstUpper($event, item.value)" -->
                                  <!-- @input="(item.upper == 'S') ? item_edita[item.value] = item_edita[item.value].toUpperCase() : ''" -->
                                  
                                  <v-combobox
                                      dense
                                      v-model="item_edita_2[item.value]"
                                      :label="item.text"
                                      v-if="item.tag == 'v-autocomplete'"
                                      :items="item.tag_items"
                                      :rules="item.rule == 'S' ? notnullRules: noRules"
                                  ></v-combobox>


                                  <span v-if="item.tag == 'v-select-item'">
                                    <v-select
                                        :items="item.tag_items"
                                        v-if="item_edita_2[item.if] == item.if_value"
                                        :label="item.text"
                                        item-value="ID"
                                        item-text="TEXTO"
                                        :return-object="false"
                                        v-model="item_edita_2[item.value]"
                                        hide-details="true"
                                      >
                                    
                                        <template slot='item' slot-scope='{ item }'>
                                          <div class="caption">
                                            {{ item.CODIGO }} &nbsp | &nbsp  {{ item.DESCRICAO }} &nbsp | &nbsp  {{ item.TEXTO }} 
                                          </div>                      
                                        </template>
                                        
                                        <template slot='selection' slot-scope='{ item }'>
                                          <div class="caption">
                                            {{ item.CODIGO }} &nbsp | &nbsp  {{ item.DESCRICAO }} &nbsp | &nbsp  {{ item.TEXTO }} 
                                          </div>                      
                                        </template>
                                    
                                    </v-select>
                                  </span>
                                  
                                  <v-select
                                      :class="[item.class]"
                                      dense
                                      v-model="item_edita_2[item.value]"
                                      :label="item.text"
                                      v-if="item.tag == 'v-select'"
                                      :items="item.tag_items"
                                      :rules="item.rule == 'S' ? notnullRules: noRules"

                                      item-value='CODIGO'
                                      item-text='NOME'
                                      :clearable = "item.tag_clear == 'S'"
                                      :persistent-hint="item.cols>2"
                                      :hint="item.hint"
                                  >
                                  
                                  <!-- <template slot="selection" v-slot-scope="item"> -->
                                    
                                  <!-- <template slot="selection" v-slot-scope="item"> -->
                                    <template v-slot:label>
                                      <v-tooltip bottom v-if="item.hint">
                                          <template v-slot:activator="{ on, attrs }">
                                            <span
                                                v-bind="attrs"
                                                v-on="on"
                                            > 
                                              <span :class="item.class">
                                                {{ item.text }} 
                                                <!-- {{ item_edita[item.value] }} -->
                                              </span>
                                            </span>

                                          </template>
                                          <span> {{ item.hint }} </span>
                                        </v-tooltip>
                                    </template>
       
                                </v-select>


                              <!-- CAMPO ANOTAÇÕES OU OBSERVAÇÃO -->
                              <!-- <component :is="(vai_imprimir) ? 'v-text' : 'v-textarea' "   -->
                                
                              <span v-if="item.tag == 'v-textarea'">
                                <v-textarea
                                  v-model="item_edita_2[item.value]"
                                  :label="item.text"
                                  v-if="item_edita_2[item.if] == item.if_value"
                                  :hint="item.hint"
                                  :persistent-hint="item.cols>2"
                                  dense
                                  :rows="item.rows"
                                  
                                  outlined
                                  
                                  :autofocus="i==2"
                                  :rules="item.rule == 'S' ? notnullRules: noRules"
                                  @click="clicou_em=item.value"
                                  id="textara_to_print"
                                  ref="textara_to_print"
                                >
                                <!-- :filled="item.solo=='S'" -->
                                <!-- vai_imprimir -->
                                </v-textarea> 
                              </span>                                   
                                  
                              </v-col>
                          </v-row>
                      </v-container>
                    </v-form>
                  </v-card-text>
  
          <v-card-actions>
              <v-btn
                  x-small
                  color="red"
                  text
                  @click="remover_tabela(tabela_selecionada_2, item_edita_2, card_titulo), dialog_tabela_2 = false"
              >
                Excluir
              </v-btn>
  
              <v-spacer></v-spacer>


              <v-btn
                  color="blue"
                  text
                  @click="imprimir_pagina()"
                  class="mr-5"
                  x-small
                  v-if="0>1"
              >
                Imprimir
              </v-btn>
  
              <v-btn
                  color="red"
                  text
                  @click="dialog_tabela_2 = false"
              >
                Cancelar
              </v-btn>
              
              &nbsp
  
              <v-btn
                  :disabled="!valido"
                  color="primary"
                  @click="salvar_tabela_2(tabela_selecionada_2, item_edita_2, 'dialog_tabela_2')"
              >
                Salvar
              </v-btn>
          </v-card-actions>
          </v-card>
      </v-dialog>

      <v-snackbar
          v-model="snackbar"
          :timeout="2000"
          top
          :color="snack_color"    
      >
        <center>
          {{ snack_text }}
        </center>
      </v-snackbar>

    </v-container>


    <!-- MOSTRA JANELA MODAL -->
    <div>
      <v-row>
        <v-col>
        <LembreteVue 
          :aberto = 'janela_modal'
          pro_tabela = 'CLIENTES'
          :pro_titulo = "'Localizar Pacientes'"
          :pro_obj_menus = null
          :show_menu = 'false'
          :pro_modo_edicao = 'false'
          @muda_aberto_lembretes="muda_aberto_modal"
        />
        </v-col>
      </v-row>
    </div>

  </div>
</template>

<script>

import axios from "axios";

import DSibasico from '../assets/js/DSi_basico'//chamo funcao do arquivo
import { downloadText, processCsvFile, processTxtFile, gerarSQLInsert } from '../assets/js/DSi_basico'
import DSisql from '../assets/js/DSi_sql'//chamo funcao do arquivo
import { send_whats_api, status_whats } from '../assets/js/DSi_whats'


var url =  sessionStorage['url']


export default {


     components:{
      LembreteVue: () => import("./Lembretes.vue")
     },

    data: () => ({

        config_whats: {},

        meu_sub_menu:{},

        imageUrl: null,
        imageUrl_2: null,
        base64Image: null,

        items_lista_importados: [], //uso no sql
        dados_importados: [],
        qual_importar:'',

        obj_dados_empresa: null,

        modelo_mensgem:'',
        modelo_sub_mensgem:'',
        menu_destacar:'',

        items_menus: null,

        superA: false,
        backgroundImage:'',

        value_expansion: [ 1 ], //[ 0,1 ]
        session_bd:'',

        clicou_em_copiar: false,
        menu_selecionou:{},

        selectedFile: null,
      

        buscar_nomes_model:'Todos',
        items_buscar_nomes: ['Todos', 'Pendentes', 'Enviados'],

        menu_nomes: [
          // { title: 'Excluir', acao: 'del_all' },
          { title: 'Status'            , acao: 'atu_status' },
          { title: 'Importar Menus'    , acao: 'importar_menus' },
          { title: 'Exportar Menus'    , acao: 'exportar_menus' },
          { title: 'Excluir Tudo'      , acao: 'del_all' },
        ],
        
        menu_respostas: [
          // { title: 'Excluir', acao: 'del_all' },
          { title: 'Importar Resposta'   , acao: 'importar_respostas' },
          { title: 'Exportar Respostas'  , acao: 'exportar_respostas' },
        ],

        menu_45: [
          { title: 'Limpar fila de envios', acao: 'limpar_fila' },
        ],

        zapzap_mostrar: false,
        zapzap_conectado: false,

        janela_modal: false,
        modal_salvou: false,
        modal_qual:'',
        obj_janela_modal: {},

        selected: [],
        selected_2: [],
        

        overlay: false,
        today:'',


        

        isMobile : false,

        dialog_tabela: false,
        tabela_selecionada:'',
        items_lista:[],
        items_header: [],
        items_editaveis: [],
        item_edita: {},
        obj_clicado : {},

        dialog_tabela_2 : false,
        tabela_selecionada_2:'',
        items_lista_2:[],
        items_header_2:[],
        items_editaveis_2:[],
        item_edita_2: {},
        obj_clicado_2 : {},
        


        tags_empresa:[],

        
        item_sub_edita: {},
        card_titulo:'',
        valido:false,

  
        notnullRules: [
          v => !!v || 'necessário'
         ],

         noRules:[v => true],

         snackbar: false,
         snack_text: 'My timeout is set to 2000.',
         snack_color: "success",

         w_data: '99px',
         w_fone: '125px',
         w_cpf: '120px',
         w_cnpj: '150px',
         w_nome: '200px',
         w_situacao: '60px',
         w_valor: '90px',


    }),

    created(){
        this.onCreated()
    },

    watch:{

      dados_importados(a){
        if (a?.length){
          this.salvar_importacao(this.qual_importar)
        }
      },

      dialog_tabela(a){
        if (a){
           if (this.tabela_selecionada == 'whats_menus'){

              if (this.item_edita.CODIGO){
                  // converte texto em emoji
                  let vem = this.encontrarCaractereNoTexto(this.obj_clicado.TEXTO) 
                  this.$set(this.item_edita, 'TEXTO', vem)
              }
           }
        }
      },
      dialog_tabela_2(a){
        if (a){
           if (this.tabela_selecionada_2 == 'whats_respostas'){

             if (this.item_edita_2.CODIGO){
                // converte texto em emoji
                this.$set(this.item_edita_2, 'TEXTO'    , this.encontrarCaractereNoTexto(this.obj_clicado_2.TEXTO))
                this.$set(this.item_edita_2, 'RESPOSTA' , this.encontrarCaractereNoTexto(this.obj_clicado_2.RESPOSTA))
             }
           }
        }
      },

      'item_edita_2.TIPO'(a){
         this.campos_adicionais(this.item_edita)
      },

      buscar_nomes_model(){
         this.tabela_2('whats_respostas', this.obj_clicado)//atualizo
      }
    },
  
    computed: {
      // imageUrl() {
      //   return URL.createObjectURL(this.selectedFile);
      // }
    },

    methods:{
    

      seleciona_menu(row){
        alert('oi')
        console.log('seleciona_menu:', row);
        
      },

      conectar_cliente(acao){

        if (!this.config_whats){
          return false
        }


        let ss 
        ss =  this.config_whats.API_LINK
        ss = ss.replaceAll('@token', this.obj_cliente_selecionado.TOKEN) 


        if (acao == 'status'){
           ss = ss.replaceAll('/send-messages', '/status') 
        }

        window.open(ss, '_blank');
      },

      onFileChange(file) {
        if (file) {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.imageUrl_2  = e.target.result;
            this.base64Image = e.target.result.split(',')[1]; // Armazena apenas a parte Base64
            // console.log('base64Image:', this.base64Image);
            
          };
          reader.readAsDataURL(file);
        } else {
          this.imageUrl_2  = null;
          this.base64Image = null; //tem limite nao salva tudo no blob do phpmyadmin
        }
      },

      async salvar_importacao(tabela){
        
        let novo_array 
        let CODIGO_CLIENTE = this.obj_cliente_selecionado.CODIGO
        let TOKEN          = this.obj_cliente_selecionado.TOKEN
        
      
        novo_array = this.dados_importados.map((x) => x); //clonando array copiar array
        novo_array = this.substituirCampo(novo_array, "CODIGO_CLIENTE" , CODIGO_CLIENTE);
        novo_array = this.substituirCampo(novo_array, "TOKEN"          , TOKEN);


        let sql =  gerarSQLInsert(tabela, novo_array);

        // console.log(sql);

        if (!confirm(`Confirmar Importação?`)){
          return false
        }

       await  this.crud_sql(sql)

       //atualiza janela
       await this.open_campanhas()


       this.dados_importados = []
        

      },

      substituirCampo(objetos, campo, novoValor) {
        return objetos.map(objeto => {
          if (objeto.hasOwnProperty(campo)) {
            return { ...objeto, [campo]: novoValor };
          }
          return objeto;
        });
      },



      async triggerFileInput() {
        await this.$refs.fileInput.click();
      },


      async handleFileUpload(event) {
        let file = event.target.files[0];
        this.dados_importados = [];

        if (file) {
          const fileName = file.name;
          const fileExtension = fileName.split('.').pop().toLowerCase();

          if (fileExtension === 'csv') {

              this.dados_importados = await processCsvFile(file);

          } else if (fileExtension === 'txt') {
            
              this.dados_importados = await processTxtFile(file);

          } else {
            console.error('Tipo de arquivo não suportado');
          }

          event.target.value = null; // limpa valor do input
        }
      },


      async exportArray(name, get_array) {
          
          //converte array to string
          const texto_salvar = JSON.stringify(get_array)
          console.log('get_array:', get_array);

          downloadText(texto_salvar, name+'.txt');//DSi_basico.js

      },


      async campos_adicionais(item){

        // return 'exit'


        if (this.tabela_selecionada=='whats_respostas'){

            removerCampos(this, 'items_editaveis_2', ['CELULAR_TESTE', 'CELULAR_RESPONDER', 'USAR_LINK_ATENDENDE', 'API_LINK','SALVAR_WEBHOOK'] )
            
            if (['MODULO WHATSAPP'].includes(item.SERVICO)){

                this.items_editaveis.push(
                  // { text: 'WhatsApp para Testes', value: 'CELULAR_TESTE',tag:'v-text', type:'text', cols:'4',rule:'N',vmask:'cel', width: this.w_fone, hint:'Quando em TESTE, as mensagens serão enviadas para este número' },
                  { text: 'Salvar WebHook', value: 'SALVAR_WEBHOOK',type:'text',tag:'v-select',tag_items:['S','N','A','C','F'],cols:'2', rule:'S', width: this.w_situacao, hint:'Sim, Não, Agenda, Campanha, Fluxo'} ,
                  // { text: 'ABRIR IMPRESSÃO',  tag:'v-chip', cols:'2', color:'primary', icon:'mdi-information', click:'get_impressao'},
                  // { text: 'ABRIR IMPRESSÃO',  tag:'v-icon', cols:'1', color:'primary', icon:'mdi-information', click:''},
                  { text: 'API da instância', value: 'API_LINK',
                    type:'text',
                    tag:'v-autocomplete',
                    tag_items:[
                        'http://dsi.app.br:8051/@token/send-messages',         
                        'http://localhost:8051/@token/send-messages',                  
                    ],
                    cols:'6',rule:'N' , upper:'N'
                  },
                  { text: 'ID DOC ou USER', value: 'ID_USER',tag:'v-text', type:'number', cols:'2',rule:'N', width: this.w_fone, hint:'Digite o codigo do doutor ou do usuario ou 0 para todos' },

                )
            }
            
        }

      },

      retorna_titulo(titulo, acao){

         if (acao == 'del_all'){
           return titulo 
         }
         
         else if (acao == 'exportar_menus'){
            if (this.items_lista.length){
              return titulo
            }else{
              return false
            }
         }
         
         else{
          return titulo
         }
      },

      async verifica_limite(limite){
        
        let ja_tem = this.items_editaveis_2.length 

        if (ja_tem >= limite){
          if (!confirm(`Esta lista já tem ${ja_tem} contatos!\nRecomendamos enviar no máximo ${limite} contatos por vez!\nDeseja continuar mesmo assim?`)){
            return false
          }
        }
        
        return true
            
      },

      encontrarEmojiNoTexto(texto) {

          if (!texto){
            return texto
          }

          // console.log('texto:', texto);
          // Divida o texto em palavras usando espaços em branco como delimitadores
          const palavras = texto.split(/\s+/);

          let achou = '';
          for (let i = 0; i < palavras.length; i++) {
            // Verificar se a palavra contém sequências percent-encoded
              try {
                // Decodificar a sequência percent-encoded dentro da palavra
                achou = encodeURIComponent(palavras[i]);
                // console.log('achou:', achou);

                // se achou ele substitui no texto
                texto = texto.replaceAll(palavras[i] , achou );


              } catch (error) {
                // console.error(`Erro ao decodificar sequência percent-encoded: ${palavras[i]}`);
                achou = palavras[i];
              }
          }

          // console.log('palavras:', palavras);

          return texto
      },

      encontrarCaractereNoTexto(texto) {


          if (!texto){
            return texto
          }

          // console.log('texto:', texto);
          // Divida o texto em palavras usando espaços em branco como delimitadores
          const palavras = texto.split(/\s+/);

          let achou = '';
          for (let i = 0; i < palavras.length; i++) {
            // Verificar se a palavra contém sequências percent-encoded
            if (/%[0-9A-Fa-f]{2}/.test(palavras[i])) {
              try {
                // Decodificar a sequência percent-encoded dentro da palavra
                achou = decodeURIComponent(palavras[i]);
                // console.log('achou:', achou);

                // se achou ele substitui no texto
                texto = texto.replaceAll(palavras[i] , achou );


              } catch (error) {
                // console.error(`Erro ao decodificar sequência percent-encoded: ${palavras[i]}`);
                achou = palavras[i];
              }
            } else {
              // Se a palavra não contiver sequências percent-encoded, mantenha a palavra original
              achou = palavras[i];
            }
          }

          // console.log('palavras:', palavras);

          return texto
      },



      alerta_zap(){
        alert("É necessário ATIVAR o módulo WhatsApp, entre em contato para maiores informações")
      },

      async situacao_whats(){

        try {
          this.zapzap_mostrar   = JSON.parse(sessionStorage['zapzap_mostrar'])
        }catch(e){}

        try {
          this.zapzap_conectado = JSON.parse(sessionStorage['zapzap_conectado'])
        }catch(e){}
        
        try {
          this.config_whats = JSON.parse(sessionStorage['config_whats'])
        }catch(e){ this.config_whats = false}


        return false
        let zapzap =  sessionStorage['config_whats']
        
        // return false
        if ((zapzap)&&(zapzap.SITUACAO !='CANCELADO')){
          this.zapzap_mostrar = true
        }else{
          this.zapzap_mostrar = false
        }

        let r = null
        if (r = await status_whats()){
            console.log('r:', r);

            this.zapzap_conectado = r.connected
            
        }
      },

      replace_emojiHERE(msg){
          return replace_emoji(msg)
      },

      async replace_msg(msg, item){

        console.log('here item:', item);

        let nome = item.NOME
        nome = nome.toLowerCase().trim();  //passo tudo pra minuscula
        nome = nome.split(" ");//separo as palavras
        nome = nome[0];//pego o primeiro nome        
        nome = nome.charAt(0).toUpperCase() + nome.slice(1);//passo 1 letra maiuscula

        let ide = item.COD_CLIENTE
        if (ide){ '*'+ide+'*' }

        msg = msg.replaceAll( '@nome'             , '*'+ nome + '*' );
        msg = msg.replaceAll( '@ide'              , ide );

        return msg

      },

      async disparar_whats(){


        if (!this.zapzap_conectado){
            alert('Volte para a Agenda e Faça a Leitura do QRcode do WhatsApp para conectar o dispositivo ao sistema!')
            return false
        }

        if (!confirm(`Iniciar o disparo de mensagens para esta lista de Contatos?`)){
          return false
        }

        this.define_defaults('')


        // verifica se nao esta vazio a lista de nomes
        let items = this.items_editaveis_2
        console.log('items:', items);
        if (!items){
          return false
        }

        // verifica a mensagem
        let msg = this.item_edita.TEXTO
        msg = this.encontrarEmojiNoTexto(msg) 



        if (msg.length<=10){
          alert('Defina uma mensagem válida!')
          return false
        }
        if (!msg){
          alert('Defina uma Mensagem para ser enviada!')
          return false
        }


        let file = this.item_edita.URL_FILE

        let retorno = ''
        let sql     = ''
        let item    = null
        let msg_tratada = ''

        for (let i = 0; i < items.length; i++) {
          item = items[i]
          
          // verifica o numero
          if (item.NUMERO.length>=10){
            

            // TRATO A MSG..REPLACE NO @nome, @ide
            msg_tratada = await this.replace_msg(msg, item)//pega o nome etc..


            // ENVIA OPCAO DE PARAR RECEBIMENTO DO ZAP
            if ((this.item_edita.ENVIAR_PARAR == 'true') || (this.item_edita.ENVIAR_PARAR == true)){
              msg_tratada = msg_tratada+`\n* Caso não queira mais receber esse tipo de mensagem, responda *PARAR*`
            }

            // console.log('msg:', msg_tratada);
            if (retorno = await send_whats_api('CAMPANHA', item.NUMERO, msg_tratada, file)){
              

              sql = `update whats_respostas set ID_WHATS = '${retorno.id}', DATA_VISTO = '${this.today}'  where codigo = ${item.CODIGO}`
              // console.log('sql:', sql);
              await this.crud_sql(sql)
              
            }
          }  
        }

        await this.tabela_2('whats_respostas', this.obj_clicado)//atualizo

      },
        
        async clicou_menu_nomes(acao){
          
          let item = Object.assign({}, this.obj_clicado)

          if (acao == 'del_all'){
            

            if (confirm(`Deseja Excluir Menus e Respostas desta lista?`)){
              let sql = `delete from whats_menus     WHERE CODIGO_CLIENTE = ${this.obj_cliente_selecionado.CODIGO};
                         delete from whats_respostas WHERE CODIGO_CLIENTE = ${this.obj_cliente_selecionado.CODIGO};`
              console.log('sql:', sql);
              
              
              await this.crud_sql(sql)

              //atualiza janela
              await this.open_campanhas()

              // await this.tabela_2('whats_menus', this.obj_clicado)//atualizo

            }
          }

          else if (acao == 'limpar_fila'){
                        

          }

          else if (acao == 'exportar_menus'){
            this.exportArray('menus', this.items_lista)
          }

          else if (acao == 'exportar_respostas'){
            
            let sql = `select * from whats_respostas where CODIGO_CLIENTE = ${this.obj_cliente_selecionado.CODIGO}`
            await DSisql.crud_abrir_tabela(this, sql, 'items_lista_importados')

            this.exportArray('respostas', this.items_lista_importados)
          }


          else if (acao == 'atu_status'){
            this.conectar_cliente('status')
          }

          else if (acao == 'importar_menus'){
            this.qual_importar = 'whats_menus'
             await this.triggerFileInput()
          }

          else if (acao == 'importar_respostas'){
            this.qual_importar = 'whats_respostas'
             await this.triggerFileInput()
          }

        },

        async abrir_janela_modal(item, acao){

          this.janela_modal      = true
          sessionStorage['pacientes_selecionados'] = ''

          if (acao == 'editar'){
            this.obj_janela_modal  = item
          }
          else if (acao == 'novo'){
            this.obj_janela_modal  = null
          }

        },

        muda_aberto_modal(valor){
          this.janela_modal = valor
          
          this.modal_alteracoes()
        },

        async modal_alteracoes(){
          let pacientes_selecionados = JSON.parse(sessionStorage['pacientes_selecionados'])
          // console.log('pacientes_selecionados:', pacientes_selecionados);

          if (pacientes_selecionados.length>0){

            if (confirm(`Deseja adicionar os Pacientes selecionados?`)){
              const resultado = this.items_editaveis_2.concat(pacientes_selecionados);
              // console.log('resultado:', resultado);

              this.add_contato(pacientes_selecionados)
            }

          }
        },

        salvar_msg(item){

          item = this.obj_clicado

          let media = this.item_edita.URL_FILE
          let parar = this.item_edita.ENVIAR_PARAR


          //           const emoji = '👩🏻‍💻';
          // const encodedEmoji = encodeURIComponent(emoji);

          // console.log(`Emoji: ${emoji}`);
          // console.log(`Encoded: ${encodedEmoji}`);


          // // const percentEncodedEmoji = '%F0%9F%91%A9%F0%9F%8F%BB%E2%80%8D%F0%9F%92%BB';
          // const percentEncodedEmoji = '%E2%80%8D';
          // const decodedEmoji = decodeURIComponent(percentEncodedEmoji);

          // console.log(`Encoded: ${percentEncodedEmoji}`);
          // console.log(`Decoded: ${decodedEmoji}`);


          const textoOriginal = this.item_edita.TEXTO;
          const textoTratado = this.encontrarEmojiNoTexto(textoOriginal);

          let sql = `update  WP_CAMPANHA set TEXTO = '${textoTratado}' , URL_FILE = '${media}', ENVIAR_PARAR = '${parar}' where codigo = ${item.CODIGO}`
          // let sql = `update  WP_CAMPANHA set TEXTO = '${this.item_edita.TEXTO}' , URL_FILE = '${media}' where codigo = ${item.CODIGO}`
          console.log(sql);
          // return false
          this.crud_sql(sql)

          // ATUALIZA LISTA SEM DAR CLOSE OPEN NA TABELA
          // let index = this.items_conta.indexOf(item)
          let index = this.items_lista.findIndex( x => x.CODIGO === item.CODIGO );
          this.$set(item, 'TEXTO'       ,  this.item_edita.TEXTO)
          this.$set(item, 'URL_FILE'    ,  this.item_edita.URL_FILE)
          this.$set(item, 'ENVIAR_PARAR',  `${this.item_edita.ENVIAR_PARAR}` )
          
          Object.assign(this.items_lista[index], item)
        },

        remover_esse(item){
            console.log('item:', item);
        
            if (confirm(`Deseja realmente Excluir o ítem ${item.CODIGO}?`)){
              let sql = `delete from whats_respostas where codigo = ${item.CODIGO}`
              console.log(sql);
              this.crud_sql(sql)

              // removo com base no valor dele
              let index = this.items_editaveis_2.findIndex( x => x.CODIGO === item.CODIGO );
              this.items_editaveis_2.splice(index, 1);//removo a coluna senha


            }
        },

        async add_contato(items){
            
            // console.log(this.obj_clicado);
            this.define_defaults('')

            let nome  = ''
            let numero = ''
            let sql = ''

            if (! await this.verifica_limite(300)){
              return false
            }

            this.overlay = true


            if (items){
              // ADICIONO DE UM ARRAY DE OJBETO

              for (let i = 0; i < items.length; i++) {
              

                  // console.log('items[i]:', items[i]);

                  let cod_cliente = 0
                  
                  if (items[i].CODIGOCLIENTE > 0){
                    cod_cliente =  items[i].CODIGOCLIENTE
                  }
                  else if (items[i].COD_CLIENTE > 0){
                    cod_cliente = items[i].COD_CLIENTE
                  }

                  nome     = items[i].NOME
                  nome     = nome.trim();
                  numero   = items[i].CELULAR
                  if (!numero){
                    numero   = items[i].FONE1
                  }

                  
                  if(url == '/api/'){
                      numero = '44999685172'//somente para teste
                  }


                  sql = `
                    insert into whats_respostas
                    (
                    COD_CAMPANHA,
                    NOME        ,
                    NUMERO      ,
                    COD_CLIENTE 
                    )

                    VALUES
                    (
                      ${this.obj_clicado.CODIGO},
                      '${nome}',
                      '${numero}',
                      ${cod_cliente}
                    )
                  ;`


                  if (numero){
                    // console.log(sql);
                     await this.crud_sql(sql)//adiciono
                  }
              }

            }
            else{

              // ADICIONO MANUALMENTE
              if ((!this.item_sub_edita.NOME) || (!this.item_sub_edita.NUMERO)){
                alert('Digite os dados!')
                return false
              }

              if (!this.obj_clicado.CODIGO){
                alert('Selecione uma Campanha!')
                return false
              }

              nome   = this.item_sub_edita.NOME
              nome   = nome.trim();

              numero = this.item_sub_edita.NUMERO


              sql = `
                insert into whats_respostas
                (
                COD_CAMPANHA,
                NOME        ,
                NUMERO        
                )

                VALUES
                (
                  ${this.obj_clicado.CODIGO},
                  '${nome}',
                  '${numero}'
                )
               ;`


                await this.crud_sql(sql)//adiciono

                // limpa os campos
                this.item_sub_edita.NOME   = ''
                this.item_sub_edita.NUMERO = ''

                //volta o focus no nome
                this.focus_by_id('id_nome')

            }

            // console.log('sql:', sql);

            this.overlay = false
            await this.tabela_2('whats_respostas', this.obj_clicado)//atualizo

        },

        seleciona_campanha(row){

            // console.log('seleciona_campanha:', row);
            // limpa
            this.obj_clicado   = {}
            this.obj_clicado_2 = {}

            this.menu_selecionou = Object.assign({}, row)
            
            this.seleciona_linha(row)

            this.tabela_2('whats_respostas',row)
       
        },

        seleciona_item_2(row){

          // console.log('seleciona_item_2:', row);

          this.selected_2= [{"CODIGO" : row.CODIGO}] //via v-modal no data-table

          this.obj_clicado_2 = Object.assign({}, row)


          if (row.TIPO == 'MENU'){

            // let meu_sub_menu = this.items_lista.filter(x => x.CODIGO === row.RESPOSTA);
            let gget = this.items_lista.filter(x => x.ID === row.RESPOSTA);
            
            this.meu_sub_menu = gget[0]

            // console.log('meu_sub_menu:', meu_sub_menu[0]);
            // this.modelo_sub_mensgem = `<b>MENU:</b> (<b><span style="color: blue;">${this.meu_sub_menu?.[0]?.DESCRICAO}</span></b>) ${this.meu_sub_menu?.[0]?.ID} <br/> ${this.meu_sub_menu?.[0]?.TEXTO}`
            this.modelo_sub_mensgem = `<b>MENU:</b> (<b><span style="color: blue;">${this.meu_sub_menu?.DESCRICAO}</span></b>) ${this.meu_sub_menu?.ID} <br/> ${this.meu_sub_menu?.TEXTO}`

            // this.menu_destacar = (meu_sub_menu[0].DESCRICAO) || ''
            this.menu_destacar = this.meu_sub_menu?.DESCRICAO ?? '';

          }
          else{
            this.modelo_sub_mensgem = `<b>RESPODE:</b><br>`+ (row.RESPOSTA)
          }

        },

        async onCreated(){
            this.isMobile = (window.innerWidth < 600)
            this.define_tags_key()

            this.superA = (sessionStorage['lg:USUARIO'] == 'A') ? true : false 
            this.obj_dados_empresa       = JSON.parse(sessionStorage['obj_dados_empresa'])
            this.obj_cliente_selecionado = JSON.parse(sessionStorage['obj_cliente_selecionado'])

            this.session_bd =  'cfc_'+ this.obj_cliente_selecionado.CODIGO;


            await this.open_campanhas()
            await this.situacao_whats()


        },

        async open_campanhas(){


            this.tabela('whats_menus','','')
            
            // let sql = `SELECT *  FROM WP_CAMPANHA` 

            // // console.log(tabela+': '+sql);
            // // await this.crud_abrir_tabela(sql, 'items_lista')
            // await DSisql.crud_abrir_tabela(this, sql, 'items_campanha')
        },

        async tabela(tabela, acao, item){//PASSO 3
            //passo 3 executo a acao quando clicar nos items e monto os campos editaveis e os campos do data-table

            // this.search = ''//limpa filtro

            this.tabela_selecionada = tabela
            // this.card_titulo = item.text

           //   console.log('item:');
           //   console.log(item);

            //limpa array
            this.items_lista       = [] //recebe os dados do sql
            this.items_header      = [] //campos que tem que mostrar no header
            this.items_editaveis   = [] //campos editaveis mas nao tem que mostrar no header


            if (tabela=='whats_menus'){
                
                // this.card_titulo = `Campanha`

                this.items_header = [

                 { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width:'30px'},
                 { text: 'CODIGO', value: 'CODIGO', type:'number', cols:'0' },  
                 { text: 'ID', value: 'ID', tag:'v-text', type:'text', cols:'2', rule:'N' },                    
                 { text: 'TOKEN', value: 'TOKEN', tag:'v-text', type:'text', cols:'2', rule:'N' },                    
                 { text: 'INICIAL', value: 'INICIAL', type:'text', tag:'v-select', tag_items:['S','N',],cols:'2',rule:'S'  },      
                 { text: 'ATIVO', value: 'ATIVO', type:'text', tag:'v-select', tag_items:['S','N',],cols:'2 ',rule:'S'  },      

                 { text: 'Descricao', value: 'DESCRICAO', tag:'v-text', type:'text', cols:'6', rule:'S', upper:'S', width:'180px' },
                 { text: 'Texto da Mensagem', value: 'TEXTO',tag:'v-textarea', type:'text', cols:'12',rule:'S', width:'333px', rows:'10' },  
                 
                 { text: 'URL_FILE', value: 'URL_FILE', tag:'v-file-input', type:'text', cols:'6', rule:'N', upper:'N' },
                 { text: 'URL_FILE', value: 'URL_FILE', tag:'v-text', type:'text', cols:'12', rule:'N', upper:'N', tag_clear:'S' },
                 
                 { text: 'Intervalo', value: 'INTERVALO_TIPO',type:'text',tag:'v-select',tag_items:['DIAS','HORAS','MINUTOS'],cols:'2', rule:'N', hint:'Horas: Máximo de 23 Minutos: Máximo de 59'}, 
                 { text: 'De', value: 'INTERVALO', tag:'v-text', type:'number', cols:'1', rule:'N' },             

                 { text: 'DAS', value: 'HORA_DAS', tag:'v-text', type:'time', cols:'2', rule:'N' },      
                 { text: 'ATÉ', value: 'HORA_ATE', tag:'v-text', type:'time', cols:'2', rule:'N' },   

                 { text: 'Texto de Ausência', value: 'TEXTO_AUSENTE',tag:'v-textarea', type:'text', cols:'12',rule:'N', width:'333px' },  

                
                //  { text: 'URL_FILE', value: 'URL_FILE',tag:'v-text', type:'text', cols:'4',rule:'S', width: this.w_nome, upper:'S' },

                ]

                // clono os items
                this.items_editaveis = this.items_header.map((x) => x); //clonando array copiar array
                this.items_header = this.items_header.map((x) => x); //clonando array copiar array

                // REMOVO DO HEADER
                this.items_header.splice(this.items_header.findIndex(x => x.value === "ATIVO") , 1);//removo a coluna senha
                this.items_header.splice(this.items_header.findIndex(x => x.value === "INICIAL") , 1);//removo a coluna senha

                // REMOVO DO EDITAVEL
                // this.items_editaveis.splice(this.items_header.findIndex(x => x.value === "x_DATA") , 1);//removo a coluna senha


                let sql = `SELECT *  FROM ${tabela} where CODIGO_CLIENTE = ${this.obj_cliente_selecionado.CODIGO} order by INICIAL desc` 
                // console.log('sql:', sql);
                await DSisql.crud_abrir_tabela(this, sql, 'items_lista')

                await strToemoji(this)

                async function strToemoji(that){
                  
                  that.items_lista = that.items_lista.map(objeto => {
                      return {
                          ...objeto,
                          ['TEXTO']: that.encontrarCaractereNoTexto(objeto['TEXTO']) 
                      };
                  });

                }



                this.items_header_2 = [

                 { text: '..', value: 'actions_delete', sortable: false, type:'hide', cols:'0',width:'5px'},

                 { text: 'CODIGO', value: 'CODIGO', type:'number', cols:'0' },                    
                 { text: 'Nome', value: 'NOME',tag:'v-text', type:'text', cols:'2',rule:'S', width: this.w_nome, upper:'N' },
                 { text: 'Numero', value: 'NUMERO',tag:'v-text', type:'text', cols:'2',rule:'S', width: this.w_nome, upper:'N' },
                ]
            }
        },



        async upload_logo(file, pasta, nome, campo_salvar, objeto){

          // alert(nome)
          // alert(campo_salvar)
          // console.log(file);


          //update refresh image
          this.urlLogo = URL.createObjectURL(file);

          var bodyFormData = new FormData();
          bodyFormData.append('pasta'       , pasta);
          bodyFormData.append('nome_arquivo', nome);
          bodyFormData.append('userfile'    , file);

          //isso daqui server para ser lido com o $_POST no php 
          const header =   { headers: {'Content-Type': 'multipart/form-data'}}

          // console.log(data);

          const res = await axios.post(url+'upFile.php', bodyFormData, header, )

          // console.log(res.data);

          let r = ''

          // console.log(res.data);

          if (res.data.status == 'OK'){

              this.alerta('Atualizado','green')
            
              let tipo = file.type
              tipo = tipo.substr(tipo.indexOf('/')+1,5);
            
              r = ( pasta+'/'+nome+'.'+tipo)

              // sessionStorage['emp_url_logo'] =  sessionStorage['url'] + 'images/'+ r //salvo para ser lido

          }else{
              this.alerta('Ops: '+res.data,'red')
          }

          //salvo no campo editavel no caso dados da empresa
          // this.item_edita[campo_salvar] = r
          
          if (campo_salvar){

            if (url == '/api/'){
              r = 'http://localhost/vue_cfc/public/images/'+r
            }
            else{
              r = url +'/images/' + r
            }

            // console.log('r::::', r);

            // return r
            // this.$set(this.item_edita, campo_salvar,  r)
            this.$set(this[objeto], campo_salvar,  r)

            // this.salvar_msg()

          }


        },


        //passo 3.2
        async tabela_2(tabela, item){


          // alert('tabela_2:'+ tabela)
          this.tabela_selecionada_2 = tabela

          //limpa array
          this.items_lista_2       = [] //recebe os dados do sql
          this.items_header_2      = [] //campos que tem que mostrar no header
          this.items_editaveis_2   = [] //campos editaveis mas nao tem que mostrar no header


          if (tabela=='whats_respostas'){
                
              // this.card_titulo = `Campanha`

              this.items_header_2 = [

              //  { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width:'30px'},
              { text: '..', value: 'actions_delete', sortable: false, type:'hide', cols:'0',width:'5px'},

              { text: '..', value: 'actions_2', sortable: false, type:'hide', cols:'0',width: this.w_actions},
                  // { text: 'CODIGO', value: 'CODIGO', type:'number', cols:'0' },              
                  // { text: 'CLIENTE', value: 'CODIGO_CLIENTE', tag:'v-text', type:'text', cols:'2', rule:'N' },      
                  // { text: 'TOKEN', value: 'TOKEN', tag:'v-text', type:'text', cols:'3', rule:'N',upper:'N' },      

                  { text: 'Opção', value: 'CODIGO_FLUXO', tag:'v-text', type:'text', cols:'1', rule:'N' },
                  { text: 'Texto', value: 'TEXTO', tag:'v-text', type:'text', cols:'9', rule:'S',width:'300px', focus: true },  
                  { text: 'Tipo Resposta', value: 'TIPO',type:'text',tag:'v-select',tag_items:['TEXTO','MENU','IMAGEM'],cols:'2', rule:'S'},

                  // { text: 'TEXTO_AUSENTE', value: 'TEXTO_AUSENTE', tag:'v-text', type:'text', cols:'9', rule:'S',width:'90px', focus: true },  

                  { text: 'MENU', value: 'RESPOSTA', tag:'v-select-item', type:'text', cols:'12', rule:'N', 
                    if:'TIPO', if_value:'MENU',
                     tag_items: this.items_lista
                  },

                  { text: 'Responder com', value: 'RESPOSTA',tag:'v-textarea', type:'text', cols:'12',rule:'N', width:'333px', rows:'10',
                    if:'TIPO', if_value:'TEXTO'
                  },
                

                  { text: 'URL_FILE', value: 'URL_FILE', tag:'v-file-input', type:'text', cols:'6', rule:'N', upper:'N' },
                  { text: 'URL_FILE', value: 'URL_FILE', tag:'v-text', type:'text', cols:'12', rule:'N', upper:'N', tag_clear:'S' },

                  { text: 'TOKEN', value: 'TOKEN', tag:'v-text', type:'text', cols:'2', rule:'N' },  
                  { text: 'ID_MENU', value: 'ID_MENU', tag:'v-text', type:'text', cols:'2', rule:'N' },  
                  { text: 'COD_MENU', value: 'COD_MENU', tag:'v-text', type:'text', cols:'2', rule:'N' },
              ]

              // clono os items
              // this.items_editaveis = this.items_header.map((x) => x); //clonando array copiar array
              this.items_editaveis_2 = this.items_header_2.map((x) => x); //clonando array copiar array


              // removo do header
              this.items_header_2.splice(this.items_header_2.findIndex(x => x.value === "RESPOSTA") , 1);//removo campos

              // REMOVO DO EDITAVEL
              // this.items_editaveis_2.splice(this.items_header_@.findIndex(x => x.value === "x_DATA") , 1);//removo a coluna senha


              this.define_defaults('')

              let where = ` WHERE ID_MENU = '${this.menu_selecionou.ID}' and CODIGO_CLIENTE = ${this.obj_cliente_selecionado.CODIGO}`
              // let where = ` WHERE COD_MENU = ${this.menu_selecionou.CODIGO} and CODIGO_CLIENTE = ${this.obj_cliente_selecionado.CODIGO}`

              // if(this.buscar_nomes_model == 'Pendentes'){
              //   // where += ` and DATA_VISTO <> '${this.today}' OR DATA_VISTO IS NULL `
              //   where += ` and (ID_WHATS = '' or ID_WHATS is null)`
              // }
              // else if(this.buscar_nomes_model == 'Enviados'){
              //   // where += ` and DATA_VISTO = '${this.today}' `
              //   where += ` and ID_WHATS <> '' `
              // }
              
              let sql = `SELECT *  FROM ${tabela} ${where}  ORDER BY CODIGO_FLUXO` 
              // console.log('sql:', sql);
              await DSisql.crud_abrir_tabela(this, sql, 'items_lista_2')


              await strToemoji(this)

              async function strToemoji(that){
                
                that.items_lista_2 = that.items_lista_2.map(objeto => {
                    return {
                        ...objeto,
                        TEXTO     :   that.encontrarCaractereNoTexto(objeto['TEXTO']),
                        RESPOSTA  :   that.encontrarCaractereNoTexto(objeto['RESPOSTA'])
                    };
                });

              }


              await montaModelo(this)
              async function montaModelo(that){

                let msg = ''
                let meu_arr = that.items_lista_2.map((x) => x); //clonando array copiar array
                for (let i = 0; i < meu_arr.length; i++) {
                   msg +=  `<br/> <b>`+ meu_arr[i].CODIGO_FLUXO + '</b> - '+meu_arr[i].TEXTO
                }

                that.modelo_mensgem = msg

                that.modelo_sub_mensgem = ''



              }

          }
        },


        seleciona_linha(row) {
          // console.log('row:');
          // console.log(row);
          //  this.selected= [{"CODIGO" : row.CODIGO}] //via v-modal no data-table
           this.selected  = [ Object.assign({}, row)] 


           //clonando objeto
           this.obj_clicado = Object.assign({}, row)
        },

        async focus_by_id(id){
           document.getElementById(id).focus();
        },

        define_defaults(tabela){

            // --------------------DEFINE DATA E HORA-----------------------
            let hoje = new Date().toLocaleDateString('pt-Br',{ datestyle: 'short', hour12: false, timeZone: 'America/Sao_Paulo' });  
            // console.log('hoje:'+ hoje);

            let today = hoje.slice(6, 10) +'-'+ hoje.slice(3, 5) +'-'+ hoje.slice(0, 2) ; //formato us yyyy-mm-dd
            this.today = today
            // console.log('today:'+ today);

            let hora = new Date().toLocaleTimeString('pt-Br',{ hour12: false, timeZone: 'America/Sao_Paulo' });
            let hora_curta = hora.slice(0, 5)
            // console.log('hora = '+ hora);
            // console.log('hora = '+ hora_curta);
            // --------------------DEFINE DATA E HORA-----------------------



            if (tabela=='whats_respostas'){
              // this.$set(this.item_edita, 'DATA', today)         
              this.$set(this.item_edita_2, 'CODIGO_CLIENTE' , this.obj_cliente_selecionado.CODIGO)
              this.$set(this.item_edita_2, 'TOKEN'          , this.obj_cliente_selecionado.TOKEN)
              this.$set(this.item_edita_2, 'COD_MENU'       , this.menu_selecionou.CODIGO)
              this.$set(this.item_edita_2, 'TIPO'           , 'TEXTO')
              this.$set(this.item_edita_2, 'CODIGO_FLUXO'   , this.items_lista_2.length+1)
              this.$set(this.item_edita_2, 'ID_MENU'        , this.menu_selecionou.ID)
            }

            else if (tabela=='whats_menus'){
              // this.$set(this.item_edita, 'DATA', today)         
              this.$set(this.item_edita, 'CODIGO_CLIENTE' , this.obj_cliente_selecionado.CODIGO)
              this.$set(this.item_edita, 'TOKEN'          , this.obj_cliente_selecionado.TOKEN)
              this.$set(this.item_edita, 'INICIAL'        , 'N')
              this.$set(this.item_edita, 'ATIVO'          , 'S')
              this.$set(this.item_edita, 'COD_CAMPANHA'   , 1)
              this.$set(this.item_edita, 'ID'             , DSibasico.geraIDdataHora())
            }
        },

        async before_update(tabela){
          // passo 8 antes de salvar verifico alguns campos necessarios em determinada tabela

          // alert('before:'+ tabela)
          // VERIFICA CAMPOS NUMERICOS QUE NAO PODEM IR VAZIOS
          let VERIFICAR = [
            'USUARIOS|CODIGODOUTOR|0',
          ]
        
          let CAMPOS      = []
          let CAMPO_VER   = ''
          let CAMPO_VALOR = ''
          for (let i = 0; i < VERIFICAR.length; i++) {
             
             CAMPOS      = VERIFICAR[i].split("|");//divide
             CAMPO_VER   = CAMPOS[1]
             CAMPO_VALOR = this.item_edita[CAMPO_VER]

             if (tabela==CAMPOS[0]){//verifica se é a tabela selecionada
                if (!CAMPO_VALOR){//verifica se vazio
                  this.$set(this.item_edita, CAMPOS[1], CAMPOS[2] ) 
                }
             }
          }


          // converte emoji em texto
          if (tabela == 'whats_menus'){
              this.$set(this.item_edita, 'TEXTO', this.encontrarEmojiNoTexto(this.item_edita.TEXTO))

              if (!this.item_edita.ID){
                this.$set(this.item_edita, 'ID', DSibasico.geraIDdataHora())
              }
          }

          if (tabela == 'whats_respostas'){
              this.$set(this.item_edita_2, 'TEXTO'      , this.encontrarEmojiNoTexto(this.item_edita_2.TEXTO))
              this.$set(this.item_edita_2, 'RESPOSTA'   , this.encontrarEmojiNoTexto(this.item_edita_2.RESPOSTA))

              if (!this.item_edita_2.ID_MENU){
                this.$set(this.item_edita_2, 'ID_MENU', this.menu_selecionou.ID)
              }
          }
        },


        meu_keyup(event,campo,upper){
  
            //identifica se o campo é double e muda o display
            if ((campo.includes('double_') || campo.includes('VALOR')) ){
                this.item_edita[campo] =  this.moeda(event)
                // @keyup="editedItem.double_credito = moeda($event)"
            }
            else if (campo.includes('CEP')) {

                this.consulta_cep(this.item_edita[campo])    
            }
            else{
                if ((campo.includes('EMAIL')==false) &&
                (campo.includes('SENHA')==false) &&
                (campo.includes('SENHA_WEB')==false)){

                    if (upper !=='N'){
                      this.allUpper(event, campo)
                    }
                    
                }
            }
        },

        allUpper(event, field){
            console.log('alluupper');
            let a = event.target.value;//captura o valor do event
            if (a!==''){  
                a = a.toUpperCase();
                // this.item_edita[field] = a;

                // event.target.value = a //AQUI ELE DEIXA O FINAL MINUSCULO PQ NAO APLICOU AINDA A ALTERAÇÃO NO ITEM_EDITA
                this.item_edita[field] = a;//AQUI ELE ALTERA DIRETO NO ITEM_EDITA


            } 
        },

        firstUpper(event, field){
              // console.log(event);
              let a = event.target.value;//captura o valor do event

              if (a!=='' && a.length==1){  
                a = a.toUpperCase();
                // this.item_edita[field] = a;

                event.target.value = a
              } 
          },



        alerta(text,color){
           DSibasico.alerta(this, text, color)
        },

        retorna_altura(menos){
            let r = (window.innerHeight - 80)
            if (menos){
              r = (r - menos)
            }
            return r
        },

        define_tags_key(){

            this.tags_empresa = [
            '@ide',
            '@nome',
            '@empresa',
            // '@paciente',
            // '@::)',
            // '@;u',
            // '@:u',
            // '@h_dir',
            // '@av',
            // '@sirene',
            // '@local',
            // '@festa1',
            // '@festa2',
            // '@festa3',
            // '@e_fone',
            // '@rb',
            // '@r_set'
            ] 
                    
        },

        insere_tag(tag){
          
          let ja = this.item_edita.TEXTO
          if (tag){
            // this[clicou_em] = this[clicou_em] + tag
            this.$set(this.item_edita, 'TEXTO',(ja) ? ja+tag : tag)
          }
        },

        getVmask(qual){
            
            if (qual == 'cel'){
                return "(##) #####-####"
            }
            else if (qual == 'fone'){
                return "(##) ####-####"
            }
            else if (qual == 'cnpj'){
                return "##.###.###/####-##"
            }
            else if (qual == 'cpf'){
                return "###.###.###-##"
            }
            else if (qual == 'cep'){
                return "#####-###"
            }
            else{
                return ""
            }
        },



           //---------------importando funcoes sql ----------------------- INICIO
          //EXECUTAR UM SQL TIPO UPDATE
          async crud_sql (sql) {
              return await DSisql.crud_sql(this, sql)
          },
  
          async tabela_create(tabela, obj_edita){
              await DSisql.tabela_create(this, tabela, obj_edita)
          },
  
          async tabela_update(tabela, obj_edita){
              await DSisql.tabela_update(this, tabela, obj_edita)
          },
  
          //ABRE OS DADOS DA TABELA
          async crud_abrir_tabela(sql, items_name){
              await DSisql.crud_abrir_tabela(this, sql, items_name)
          }, 
  
          //FICA NO BOTAO SALVAR.. VERIFICA SE UPDATE OU CREATE
          async salvar_tabela(tabela, obj_edita, dialog_name){

              await this.before_update(this.tabela_selecionada)//trato alguns campos necessarios

              await DSisql.salvar_tabela(this,tabela, obj_edita, dialog_name)
          },
          
          //FICA NO BOTAO SALVAR.. VERIFICA SE UPDATE OU CREATE
          async salvar_tabela_2(tabela, obj_edita, dialog_name){

              await this.before_update(this.tabela_selecionada_2)//trato alguns campos necessarios

              await DSisql.salvar_tabela(this,tabela, obj_edita, dialog_name)
          },
  
          async remover_tabela(tabela, obj_edita, titulo){

            // if (confirm(`Deseja excluir este lançamento?`)){
            
              await DSisql.remover_tabela(this, tabela, obj_edita, titulo)

              let sql = `delete from whats_respostas where cod_campanha = ${obj_edita.CODIGO}`              
              await this.crud_sql(sql)

              this.items_editaveis_2    = []
              this.obj_clicado    = {}
            //  }
          },
  
          editar_tabela(item, obj_edita_name, dialog_name) {
              this.seleciona_linha(item)
              this.seleciona_item_2(item)
              DSisql.editar_tabela(this, item, obj_edita_name, dialog_name)
          },
  
          nova_tabela(obj_edita_name,dialog_name){

             DSisql.nova_tabela(this, obj_edita_name,dialog_name)

             //define alguns valores default
             this.define_defaults(this.tabela_selecionada)
          },
          
          nova_tabela_2(obj_edita_name,dialog_name){

             DSisql.nova_tabela(this, obj_edita_name,dialog_name)

             //define alguns valores default
             this.define_defaults(this.tabela_selecionada_2)
          },
  
  
           //---------------importando funcoes sql ----------------------- FIM


    },

}
</script>

<style scoped>
    .container{
        max-width: 100%;
    }

  /* tamanho da fonte do data-table */
  .v-data-table >>> tr>td{
   font-size:12px !important;
   /* height: 25px !important; */
  }

 /* tamanho da fonte do data-table */

 /* cor da linha selecionada do data-table */
 .theme--light.v-data-table tbody tr.v-data-table__selected {
     /* seleciona_linha cor */
     background: #eee9e9 !important;
     /* background: blue !important; */
 }

 ::v-deep td.text-start{ /* padding das colunas data-table  */
   padding-left: 5px !important;
   padding-right: 2px !important;
   /* padding: 0px 2px !important; */
   /* background-color: red; */
 }

 ::v-deep th.text-start{ /* padding dos titulos das colunas data-table */
   padding: 0px 4px !important;
   /* background-color: red; */
 }

 .negrito {
    font-weight: bolder !important;
    /* color: red !important; */
    /* Outros estilos desejados */
 }

 blue{
    color: blue !important;  /* Define a cor do texto para azul */
 }
 
</style>
