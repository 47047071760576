<template>
  <v-container fluid class="pa-0 no-gutters">
    {{ selectedDate }} <br/>
    <!-- {{ eventsByDate }} -->
    daysOfWeek: {{ daysOfWeek }} <br/>
    <div class="schedule-wrapper">
      <div class="fixed-column">
        <v-card outlined>
          <v-card-title class="text-center">Horários</v-card-title>
          <v-list dense>
            <v-list-item
              v-for="(hour, index) in dynamicScheduleHours"
              :key="index"
              class="block-item"
              :style="{ height: blockHeight + 'px' }"
            >
              <v-list-item-content class="block-content">{{ hour }}</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </div>

      <div class="scrollable-columns">
        <v-row no-gutters>
          <v-col
            v-for="(day, dayIndex) in daysOfWeek"
            :key="dayIndex"
            class="day-column"
          >
            <v-card outlined>
              <v-card-title class="red text-center">{{ formatDate(day) }}</v-card-title>
              <v-list dense>
                <v-list-item
                  v-for="(hour, hourIndex) in dynamicScheduleHours"
                  :key="hourIndex"
                  class="block-item"
                  :style="{ height: blockHeight + 'px' }"
                >
                  <v-list-item-content class="block-content ">
                    <!-- {{day}} -->
                    <span v-if="eventsByDate[day] && eventsByDate[day].some(event => event.HORA === hour)">
                      {{ hour }} - {{ getDescription(day, hour) }}
                    </span>
                    <span v-else>&nbsp;</span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      selectedDate: '2024-10-10', // Data padrão
      blockHeight: 50,
      daysOfWeek: [],
      scheduleHours: [
        "07:00", "07:30", "08:00", "08:10", "09:00", "09:10", "10:00", "10:30", 
        "10:50", "11:00", "11:50", "12:40", "13:40", "14:30", "15:30", 
        "16:20", "17:15", "18:05", "19:00", "19:55"
      ],
      items_eventos: [
        { "CODIGO": "22", "CODIGO_ALUNO": "1", "CODIGO_VEICULO": "3", "CODIGO_INSTRUTOR": "4", "DATA": "2024-10-11", "HORA": "16:15", "HORA_FIM": "17:00", "DESCRICAO": "DESCRICAO 15:13:54", "SITUACAO": "MARCADO", "DURACAO": "45", "ALUNO": "NOME ALUNO", "PROCESSO": "PROCESSO", "CATEGORIA": "A", "QUEM_ALTEROU": "09/10/2024_15:13:54_A", "x_NOME_VEICULO": "MOTO", "x_PLACA": "MM0887", "x_NOME_INSTRUTOR": "SELMA", "x_echoDSi": "cfc_45", "x_DATA": "11/10/2024", "start": "2024-10-11T16:15", "end": "2024-10-11T17:00", "color": "grey lighten-0", "timed": true },
        { "CODIGO": "21", "CODIGO_ALUNO": "1", "CODIGO_VEICULO": "3", "CODIGO_INSTRUTOR": "4", "DATA": "2024-10-10", "HORA": "15:30", "HORA_FIM": "16:15", "DESCRICAO": "DESCRICAO 15:13:41", "SITUACAO": "MARCADO", "DURACAO": "45", "ALUNO": "NOME ALUNO", "PROCESSO": "PROCESSO", "CATEGORIA": "A", "QUEM_ALTEROU": "09/10/2024_15:13:41_A", "x_NOME_VEICULO": "MOTO", "x_PLACA": "MM0887", "x_NOME_INSTRUTOR": "SELMA", "x_echoDSi": "cfc_45", "x_DATA": "10/10/2024", "start": "2024-10-10T15:30", "end": "2024-10-10T16:15", "color": "grey lighten-0", "timed": true },
        { "CODIGO": "20", "CODIGO_ALUNO": "1", "CODIGO_VEICULO": "3", "CODIGO_INSTRUTOR": "4", "DATA": "2024-10-10", "HORA": "10:15", "HORA_FIM": "15:00", "DESCRICAO": "DESCRICAO 15:13:35", "SITUACAO": "MARCADO", "DURACAO": "45", "ALUNO": "NOME ALUNO", "PROCESSO": "PROCESSO", "CATEGORIA": "A", "QUEM_ALTEROU": "09/10/2024_15:13:35_A", "x_NOME_VEICULO": "MOTO", "x_PLACA": "MM0887", "x_NOME_INSTRUTOR": "SELMA", "x_echoDSi": "cfc_45", "x_DATA": "10/10/2024", "start": "2024-10-10T10:15", "end": "2024-10-10T15:00", "color": "grey lighten-0", "timed": true }
      ],
      eventsByDate: {}
    };
  },
  computed: {
    dynamicScheduleHours() {
      const allHours = [...this.scheduleHours];
      Object.values(this.eventsByDate).forEach(dayEvents => {
        dayEvents.forEach(event => {
          if (!allHours.includes(event.HORA)) {
            allHours.push(event.HORA);
          }
        });
      });
      return allHours.sort((a, b) => this.compareTimes(a, b));
    }
  },
  
  methods: {
    compareTimes(timeA, timeB) {
      const [hoursA, minutesA] = timeA.split(':').map(Number);
      const [hoursB, minutesB] = timeB.split(':').map(Number);
      return (hoursA - hoursB) || (minutesA - minutesB);
    },

    generateDaysOfWeek() {
    const selectedDate = new Date(this.selectedDate);
    const dayOfWeek = selectedDate.getDay(); // 0 (domingo) a 6 (sábado)
    const diffToSunday = -dayOfWeek; // Ajuste para que comece no domingo
    const startOfWeek = new Date(selectedDate);
    startOfWeek.setDate(selectedDate.getDate() + diffToSunday); // Começa no domingo

    const daysOfWeek = [];
    for (let i = 0; i < 6; i++) { // Altere para 7 para incluir todos os dias da semana
        const day = new Date(startOfWeek);
        day.setDate(startOfWeek.getDate() + i);
        const formattedDay = day.toISOString().split('T')[0]; // 'YYYY-MM-DD' para comparação
        daysOfWeek.push(formattedDay);
    }

    this.daysOfWeek = daysOfWeek;
    this.organizeEventsByDate(); // Organiza eventos por data após gerar os dias da semana
    },

    organizeEventsByDate() {
      this.eventsByDate = {}; // Reinicializa o objeto de eventos

      this.items_eventos.forEach(event => {
        const eventDate = event.DATA; // Mantém o formato 'YYYY-MM-DD'
        if (!this.eventsByDate[eventDate]) {
          this.eventsByDate[eventDate] = [];
        }

        this.eventsByDate[eventDate].push({
          HORA: event.HORA,
          DESCRICAO: event.DESCRICAO + ' - ' + event.DATA
        });
      });
    },

    getDescription(day, hour) {
      const event = this.eventsByDate[day]?.find(event => event.HORA === hour);
      return event ? event.DESCRICAO : '';
    },


    formatDate(dateString) {
        // Cria um objeto Date a partir da string 'YYYY-MM-DD'
        const date = new Date(dateString + 'T00:00:00'); // Adiciona o horário para evitar problemas de fuso horário

        // Opções para formatar o dia da semana (abreviado) e o dia do mês
        const options = { weekday: 'short', day: 'numeric' };
        
        // Obtém a string formatada em português
        const formattedDate = date.toLocaleDateString('pt-BR', options).toUpperCase();

        // Para retornar no formato desejado "07 - seg"
        const day = date.getDate().toString().padStart(2, '0'); // Pega o dia do mês e garante que tenha dois dígitos
        const weekday = formattedDate.split(' ')[0]; // Obtém apenas a parte do dia da semana

        return `${day} - ${weekday}`;
    },
    
  },
  mounted() {
    this.generateDaysOfWeek(); // Gera os dias da semana quando o componente for montado
  },
  watch: {
    selectedDate() {
      this.generateDaysOfWeek(); // Regenera os dias da semana se a data selecionada mudar
    }
  }
};
</script>

<style scoped>
.schedule-wrapper {
  display: flex;
}

.fixed-column {
  width: 120px; /* Ajuste para a largura da coluna fixa */
}

.scrollable-columns {
  flex: 1; /* O restante do espaço será ocupado pelas colunas de dias */
  overflow-x: auto; /* Permite rolagem horizontal se necessário */
  display: flex;
}

.day-column {
  flex: 1; /* Cada coluna do dia ocupa um espaço igual */
  margin: 0 5px; /* Margem entre as colunas */
}

.block-item {
  border: 1px solid #e0e0e0; /* Borda para os itens */
}

.block-content {
  padding: 5px; /* Espaçamento interno */
  white-space: nowrap; /* Evita quebra de linha */
}
</style>
